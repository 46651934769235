import React from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CustomTheme } from '../../theme'

const LOGO_HOVER_TARGET_CLASSNAME = '__logo-hover-target'

const useStyles = makeStyles((theme: CustomTheme) => ({
  logoLink: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },

  logo: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },

  logoDesktop: {
    background: theme.colors.OFFWHITE,
    borderRadius: 100,
    padding: theme.spacing(1.5, 2),
    transition: 'color 0.25s ease-out',

    '&:hover': {
      animation: `$slidingBackgroundAnimation 5s ease-in-out infinite`,
      background: theme.colors.VORI_PURPLE_LIGHT,
      backgroundSize: 500,
      color: theme.colors.VORI_PURPLE,
    },

    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  },

  logoMobile: {
    //
  },

  '@keyframes slidingBackgroundAnimation': {
    '0%': {
      backgroundPositionX: 0,
    },

    '100%': {
      backgroundPositionX: 1000,
    },
  },

  logoText: {
    color: theme.colors.GRAY_2,
    fontWeight: 'bold',

    [`.${LOGO_HOVER_TARGET_CLASSNAME}:hover &`]: {
      color: theme.colors.VORI_PURPLE,
    },

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 2),
    },
  },

  logoTextDesktop: {
    margin: theme.spacing(0, 4),
  },

  logoTextMobile: {
    margin: theme.spacing(0, 1),

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  logoImage: {
    backgroundImage: `url(/logo512.png)`,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 24,
    width: 24,
  },
}))

type Props = {
  className?: string
  mobile?: boolean
}
const VoriLogo = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { className, mobile } = props

  return (
    <NavLink className={clsx(classes.logoLink, className)} to="/">
      <div
        className={clsx(classes.logo, {
          [classes.logoDesktop]: !mobile,
          '__logo-hover-target': !mobile,
          [classes.logoMobile]: mobile,
        })}
      >
        <div className={classes.logoImage} />
        <Typography
          className={clsx(classes.logoText, {
            [classes.logoTextDesktop]: !mobile,
            [classes.logoTextMobile]: mobile,
          })}
        >
          Vori Market
        </Typography>
      </div>
    </NavLink>
  )
}

export default VoriLogo
