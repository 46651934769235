import React from 'react'

import { MarketVendorProductWithActiveDealFieldsFragment } from '@vori/gql-market'

import { makeStyles } from '@material-ui/core/styles'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import Typography from '@material-ui/core/Typography'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  activeDeal: {
    alignItems: 'flex-start',
    color: theme.colors.GREEN,
    display: 'flex',
    textTransform: 'uppercase',
  },

  activeDealIcon: {
    alignItems: 'center',
    backgroundColor: theme.colors.LIGHT_GREEN,
    borderRadius: '50%',
    color: theme.colors.GREEN,
    display: 'inline-flex',
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
}))

type Props = {
  activeDeal: NonNullable<
    MarketVendorProductWithActiveDealFieldsFragment['activeDeal']
  >
}

function ActiveDealTag(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { activeDeal } = props

  return (
    <Typography className={classes.activeDeal} variant="caption">
      <div className={classes.activeDealIcon}>
        <LocalOfferIcon fontSize="inherit" />
      </div>{' '}
      {activeDeal.displayName}
    </Typography>
  )
}

export default ActiveDealTag
