// "Strict Mode" theme avoids React Strict mode warnings about nested `findDOMNode` usage on `Transition` / `Drawer`
// https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
  Theme,
} from '@material-ui/core/styles'

export type ColorOptions = {
  [key: string]: string | number | ((input: unknown) => string)
}

export type ColorTypes = {
  VORI_PURPLE: string
  VORI_PINK: string
  VORI_PURPLE_LIGHT: string
  VORI_YELLOW: string
  // BADGE_GREEN_BACKGROUND: string,
  BADGE_GREEN_TEXT: string
  BADGE_YELLOW_BACKGROUND: string
  BADGE_YELLOW_TEXT: string
  BADGE_RED_BACKGROUND: string
  BADGE_RED_TEXT: string
  ERROR: string
  WARNING: string
  SUCCESS: string
  SUCCESS_BACKGROUND: string
  SHADOW: string
  GREEN: string
  ACCENT_GREEN: string
  LIGHT_GREEN: string
  // Standards
  BLACK: string
  GRAY_0: string
  GRAY_1: string
  GRAY_2: string
  GRAY_3: string
  GRAY_4: string
  GRAY_5: string
  GRAY_6: string
  OFFWHITE: string
  WHITE: string
  // Derivatives. TODO consider computing these
  VORI_PURPLE_HIGHLIGHTED: string
  VORI_PURPLE_HSLA: (alpha: number) => string
}

export const Colors: ColorTypes = {
  // Brand palette
  VORI_PURPLE: 'hsl(251, 62%, 51%)',
  VORI_PINK: 'hsl(292, 100%, 50%)',
  VORI_PURPLE_LIGHT: 'hsl(248, 73%, 94%)',
  VORI_YELLOW: 'hsl(47,94%,63%)',
  // BADGE_GREEN_BACKGROUND: 'hsl(135,100%,96%)',
  BADGE_GREEN_TEXT: 'hsl(119,97%,25%)',
  BADGE_YELLOW_BACKGROUND: 'hsl(42,55%,91%)',
  BADGE_YELLOW_TEXT: 'hsl(47,100%,40%)',
  BADGE_RED_BACKGROUND: 'hsl(353,100%,97%)',
  BADGE_RED_TEXT: 'hsl(352,69%,50%)',
  ERROR: 'hsl(353,98%,41%)',
  WARNING: 'hsl(54,100%,58%)',
  SUCCESS: 'hsl(89,73%,48%)',
  SUCCESS_BACKGROUND: 'hsl(89,100%,94%)',
  SHADOW: 'hsla(0,0%,33%,0.08)',
  GREEN: 'hsl(153, 76%, 36%)', // #16a364
  ACCENT_GREEN: 'hsl(155, 76%, 44%)', // #1BC47D
  LIGHT_GREEN: 'hsl(152, 42%, 91%)', // #DFF2E9
  // Standards
  BLACK: '#000',
  GRAY_0: 'hsl(0,0%,89%)', // #E3E3E3
  GRAY_1: 'hsl(0,0%,83%)', // #D5D5D5
  GRAY_2: 'hsl(0,0%,17%)', // #2C2C2C
  GRAY_3: 'hsl(0,0%,31%)', // #4F4F4F
  GRAY_4: 'hsl(0,0%,34%)', // #565656
  GRAY_5: 'hsl(270,1%,44%)', // #717072
  GRAY_6: 'hsl(0, 0%, 64%)', // #979797
  OFFWHITE: '#F2F2F2',
  WHITE: '#fff',
  // Derivatives. TODO consider computing these
  VORI_PURPLE_HIGHLIGHTED: 'hsl(251,70%,55%)',
  VORI_PURPLE_HSLA: (alpha: number): string => `hsla(251,60%,43%,${alpha})`,
}

export const GRAY_4_BG =
  // #D5D5D5 1x1px. Via http://www.patternify.com/
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2O4evXqfwAIgQN/8XHAJAAAAABJRU5ErkJggg=='

export interface CustomThemeOptions extends ThemeOptions {
  colors: ColorTypes
}

export interface CustomTheme extends Theme {
  colors: ColorTypes
}

const defaultTheme = createMuiTheme()

const theme = responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    // Define custom `colors` rather than overwriting `palette`,
    // since each has a different format
    colors: Colors,
    palette: {
      primary: {
        main: Colors.VORI_PURPLE,
        // light?: string; Calculated if not provided
        // dark?: string; Calculated if not provided
        // contrastText?: string; Calculated if not provided
      },
      secondary: {
        main: Colors.VORI_YELLOW,
      },
      error: {
        main: Colors.ERROR,
      },
      warning: {
        main: Colors.WARNING,
      },
      // info: {},
      // success: {},
    },
    typography: {
      fontFamily: ['CircularStd', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      caption: {
        color: Colors.GRAY_3,
        fontWeight: 700,
        letterSpacing: 1,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'subpixel-antialiased',

            [defaultTheme.breakpoints.only('xs')]: {
              fontSize: '90%',
            },
          },
          body: {
            color: Colors.BLACK,
            backgroundColor: Colors.WHITE,
          },
        },
      },
      // Header
      MuiToolbar: {
        root: {
          backgroundColor: '#fff',
          color: Colors.GRAY_3,
        },
      },
      // Links
      MuiLink: {
        root: {
          cursor: 'pointer',
        },
      },
      // Buttons
      MuiButton: {
        root: {
          textTransform: 'none',
          '&+&': {
            marginLeft: 8,
          },
        },
        contained: {
          borderRadius: 100,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          color: Colors.WHITE,
          backgroundColor: Colors.VORI_PURPLE,
          '&:hover': {
            backgroundColor: Colors.VORI_PURPLE_HIGHLIGHTED,
          },
        },
        outlined: {
          borderRadius: defaultTheme.spacing(),
          border: `1px solid ${Colors.GRAY_3}`,
          color: Colors.GRAY_3,
        },
        outlinedPrimary: {
          color: Colors.VORI_PURPLE,
          backgroundColor: 'transparent',
          borderColor: Colors.VORI_PURPLE,
          '&:hover': {
            color: Colors.WHITE,
            borderColor: Colors.VORI_PURPLE_HIGHLIGHTED,
            backgroundColor: Colors.VORI_PURPLE_HIGHLIGHTED,
          },
        },
        outlinedSizeSmall: {
          fontSize: '0.75rem',
          fontWeight: 700,
          letterSpacing: 1,
          padding: '6px 12px',
        },
        outlinedSizeLarge: {
          fontWeight: 700,
          letterSpacing: 1,
        },
      },
      // Checkboxes
      MuiCheckbox: {
        root: {
          color: Colors.GRAY_3,
        },
        colorPrimary: {
          '&.Mui-checked': { color: Colors.VORI_PURPLE },
        },
      },
      // Chips
      MuiChip: {
        root: {
          height: 26,
          fontSize: '0.8rem',
          margin: '0 4px 8px 0',
          '& > svg.MuiSvgIcon-root': {
            width: 18,
            height: 18,
            color: Colors.VORI_PURPLE,
            '&:hover': {
              color: Colors.VORI_PURPLE_HIGHLIGHTED,
            },
          },
        },
        colorPrimary: {
          backgroundColor: Colors.VORI_PURPLE_LIGHT,
          color: Colors.VORI_PURPLE,
          '&.MuiChip-deletableColorPrimary:focus': {
            backgroundColor: Colors.VORI_PURPLE_HSLA(0.3),
          },
        },
      },
      // CircularProgress
      MuiCircularProgress: {
        colorPrimary: {
          color: Colors.VORI_PURPLE,
        },
      },
      // Inputs
      MuiOutlinedInput: {
        notchedOutline: {
          borderRadius: 10,
          borderColor: Colors.GRAY_3,
          '.MuiOutlinedInput-root:hover &': {
            borderColor: Colors.VORI_PURPLE,
          },
          '.MuiOutlinedInput-root.Mui-error:hover &': {
            borderColor: Colors.ERROR,
          },
        },

        input: {
          padding: '12px 16px', // default 18.5px 14px
          '&::placeholder': {
            color: Colors.GRAY_5,
          },
        },
      },
      MuiStepper: {
        root: {
          '&.MuiPaper-elevation0': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTab: {
        root: {
          textTransform: 'inherit',
          fontWeight: 'bold',
        },
      },
      // Old product table implementation, TODO revisit
      MuiTableCell: {
        root: {
          fontFamily: 'CircularStd, Helvetica, Arial, sans-serif',
          fontSize: '15px !important',
          color: 'rgba(0, 0, 0, 0.5) !important',
        },
        head: {
          color: 'rgba(0, 0, 0, 0.87) !important',
          textTransform: 'uppercase',
          fontSize: '14px !important',
        },
      },
      MuiTableSortLabel: {
        root: {
          fontSize: '14px !important',
        },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: Colors.GRAY_3,
        },
      },
    },
  } as CustomThemeOptions),
  {
    breakpoints: ['xs', 'sm', 'md', 'lg'],
  },
)

export default theme
