import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  preloader: {
    alignItems: 'center',
    color: theme.colors.VORI_PURPLE,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
}))

const Preloader = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Container className={classes.preloader}>
      <h3>Vori Market</h3>
      <CircularProgress />
    </Container>
  )
}

export default Preloader
