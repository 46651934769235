export const MaxOrderableProductQuantity = 999999

export const QuantityThresholdRed = 10
export const QuantityThresholdYellow = 30

export const BestsellersAlgoliaFilter = 'project19Featured:true'
export const BestsellersPerPage = 100

export const SearchSuggestions = [
  'Beans',
  'Beef',
  'Berries',
  'Boullion',
  'Breads',
  'Broth',
  'Canned Beans',
  'Canned Fruit',
  'Condiments',
  'Creamer',
  'Dry Beans',
  'Eggs',
  'Flour',
  'Frozen',
  'Fruits',
  'Hand Soap',
  'Ice Cream',
  'Meat',
  'Milk',
  'Paper Towels',
  'Pasta',
  'Plant-based',
  'Poultry',
  'Rice',
  'Sanitary Wipes',
  'Sausage',
  'Seafood',
  'Soup',
  'Sugar',
  'Toilet Paper',
  'Tortillas',
  'Vegetables',
  'Yeast',
  'Yogurt',
]
