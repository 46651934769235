import React from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import Button from '../ui/Button'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  circleIcon: {
    color: theme.colors.SUCCESS,
    fontSize: '2rem',
    marginRight: theme.spacing(1),
  },
  message: {
    textAlign: 'center',
  },
  title: {
    '& h2': { alignItem: 'center', display: 'flex', justifyContent: 'center' },
  },
}))

type Props = {
  isOpen: boolean
  handleOnClose: () => void
  vendorName: string
}

const CheckoutSuccessModal = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { isOpen, handleOnClose, vendorName } = props

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <DialogContent className={classes.message}>
        <DialogTitle
          data-test-id="checkout-success-text"
          className={classes.title}
        >
          <CheckCircleIcon className={classes.circleIcon} />
          Success!
        </DialogTitle>
        <DialogContentText>
          Your order with {vendorName} was successfully created.
          <br />
          Check your email for details.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckoutSuccessModal
