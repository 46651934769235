import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import { displayCurrency, ensureNumericPrice } from '../../helpers/utils'
import { CustomTheme } from '../../theme'
import { PriceProps } from './helpers/types'

const useStyles = makeStyles((theme: CustomTheme) => ({
  priceBox: {
    flexShrink: 0,
  },

  priceBoxCompact: {
    display: 'flex',
  },

  price: {
    alignItems: 'flex-start',
    color: theme.colors.BLACK,
    display: 'flex',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    marginRight: theme.spacing(),

    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
      maxWidth: '90%',
    },
  },

  priceLarge: {
    fontSize: theme.typography.h5.fontSize,
  },

  priceWithDeal: {
    color: theme.colors.GREEN,
  },

  originalPrice: {
    color: theme.colors.GRAY_5,
    fontSize: theme.typography.body2.fontSize,
    marginLeft: theme.spacing(0.5),
    textDecoration: 'line-through',
  },

  originalPriceLarge: {
    fontSize: theme.typography.body2.fontSize,
  },

  priceByline: {
    color: theme.colors.GRAY_3,
  },
}))

function Price(props: PriceProps): JSX.Element {
  const classes = useStyles(props)

  const {
    activeDeal,
    className,
    compact,
    large,
    productData,
    showPriceDifference,
    hidePriceByLine,
  } = props

  const casePrice = ensureNumericPrice(productData?.wholesaleCasePrice)

  const casePriceWithActiveDeal = ensureNumericPrice(
    productData?.casePriceWithActiveDeal,
  )

  const hasActiveDeal = activeDeal != null

  return (
    <div
      className={clsx(
        classes.priceBox,
        {
          [classes.priceBoxCompact]: compact,
        },
        className,
      )}
    >
      <div
        className={clsx(classes.price, {
          [classes.priceLarge]: large,
        })}
      >
        {hasActiveDeal && showPriceDifference ? (
          <>
            <span className={clsx(classes.priceWithDeal)}>
              {displayCurrency(casePriceWithActiveDeal)}
            </span>
            <span
              className={clsx(classes.originalPrice, {
                [classes.originalPriceLarge]: large,
              })}
            >
              {displayCurrency(casePrice)}
            </span>
          </>
        ) : (
          <span>{displayCurrency(casePrice)}</span>
        )}
      </div>
      {!hidePriceByLine && <div className={classes.priceByline}>per case</div>}
    </div>
  )
}

export default Price
