import React from 'react'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useMarketGetCartsInfoForCheckoutButtonQuery } from '@vori/gql-market'
import { ShoppingCart01Icon } from '@vori/gourmet-icons'

import { displayCurrency, pluralize } from '../../helpers/utils'
import { useIsMounted } from '../../helpers/hooks'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0.5, 2),

    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1),
    },

    '& .MuiButton-startIcon': {
      marginLeft: 0,

      [theme.breakpoints.only('xs')]: {
        marginRight: 0,
      },
    },
  },

  buttonContent: {
    padding: theme.spacing(0.5, 0),
  },
}))

type Props = {
  className?: string
  mobile?: boolean
  vendorID: string
  onClick: () => void
}

const CartButton = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { className, onClick, mobile, vendorID: currentVendorID } = props
  const isMounted = useIsMounted()

  const { data } = useMarketGetCartsInfoForCheckoutButtonQuery({
    skip: !isMounted,
  })

  const carts = data?.me?.user.carts || []

  const activeCartsCount =
    carts.filter(
      (cart) => cart.vendor.isMarketEnabled && cart.lineItemCount > 0,
    ).length || 0

  const activeCart = carts.find(
    (cart) =>
      currentVendorID != null &&
      (cart.vendor.id === currentVendorID ||
        cart.vendor.slug === currentVendorID),
  )

  const activeCartTotal = displayCurrency(activeCart ? activeCart.subTotal : 0)

  return (
    <Button
      data-test-id="cart-button"
      className={clsx(classes.button, className)}
      color="primary"
      disabled={activeCartsCount === 0}
      startIcon={
        mobile ? (
          <Badge badgeContent={activeCartsCount} color="primary">
            <ShoppingCart01Icon
              variant={activeCartsCount === 0 ? 'default' : 'primary'}
            />
          </Badge>
        ) : (
          <ShoppingCart01Icon />
        )
      }
      variant={mobile ? 'text' : 'contained'}
      onClick={onClick}
    >
      {!mobile && (
        <>
          <span
            data-test-id="nav-cart-price-text"
            className={classes.buttonContent}
            id="nav-cart-price"
          >
            {currentVendorID
              ? activeCartTotal
              : activeCartsCount === 0
                ? '$0.00'
                : `${activeCartsCount} ${pluralize(
                    'Active Cart',
                    activeCartsCount,
                  )}`}
          </span>
        </>
      )}
    </Button>
  )
}

export default CartButton
