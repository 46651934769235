import {
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_DB_URL,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MSG_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
  ENV_PLAID_ENV,
  ENV_PLAID_PUBLIC_KEY,
} from '@vori/market-env'

export const FIREBASE_CONFIG = {
  apiKey: ENV_FIREBASE_API_KEY,
  appId: ENV_FIREBASE_APP_ID,
  authDomain: ENV_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV_FIREBASE_DB_URL,
  measurementId: ENV_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: ENV_FIREBASE_MSG_SENDER_ID,
  projectId: ENV_FIREBASE_PROJECT_ID,
  storageBucket: ENV_FIREBASE_STORAGE_BUCKET,
}

export const PlaidConfig = {
  // plaid.com/docs/#parameter-reference
  clientName: 'Vori',
  env: ENV_PLAID_ENV,
  product: ['auth'],
  publicKey: ENV_PLAID_PUBLIC_KEY,
}

export const FeatureFlags = {
  plaidAccountPayments: false,
  selfServeSignup: false,
}
