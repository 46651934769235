import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useLayout } from '../../helpers/hooks'
import {
  getVendorRoutes,
  VENDOR_CATALOG_ROUTE,
  VENDOR_ORDERGUIDE_ROUTE,
  VENDOR_PROMOTIONS_ROUTE,
} from '../../routers/VendorRoutes'
import { CustomTheme } from '../../theme'
import VendorSelector from './VendorSelector'

const TABS = [
  VENDOR_CATALOG_ROUTE,
  VENDOR_PROMOTIONS_ROUTE,
  VENDOR_ORDERGUIDE_ROUTE,
]

const useStyles = makeStyles((theme: CustomTheme) => ({
  tabMenu: {
    backgroundColor: theme.colors.WHITE,
    borderBottom: `1px solid ${theme.colors.GRAY_1}`,
    boxShadow: `0 -5px 0 0 ${theme.colors.WHITE}`,
    position: 'sticky',
    top: 80,
    zIndex: 10,

    [theme.breakpoints.only('xs')]: {
      top: 50,
    },
  },

  vendorSelect: {
    left: 0,
    position: 'absolute',
  },
}))

type Props = {
  className?: string
}

export default function VendorTabMenu(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { vendor: slugOrID, activeTab: activeTabName } = useParams<{
    vendor: string
    activeTab: string
  }>()
  const [activeTab, setActiveTab] = useState(TABS.indexOf(activeTabName || ''))
  const history = useHistory()
  const isMobile = useLayout()
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const query = params.get('query')

  useEffect(() => {
    const newTabIndex = TABS.indexOf(activeTabName || '')

    if (newTabIndex >= 0) {
      setActiveTab(newTabIndex)
    }
  }, [activeTabName])

  const { catalogURL, orderGuideURL, promotionsURL } = getVendorRoutes(slugOrID)

  const VendorSelectorComponent = () => (
    <VendorSelector
      className={props.className}
      defaultVendorID={slugOrID}
      onChange={(vendor) => {
        const nextVendorRoutes = getVendorRoutes(
          vendor?.slug || vendor?.id || '',
        )

        switch (TABS[activeTab]) {
          case VENDOR_CATALOG_ROUTE: {
            history.push(nextVendorRoutes.catalogURL)
            break
          }

          case VENDOR_PROMOTIONS_ROUTE: {
            history.push(nextVendorRoutes.promotionsURL)
            break
          }

          case VENDOR_ORDERGUIDE_ROUTE: {
            history.push(nextVendorRoutes.orderGuideURL)
            break
          }

          default: {
            history.push(nextVendorRoutes.catalogURL)
          }
        }
      }}
    />
  )

  const portalDOM = document.querySelector(
    '[data-portal="header-vendor-selector"]',
  )

  const addQueryParamIfPresent: (path: string) => string = (path) => {
    if (query) {
      return `${path}?query=${query}`
    } else {
      return path
    }
  }

  return (
    <Container className={classes.tabMenu} maxWidth="xl">
      {isMobile && portalDOM ? (
        createPortal(<VendorSelectorComponent />, portalDOM)
      ) : (
        <VendorSelectorComponent />
      )}
      <Tabs
        centered
        indicatorColor="primary"
        textColor="primary"
        value={activeTab === -1 ? 0 : activeTab}
      >
        <Tab
          label="Catalog"
          onClick={() => {
            history.push(addQueryParamIfPresent(catalogURL))
          }}
        />
        <Tab
          label="Promotions"
          onClick={() => {
            history.push(addQueryParamIfPresent(promotionsURL))
          }}
        />
        <Tab
          label="Order Guide"
          onClick={() => {
            history.push(addQueryParamIfPresent(orderGuideURL))
          }}
        />
      </Tabs>
    </Container>
  )
}
