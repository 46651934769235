import 'firebase/auth'
import app from 'firebase/app'

import { FIREBASE_CONFIG } from '../config/AppConfig'

class Firebase {
  auth: app.auth.Auth

  constructor() {
    const config = FIREBASE_CONFIG
    app.initializeApp(config)
    this.auth = app.auth()
  }

  // AUTH ACTIONS

  createAccount = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  signIn = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password)

  signOut = () => this.auth.signOut()

  passwordReset = (email: string) => this.auth.sendPasswordResetEmail(email)

  passwordUpdate = (password: string) =>
    this.auth?.currentUser?.updatePassword(password)

  changePassword = (currentPassword: string, newPassword: string) => {
    return new Promise((resolve, reject) => {
      try {
        if (this.reauthenticate(currentPassword)) {
          const user = this.auth?.currentUser
          if (user) {
            user
              .updatePassword(newPassword)
              .then(() => {
                resolve('Password updated successfully!')
              })
              .catch((error: unknown) => reject(error))
          }
        }
      } catch (error: unknown) {
        reject(error)
      }
    })
  }

  reauthenticate = (currentPassword: string) => {
    const user = this.auth.currentUser
    const cred = app.auth.EmailAuthProvider.credential(
      user?.email || '',
      currentPassword,
    )

    return user?.reauthenticateWithCredential(cred)
  }

  updateEmail = async (currentPassword: string, newEmail: string) => {
    await this.reauthenticate(currentPassword)
    await this.auth?.currentUser?.updateEmail(newEmail)
  }
}

const firebase = new Firebase()

export default firebase
