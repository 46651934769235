import { makeStyles } from '@material-ui/core/styles'

import ClearIcon from '@material-ui/icons/Clear'
import clsx from 'clsx'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import IconButton from '@material-ui/core/IconButton'
import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import {
  BuyXGetYFreeDeal,
  FixedDeal,
  MarketGetCartsQuery,
  PercentageDeal,
} from '@vori/gql-market'

import ActiveDealTag from '../product/ActiveDealTag'
import AddToCartButtonInput from '../product/AddToCartButtonInput'
import ProductPrice from '../product/Price'
import WithActiveDeal from '../product/WithActiveDeal'

import { MaxOrderableProductQuantity } from '../../config/ProductConfig'
import { useUpdateProductQuantity } from '../../graphql/graphqlHooks'
import { CustomTheme } from '../../theme'

const PriceWithActiveDeal = WithActiveDeal(ProductPrice)

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
  },
  image: {
    height: 100,
    width: 100,
    alignSelf: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.only('xs')]: {
      height: 64,
      width: 64,
    },
  },
  noImage: {
    backgroundColor: theme.colors.VORI_PURPLE_HSLA(0.02),
  },
  description: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  descriptionText: {
    maxWidth: 250,
    maxHeight: '2.9em',
    overflow: 'hidden',
  },
  activeDealRow: {
    margin: theme.spacing(1, 0),
  },
  sizeDetails: {
    color: theme.colors.GRAY_5,
  },
  content: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    // minWidth: 300,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quantityControl: {
    marginLeft: theme.spacing(2),
  },
  priceRow: {
    marginTop: theme.spacing(1),
  },
  quantIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    color: theme.colors.VORI_PURPLE,
  },
  quantity: {
    fontSize: '1.2rem',
    margin: `0 ${theme.spacing(1)}px`,
    // prevent flickering as quantity (and therefore width) changes
    width: '2em',
    textAlign: 'center',
  },
  outOfStockLabel: {
    color: theme.colors.BADGE_RED_TEXT,
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      marginRight: theme.spacing(0.5),
    },
  },
}))

type Props = {
  className?: string
  lineItem: NonNullable<
    MarketGetCartsQuery['me']
  >['user']['carts'][number]['lineItems']['nodes'][number]
  onLineItemRemove?: (lineItemID: string) => void
}

export default function CartItem(props: Props): JSX.Element {
  const classes = useStyles(props)
  const [isEditingQuantity, setIsEditingQuantity] = useState(false)
  const { className, lineItem, onLineItemRemove } = props
  const { vendorProduct, quantity } = lineItem
  const updateQuantity = useUpdateProductQuantity()

  const {
    activeDeal,
    caseSize,
    product: { description, imgURL, unitVolume },
  } = vendorProduct

  const handleRemoveFromCart = () => {
    if (vendorProduct != null) {
      updateQuantity(vendorProduct, 0)
    }

    if (onLineItemRemove != null) {
      onLineItemRemove(lineItem.id)
    }
  }

  const handleQuantityChange = (newQuantity: number) => {
    if (vendorProduct != null) {
      updateQuantity(vendorProduct, newQuantity)
    }
  }

  const isOutOfStock =
    vendorProduct.vendor?.disableOutOfStockPurchases &&
    (vendorProduct.inventory || 0) <= 0

  return (
    <div className={clsx(className, classes.container)}>
      <div
        className={clsx(classes.image, {
          [classes.noImage]: !imgURL,
        })}
        style={imgURL ? { backgroundImage: `url(${imgURL})` } : undefined}
      ></div>
      <div className={classes.content}>
        {isOutOfStock && (
          <div
            className={clsx(classes.descriptionText, classes.outOfStockLabel)}
          >
            <ErrorOutline fontSize="small" /> Out of Stock
          </div>
        )}
        <div className={classes.titleRow}>
          <div className={classes.description}>
            <div className={classes.descriptionText}>{description}</div>
          </div>
          {activeDeal != null && (
            <div className={classes.activeDealRow}>
              <ActiveDealTag activeDeal={activeDeal} />
            </div>
          )}
          <div className={classes.sizeDetails}>
            {`${
              caseSize != null && String(caseSize).trim().length > 0
                ? `${caseSize} per case`
                : ''
            }${
              typeof unitVolume === 'string' && unitVolume.trim().length > 0
                ? ` · ${unitVolume}`
                : ''
            }`}
          </div>
        </div>
        <div className={classes.priceRow}>
          <PriceWithActiveDeal
            activeDeal={
              vendorProduct.activeDeal as FixedDeal &
                BuyXGetYFreeDeal &
                PercentageDeal
            }
            fromGQL
            large
            productData={vendorProduct}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <div className={classes.quantityControl}>
          <AddToCartButtonInput
            disabled={
              vendorProduct.vendor?.disableOutOfStockPurchases &&
              (vendorProduct.inventory || 0) <= 0
            }
            isEditingQuantity={isEditingQuantity}
            onEditingQuantityChange={setIsEditingQuantity}
            quantity={quantity}
            onQuantityChange={handleQuantityChange}
            maxQuantity={MaxOrderableProductQuantity}
          />
        </div>
        {!isEditingQuantity && (
          <Tooltip title="Remove from Cart" placement="top">
            <IconButton onClick={handleRemoveFromCart}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
