import React, { useEffect, useState } from 'react'
import { stubFalse } from 'lodash'
import clsx from 'clsx'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import WithAddToCart from './WithAddToCart'
import { displayCurrency } from '../../helpers/utils'
import { CustomTheme } from '../../theme'
import { WithAddToCartInputExtended } from './helpers/types'

export const styles = makeStyles((theme: CustomTheme) => ({
  quanityControls: {
    marginTop: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  addToCartBtn: {
    borderRadius: 4,
    width: '100%',
    height: 55,
    [theme.breakpoints.down('xs')]: {
      height: 36,
      width: '100%',
    },
  },
  quantityInputWrapper: {
    border: `1px solid ${theme.colors.GRAY_1}`,
    borderRadius: 4,
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
  },
  quantityInput: {
    textAlign: 'center',
    borderRadius: 4,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.WHITE,
    color: theme.colors.BLACK,
    fontSize: theme.typography.subtitle2.fontSize,
    height: 54,
    width: 62,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      height: 34,
      maxWidth: 88,
      width: '100%',
    },

    // Disable native up/down buttons on number input
    '-moz-appearance': 'textfield',

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },

    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
  },
  quantityButtons: {
    borderLeft: `1px solid ${theme.colors.GRAY_1}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& .MuiButton-root': {
      margin: 0,

      '&:first-child': {
        borderBottom: `1px solid ${theme.colors.GRAY_1}`,
      },
    },
    height: 54,
    [theme.breakpoints.down('xs')]: {
      height: 34,
    },
  },
  quantityButton: {
    color: theme.colors.GRAY_4,
    borderRadius: 0,
    minWidth: 0,
    padding: 0,
    width: 25,
    height: '100%',

    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.75,
    filter: 'grayscale(0.55)',
  },
}))

const QuantityControls = ({
  basketProps,
  productData,
  setLocalQuantity,
  localQuantity = 0,
  maxQuantity = Number.POSITIVE_INFINITY,
  incrementQuantity,
  decrementQuantity,
  onInputKeyDown,
  onInputChange,
  isDemoMode,
}: WithAddToCartInputExtended): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(stubFalse)
  const classes = styles()

  useEffect(() => {
    if (setLocalQuantity) {
      setLocalQuantity(basketProps?.quantity || 0)
    }

    if (localQuantity === basketProps?.quantity && loading) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketProps?.quantity])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const confirm = () => {
    if (
      basketProps?.onQuantityChange &&
      localQuantity !== basketProps?.quantity
    ) {
      if (!isDemoMode) {
        setLoading(true)
        basketProps.onQuantityChange(Math.min(maxQuantity, localQuantity))
      } else {
        window.open('https://g170h.app.link/e/E3voPUhRVgb', '_blank')
      }
    }
  }

  const btnText = `${
    (basketProps?.quantity || 0) > 0 ? 'Update Cart' : 'Add to Cart'
  }`

  return (
    <Grid
      container
      item
      xs={12}
      spacing={isMobile ? 1 : 0}
      className={classes.quanityControls}
    >
      <Grid item xs={3}>
        <TextField
          className={classes.quantityInputWrapper}
          disabled={basketProps?.disabled}
          // props for native `input`
          inputProps={{
            className: classes.quantityInput,
            pattern: '[0-9]*', // force numerical keyboard on iOS
          }}
          // props for Underlying Material `Input`
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <div className={classes.quantityButtons}>
                <Button
                  className={clsx(classes.quantityButton, {
                    [classes.disabled]: basketProps?.disabled,
                  })}
                  onClick={(event) => {
                    if (incrementQuantity) {
                      incrementQuantity(event.shiftKey)
                    }
                  }}
                  disabled={maxQuantity <= localQuantity}
                  variant="text"
                >
                  <ArrowDropUpIcon />
                </Button>
                <Button
                  className={clsx(classes.quantityButton, {
                    [classes.disabled]: basketProps?.disabled,
                  })}
                  disabled={localQuantity <= 0}
                  onClick={(event) => {
                    if (decrementQuantity) {
                      decrementQuantity(event.shiftKey)
                    }
                  }}
                  variant="text"
                >
                  <ArrowDropDownIcon />
                </Button>
              </div>
            ),
          }}
          value={localQuantity}
          onKeyDown={onInputKeyDown}
          onChange={onInputChange}
          type="number"
        />
      </Grid>
      <Grid item xs={9}>
        <Button
          variant="contained"
          color="primary"
          className={classes.addToCartBtn}
          disabled={
            (localQuantity <= 0 && basketProps?.quantity === 0) || loading
          }
          onClick={confirm}
        >
          {loading ? (
            <CircularProgress size="20px" />
          ) : (
            <>
              {isMobile ? (
                <Grid
                  container
                  item
                  xs={12}
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item xs={9}>
                    {btnText}
                  </Grid>
                  <Grid item xs={3}>
                    {displayCurrency(
                      (parseInt(productData?.wholesaleCasePrice as string) ||
                        0) * (basketProps?.quantity || 0),
                    )}
                  </Grid>
                </Grid>
              ) : (
                btnText
              )}
            </>
          )}
        </Button>
      </Grid>
    </Grid>
  )
}

export default WithAddToCart(QuantityControls)
