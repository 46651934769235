import React, { useState } from 'react'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'

import { MarketGetVendorDetailsBySlugOrIdQuery } from '@vori/gql-market'

import { disableAppNotify } from '../../helpers/ios-app-notify'
import { CUSTOM_IOS_URL } from '../../constants'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  contentParentContainer: {
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 425,
    },
    backgroundColor: theme.colors.WHITE,
    bottom: 0,
    textAlign: 'center',
  },
  gradient: {
    width: '100%',
    height: 4,
    background: `linear-gradient(75deg, ${theme.colors.VORI_PINK}, ${theme.colors.VORI_PURPLE})`,
  },
  contentContainer: {
    padding: theme.spacing(3, 7),

    '& p, h2, button': {
      fontSize: '1rem',
    },
  },
  boldTxt: {
    fontWeight: 700,
  },
  description: {
    color: theme.colors.GRAY_3,
    marginBottom: 35,
  },
  notNowBtn: {
    padding: 0,
  },
  headTxt: {
    marginBottom: 12,
  },
  switchBtn: {
    marginBottom: 12,
  },
  vendorAvatar: {
    backgroundColor: theme.colors.WHITE,
    border: `1px solid ${theme.colors.GRAY_1}`,
    height: 84,
    margin: theme.spacing(0, 'auto', 0.5),
    padding: theme.spacing(1),
    width: 84,
    marginBottom: 20,
  },
}))

type Props = {
  initalOpen: boolean
  vendor: MarketGetVendorDetailsBySlugOrIdQuery['vendorBySlug']
}

const IOSAppNotify = ({ initalOpen, vendor }: Props): JSX.Element => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(initalOpen)

  const handleClose = () => {
    disableAppNotify()
    setOpen(false)
  }

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.contentParentContainer}
      >
        <div className={classes.gradient} />
        <div className={classes.contentContainer}>
          <Avatar
            alt={vendor?.name || 'Vendor'}
            className={classes.vendorAvatar}
            src={vendor?.imgURL || ''}
          />
          <Typography
            component="h2"
            className={clsx(classes.boldTxt, classes.headTxt)}
          >
            Explore more items from {vendor?.name || 'vendor'}
          </Typography>
          <Typography component="p" className={classes.description}>
            Use the Vori app to quickly scan to write order, easily shop on the
            go, and review order history - all in one place.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.switchBtn}
            onClick={() => window.location.replace(CUSTOM_IOS_URL)}
          >
            Switch to the app
          </Button>
          <Button
            className={clsx(classes.boldTxt, classes.notNowBtn)}
            onClick={handleClose}
          >
            Not now
          </Button>
        </div>
      </Grid>
    </Backdrop>
  )
}

export default IOSAppNotify
