import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.colors.VORI_PURPLE_LIGHT,
    borderRadius: 4,
    color: theme.colors.VORI_PURPLE,
    display: 'inline-flex',
    maxWidth: '100%',
    padding: '2px 10px',
    textAlign: 'center',
  },

  innerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

type Props = {
  brand: string
  className?: string
}

function BrandTag(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { brand, className } = props

  return (
    <div className={clsx(classes.container, className)} title={brand}>
      <span className={classes.innerText}>{brand}</span>
    </div>
  )
}

export default BrandTag
