import React, { forwardRef, Fragment } from 'react'
import styled, { CSSObject } from 'styled-components'

import { base, colors, spacing } from '../tokens'

import Flex from '../Flex'
import LogoWithText from '../LogoWithText'
import Spacer from '../Spacer'
import Text from '../Text'

type AppHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  leftContent?: Array<React.ReactNode>
  rightContent?: Array<React.ReactNode>
  title?: string
}

const defaultProps: Partial<AppHeaderProps> = {
  className: '',
  title: '',
}

const styles: CSSObject = {
  backgroundColor: colors.appHeader.backgroundColor,
  color: colors.appHeader.title.color,
  padding: spacing.appHeader,

  [`${Text}`]: {
    color: colors.appHeader.title.color,
  },
}

const Divider = styled.div({
  borderLeftColor: colors.appHeader.divider.borderColor,
  borderLeftStyle: 'solid',
  borderLeftWidth: 1,
  display: 'inline-flex',
  height: base.spacing.medium,
  width: 1,
})

const Title = styled(Text)({
  color: colors.appHeader.title.color,
})

const StyledAppHeader = styled(
  forwardRef<HTMLDivElement, AppHeaderProps>(function AppHeader(
    { leftContent, rightContent, title, ...props }: AppHeaderProps,
    ref,
  ) {
    return (
      <Flex
        {...props}
        centerY
        fullWidth
        justifyContent="space-between"
        ref={ref}
      >
        <Flex centerY fullWidth>
          <LogoWithText variant="secondary" />
          {title != null && title !== '' && (
            <>
              <Spacer inline size="medium" />
              <Divider />
              <Spacer inline size="medium" />
              <Title size="h5">{title}</Title>
            </>
          )}
          {(leftContent || []).map((node, index) => (
            <Fragment key={`leftContentInternal-${index}`}>
              <Spacer inline size="medium" />
              <Divider />
              <Spacer inline size="medium" />
              {node}
            </Fragment>
          ))}
        </Flex>
        <Flex centerY fullWidth justifyContent="flex-end">
          {(rightContent || []).map((node, index) => (
            <Fragment key={`rightContentInternal-${index}`}>
              {index !== 0 && (
                <>
                  <Spacer inline size="medium" />
                  <Divider />
                  <Spacer inline size="medium" />
                </>
              )}
              {node}
            </Fragment>
          ))}
        </Flex>
      </Flex>
    )
  }),
)<AppHeaderProps>(styles)

StyledAppHeader.displayName = 'AppHeader'
StyledAppHeader.defaultProps = defaultProps

export type { AppHeaderProps }
export { defaultProps as AppHeaderDefaultProps, styles as AppHeaderStyles }
export default StyledAppHeader
