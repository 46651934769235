import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import HorizontalScrollList from '../components/ui/HorizontalScrollList'
import VendorCard from '../components/vendor/VendorCard'
import Section from '../components/ui/Section'
import ViewLoader from '../components/ui/ViewLoader'
import MyVendors from './MyVendors'

import {
  MY_VENDORS,
  // MY_PROMOS,
  // MY_BESTSELLERS,
  // NEW_ITEMS,
} from '../constants/routes'
import { REDIRECT } from '../constants'

import { useVendorsForViewer } from '../graphql/graphqlHooks'
import { getLocalStorage, removeFromLocalStorage } from '../helpers/utils'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },

  vendorItemContaner: {
    alignItems: 'stretch',
    display: 'flex',
    flexShrink: 0,
    width: 'calc(100% / 4.5)',

    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% / 3.5)',
    },

    [theme.breakpoints.only('xs')]: {
      width: 'calc(100% / 2.5)',
    },
  },

  vendorItem: {
    width: '100%',
  },
}))

const Home = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const [isMounted, setIsMounted] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { loading, vendors } = useVendorsForViewer({
    skip: !isMounted,
  })

  // Prevents memory leaks, only need to query VendorsForViewer when component is mounted
  useEffect(() => {
    setIsMounted(true)

    const redirect = getLocalStorage(REDIRECT)
    const url = redirect?.url
    if (redirect?.shouldRedirect && url) {
      history.push(`/${url}`)
      removeFromLocalStorage(REDIRECT)
    }
  }, [history])

  if (loading) return <ViewLoader />

  const noVendors = !vendors || vendors?.length === 0

  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Section
            title="Your Vendors"
            seeAllURL={isMobile || noVendors ? null : MY_VENDORS}
          >
            {!isMobile && vendors && vendors?.length > 0 && (
              <HorizontalScrollList
                items={vendors.map((vendor) => (
                  <VendorCard
                    className={classes.vendorItem}
                    key={vendor.id}
                    vendor={vendor}
                  />
                ))}
                itemClassName={classes.vendorItemContaner}
              />
            )}

            {isMobile && vendors && vendors?.length > 0 && <MyVendors />}

            {noVendors && 'Unable to load vendors. Please contact support'}
          </Section>

          {/* <Section title="Shop Promos" seeAllURL={MY_PROMOS}>
            (TODO)
          </Section>
          <Section title="Best Sellers" seeAllURL={MY_BESTSELLERS}>
            (TODO)
          </Section>
          <Section title="New Items" seeAllURL={NEW_ITEMS}>
            (TODO)
          </Section> */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
