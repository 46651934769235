import React, { useContext } from 'react'
import { Redirect, Route, useLocation, RouteProps } from 'react-router-dom'

import { SIGN_IN } from '../constants/routes'
import { AuthContext } from '../auth/AuthContext'
import { REDIRECT } from '../constants'
import { setLocalStorage } from '../helpers/utils'

const PrivateRoute = ({
  component: Component,
  ...rest
}: RouteProps): JSX.Element => {
  const location = useLocation()
  const {
    auth: { isAuthenticated },
  } = useContext(AuthContext)

  if (!isAuthenticated) {
    setLocalStorage(REDIRECT, { shouldRedirect: true })
    return (
      <Redirect
        to={{
          pathname: SIGN_IN,
          state: { from: location },
        }}
      />
    )
  }

  return <Route {...rest} component={Component} />
}

export default PrivateRoute
