import { useCallback, useContext, useEffect, useState } from 'react'
import { cloneDeep, set } from 'lodash'

import {
  DepartmentName,
  MarketGetAllVendorsQueryResult,
  MarketGetCartsQuery,
  MarketGetCurrentUserQueryResult,
  MarketGetOrderGuideQueryResult,
  MarketGetVendorProductOrderHistoryQueryHookResult,
  MarketVendorFieldsFragment,
  MarketVendorProductWithActiveDealFieldsFragment,
  UnitOfMeasure,
  useMarketClearVendorCartMutation,
  useMarketGetAllVendorsQuery,
  useMarketGetCartsQuery,
  useMarketGetCurrentUserQuery,
  useMarketGetOrderGuideQuery,
  useMarketGetVendorProductOrderHistoryQuery,
  useMarketUpdateCartQuantityMutation,
  Vendor,
} from '@vori/gql-market'

import { AuthContext } from '../auth/AuthContext'
// eslint-disable-next-line import/no-cycle
import { useIsMounted } from '../helpers/hooks'

import { ProductExtras } from '../components/product/helpers/types'
import { GqlVendorProductToAlgolia } from '../helpers/utils'

export function useCurrentUser(): Pick<
  MarketGetCurrentUserQueryResult,
  'data' | 'error' | 'loading'
> {
  const { auth } = useContext(AuthContext)
  const { user: firestoreUser } = auth

  const { data, error, loading } = useMarketGetCurrentUserQuery({
    skip: firestoreUser == null,
    variables: {
      featureIDs: ['market.sunridgefarmsdemo'],
    },
  })

  return { data, error, loading }
}

/**
 * @param {import('@apollo/client').QueryHookOptions} options
 */
export function useVendorsForViewer(options = {}): Pick<
  MarketGetAllVendorsQueryResult,
  'loading' | 'error'
> & {
  vendors: MarketVendorFieldsFragment[] | null
} {
  const { data, error, loading } = useMarketGetAllVendorsQuery(options)

  if (!data?.me?.user || loading) {
    return { error, loading, vendors: null }
  }

  const defaultDepartmentName =
    data.me.user.defaultDepartmentName || DepartmentName.Default

  let vendors =
    data.me.user.storeDepartments.nodes
      .find(({ name }) => defaultDepartmentName === name)
      ?.vendorStoreDepartmentAccounts.nodes.map(({ vendor }) => vendor) || []

  if (!vendors) {
    // Vendor users (salesRep / vendorAdmin) can see only their own vendor
    const vendor = data.me?.user.vendorUser?.vendor

    if (vendor) {
      vendors = [vendor]
    }
  }

  return { error, loading, vendors }
}

export function useUpdateProductQuantity(): (
  product: (
    | Partial<MarketVendorProductWithActiveDealFieldsFragment>
    | Partial<GqlVendorProductToAlgolia>
  ) &
    ProductExtras,
  quantity: number,
) => Promise<void> {
  const isMounted = useIsMounted()
  const { data: cartQueryData, refetch } = useMarketGetCartsQuery()
  const [updateCartQuantity] = useMarketUpdateCartQuantityMutation()

  const updateProductQuantity = async (
    product: (
      | Partial<MarketVendorProductWithActiveDealFieldsFragment>
      | Partial<GqlVendorProductToAlgolia>
    ) &
      ProductExtras,
    quantity: number,
  ): Promise<void> => {
    // let cartToUpdate = null;
    // let updatedLineItems = [];
    const carts = cartQueryData?.me?.user.carts || []
    const prevCarts = carts.map((cart) => cart.id)

    // for (const cart of carts) {
    //   cartToUpdate = cart.vendor.id === product.vendor.id ? cart : null;

    //   if (cartToUpdate !== null) {
    //     updatedLineItems = cart.lineItems.nodes.concat();

    //     const lineItemIndexToUpdate = updatedLineItems.findIndex((lineItem) =>
    //       lineItem.id === product.id
    //     );

    //     if (lineItemIndexToUpdate !== -1) {
    //       updatedLineItems[lineItemIndexToUpdate] = {
    //         ...updatedLineItems[lineItemIndexToUpdate],
    //         quantity,
    //       };
    //     } else {
    //       updatedLineItems = updatedLineItems.concat({
    //         id: product.id,
    //         quantity,
    //         vendorProduct: product,
    //       });
    //     }

    //     break;
    //   }
    // }

    /**
     * Update cache manually after updating quantity to perform a
     * pseudo-optimistic UI update in order to have instant visual feedback
     * of the cart button state on the header while we update the cart and
     * refetch the updated cart contents.
     *
     * @todo - Revisit this after we can switch to 100% GQL; We can't
     * consistently update the Apollo cache with data coming from Algolia
     * because it doesn't reflect the data coming from GQL.
     */
    // updateQuery((prevData) => {
    //   const clonedPrevData = cloneDeep(prevData);
    //   const prevDataCarts = clonedPrevData.me.user.carts || [];

    //   for (const [index, cart] of prevDataCarts.entries()) {
    //     /**
    //      * Update cart
    //      */
    //     if (cart.vendor.id === product.vendor.id) {
    //       let prevDataLineItems = cart.lineItems.nodes.concat();

    //       const lineItemIndexToUpdate = prevDataLineItems.findIndex(
    //         (lineItem) => lineItem.vendorProduct.id === product.id
    //       );

    //       if (lineItemIndexToUpdate !== -1) {
    //         prevDataLineItems[lineItemIndexToUpdate] = {
    //           ...prevDataLineItems[lineItemIndexToUpdate],
    //           quantity,
    //         };
    //       } else {
    //         prevDataLineItems = prevDataLineItems.concat({
    //           id: product.id,
    //           quantity,
    //           vendorProduct: product,
    //         });
    //       }

    //       prevDataCarts[index].lineItems.nodes = prevDataLineItems;

    //       /**
    //        * Delete cart
    //        */
    //       if (
    //         prevDataLineItems.reduce(
    //           (totalQuantity, lineItem) =>
    //             totalQuantity + (lineItem.quantity || 0),
    //           0
    //         ) === 0
    //       ) {
    //         delete prevDataCarts[index];
    //       }

    //       clonedPrevData.me.user.carts = compact(prevDataCarts);
    //       break;
    //     }
    //   }

    //   /**
    //    * Add cart
    //    */
    //   if (
    //     quantity > 0 &&
    //     (!clonedPrevData.me.user.carts.length ||
    //       !clonedPrevData.me.user.carts.find(
    //         (cart) => cart?.vendor?.id === product.vendor.id
    //       ))
    //   ) {
    //     clonedPrevData.me.user.carts.push({
    //       id: Date.now(),
    //       productCount: quantity,
    //       lineItemCount: 1,
    //       subTotal: 0,
    //       totalDiscount: 0,
    //       lineItems: {
    //         nodes: [
    //           {
    //             id: product.id,
    //             quantity,
    //             price: 0,
    //             vendorProduct: product,
    //           },
    //         ],
    //       },
    //       vendor: {
    //         email: product.vendor.id,
    //         id: product.vendor.id,
    //         imgURL: '',
    //         name: product.vendor.id,
    //         orderMin: 0,
    //         slug: product.vendor.id,
    //       },
    //     });
    //   }

    //   return clonedPrevData;
    // });

    const result = await updateCartQuantity({
      variables: {
        quantity,
        unitOfMeasure: UnitOfMeasure.Case, // Market only supports cases atm
        vendorProductID: product.id as string,
      },
      /**
       * Optimistic UI update to get instant visual feedback when updating
       * product quantities. This will help to quickly show the upsell banner
       * on the vendor promotions tab while the cart is being updated.
       *
       * @todo - Revisit this after we can switch to 100% GQL; We can't
       * consistently update the Apollo cache with data coming from Algolia
       * because it doesn't reflect the data coming from GQL.
       */
      // ...(cartToUpdate != null && {
      //   optimisticResponse: {
      //     updateCartProductQuantity: {
      //       updatedVendorCart: {
      //         ...cartToUpdate,
      //         lineItemCount: updatedLineItems.length,
      //         productCount: updatedLineItems.reduce(
      //           (productCount, lineItem) =>
      //             productCount + (lineItem.quantity || 0),
      //           0
      //         ),
      //         lineItems: {
      //           nodes: updatedLineItems.map((lineItem) => ({
      //             ...lineItem,
      //             __typename: 'CartLineItem',
      //           })),
      //           __typename: 'CartLineItems',
      //         },
      //         __typename: 'VendorCart',
      //       },
      //       __typename: 'updateCartProductQuantityResult',
      //     },
      //   },
      // }),
    })

    const updatedVendorCart =
      result.data?.updateCartProductQuantity.updatedVendorCart

    if (
      !updatedVendorCart ||
      updatedVendorCart.lineItemCount === 0 ||
      !prevCarts.includes(updatedVendorCart.id)
    ) {
      // A new cart was added, or the last item in cart was deleted,
      // so refetch the top-level list of active carts
      if (isMounted) {
        refetch()
      }
    }
  }

  return updateProductQuantity
}

export function useClearCart(): () => Promise<void> {
  const isMounted = useIsMounted()
  const { data: cartQueryData, refetch, updateQuery } = useMarketGetCartsQuery()

  const [clearVendorCart] = useMarketClearVendorCartMutation()

  const clearCart: () => Promise<void> = useCallback(async () => {
    const vendorAndStoreIDs =
      cartQueryData?.me?.user.carts
        .map((cart) => [cart?.vendor.id, cart.store.id])
        .filter((IDs: Array<string>) => IDs[0]) || []

    updateQuery((prevData) => {
      const clonedPrevData = cloneDeep(prevData)
      return set(clonedPrevData, 'me.users.carts', [])
    })

    await Promise.all(
      vendorAndStoreIDs.map(([vendorID, storeID]) =>
        clearVendorCart({ variables: { storeID, vendorID } }),
      ),
    )

    if (isMounted) {
      refetch()
    }
  }, [
    cartQueryData?.me?.user.carts,
    clearVendorCart,
    isMounted,
    refetch,
    updateQuery,
  ])

  return clearCart
}

function getProductQuantityFromCarts(
  vendorProductID: string,
  carts: NonNullable<MarketGetCartsQuery['me']>['user']['carts'] = [],
): number {
  let quantity = 0

  for (const cart of carts) {
    const lineItemMatch = cart.lineItems.nodes.find(
      (lineItem) => lineItem.vendorProduct.id === vendorProductID,
    )

    if (lineItemMatch !== undefined) {
      quantity = lineItemMatch.quantity || 0
      break
    }
  }

  return quantity
}

export function useProductQuantity(vendorProductID: string): number {
  const { data } = useMarketGetCartsQuery()
  const isMounted = useIsMounted()

  const [quantity, setQuantity] = useState(
    getProductQuantityFromCarts(vendorProductID, data?.me?.user.carts || []),
  )

  useEffect(() => {
    if (isMounted) {
      setQuantity(
        getProductQuantityFromCarts(
          vendorProductID,
          data?.me?.user.carts || [],
        ),
      )
    }
  }, [data, isMounted, vendorProductID])

  return quantity
}

export function useProductQuantities(
  vendorProductIDs: string[] = [],
): Map<string, number> {
  const { data } = useMarketGetCartsQuery()
  const [productQuantities, setProductQuantities] = useState(new Map())

  const foundAllProductQuantities = useCallback(
    () => vendorProductIDs.length === productQuantities.size,
    [productQuantities.size, vendorProductIDs.length],
  )

  useEffect(() => {
    const { carts = [] } = data?.me?.user || {}

    for (const cart of carts) {
      for (const lineItem of cart.lineItems.nodes) {
        if (vendorProductIDs.includes(lineItem.vendorProduct.id)) {
          productQuantities.set(lineItem.vendorProduct.id, lineItem.quantity)

          if (foundAllProductQuantities()) {
            break
          }
        }
      }

      if (foundAllProductQuantities()) {
        break
      }
    }

    setProductQuantities(productQuantities)
  }, [data, foundAllProductQuantities, productQuantities, vendorProductIDs])

  return productQuantities
}

export function useVendor(vendorID: string): Vendor {
  const getVendor = useCallback(
    (currentVendors = []) =>
      Array.isArray(currentVendors)
        ? currentVendors.find(
            (vendor) => vendor.id === vendorID || vendor.slug === vendorID,
          )
        : null,
    [vendorID],
  )

  const { vendors } = useVendorsForViewer({
    fetchPolicy: 'cache-first',
  })

  const [vendor, setVendor] = useState(getVendor(vendors))

  useEffect(() => {
    setVendor(getVendor(vendors))
  }, [getVendor, vendors])

  return vendor
}

/**
 * @param {string} vendorID
 * @param {import('@apollo/client').QueryHookOptions} options
 */
export function useOrderGuide(
  vendorID: string,
  options = {},
): Pick<MarketGetOrderGuideQueryResult, 'data' | 'loading' | 'error'> {
  const {
    departmentID,
    error: departmentIDError,
    loading: departmentIDLoading,
  } = useDepartmentID()

  const { data, error, loading } = useMarketGetOrderGuideQuery({
    variables: {
      storeDepartmentID: departmentID || '',
      vendorID,
    },
    skip: departmentIDLoading,
    ...options,
  })

  return {
    data,
    error: error || departmentIDError,
    loading: loading || departmentIDLoading,
  }
}

export function useProductOrderHistory(
  productID: string,
  options = {},
): Pick<
  MarketGetVendorProductOrderHistoryQueryHookResult,
  'data' | 'error' | 'loading'
> {
  const { data, error, loading } = useMarketGetVendorProductOrderHistoryQuery({
    variables: {
      limit: 5,
      productID,
    },
    ...options,
  })

  return { data, error, loading }
}

export function useDepartmentID(): Pick<
  MarketGetCurrentUserQueryResult,
  'error' | 'loading'
> & { departmentID: string | null } {
  const { data, error, loading } = useCurrentUser()

  const defaultDepartmentName =
    data?.me?.user.defaultDepartmentName || DepartmentName.Default

  const departmentID =
    loading || error != null
      ? null
      : data?.me?.user.storeDepartments.nodes.find(
          ({ name }) => name === defaultDepartmentName,
        )?.id || null

  return { departmentID, error, loading }
}

export function useVendorStoreDepartmentAccountID(vendorID: string): Pick<
  MarketGetAllVendorsQueryResult,
  'error' | 'loading'
> & {
  vsdaID: string | null
} {
  const {
    departmentID,
    error: departmentError,
    loading: departmentLoading,
  } = useDepartmentID()

  const {
    data,
    error: vendorsError,
    loading: vendorsLoading,
  } = useMarketGetAllVendorsQuery()

  const error = departmentError || vendorsError
  const loading = departmentLoading || vendorsLoading

  const vsdaID =
    loading || error != null
      ? null
      : data?.me?.user.storeDepartments.nodes
          .find(({ id }) => id === departmentID)
          ?.vendorStoreDepartmentAccounts.nodes.find(
            ({ vendor }) => vendor.id === vendorID,
          )?.id || null

  return { vsdaID, error, loading }
}
