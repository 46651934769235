import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  tag: {
    backgroundColor: theme.colors.GRAY_1,
    borderRadius: 6,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
  tagSuccess: {
    backgroundColor: theme.colors.SUCCESS_BACKGROUND,
    color: theme.colors.SUCCESS,
  },
}))

type Props = {
  className?: string
  type?: string
  label?: string
}

const Tag = (
  props: Props & Partial<React.HTMLAttributes<HTMLDivElement>>,
): JSX.Element => {
  const classes = useStyles(props)
  const { className, type, label, ...rest } = props
  return (
    <div
      className={clsx(className, classes.tag, {
        [classes.tagSuccess]: type === 'success',
      })}
      {...rest}
    >
      {label}
    </div>
  )
}

export default Tag
