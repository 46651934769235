import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { MarketGetCartsQuery } from '@vori/gql-market'

import CartItem from './CartItem'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  cartDetails: {
    maxWidth: 450,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  cartItem: {
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderBottom: `1px solid ${theme.colors.GRAY_1}`,
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
}))

type Props = {
  cart: NonNullable<MarketGetCartsQuery['me']>['user']['carts'][number]
  onLineItemRemove?: (lineItemID: string) => void
}

const VendorCartDetails = (props: Props): JSX.Element | null => {
  const classes = useStyles()
  const { cart, onLineItemRemove } = props
  if (!cart) {
    return null
  }
  const { lineItems } = cart
  return (
    <div className={classes.cartDetails}>
      {lineItems.nodes.map((lineItem) => (
        <CartItem
          className={classes.cartItem}
          lineItem={lineItem}
          onLineItemRemove={onLineItemRemove}
          key={lineItem.id}
        />
      ))}
    </div>
  )
}

export default VendorCartDetails
