import React, { useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { BuyXGetYFreeDeal, FixedDeal, PercentageDeal } from '@vori/gql-market'

import ProductCard from '../product/ProductCard'
import ViewLoader from '../ui/ViewLoader'
import WithActiveDeal from '../product/WithActiveDeal'
import WithBasketQuantity from '../product/WithBasketQuantity'
import WithOrderHistory from '../product/WithOrderHistory'

import { useOrderGuide } from '../../graphql/graphqlHooks'

const useStyles = makeStyles((theme) => ({
  productsContainer: {
    marginTop: theme.spacing(2),
  },

  productContainer: {
    marginBottom: theme.spacing(2),
  },
}))

const EnhancedProductCard = WithActiveDeal(
  WithBasketQuantity(WithOrderHistory(ProductCard)),
)

type Props = {
  vendorID: string
}

const VendorOrderGuide = (props: Props): JSX.Element | null => {
  const firstLoad = useRef(true)
  const { vendorID } = props

  const { data, loading } = useOrderGuide(vendorID, {
    fetchPolicy: 'cache-and-network', // we need to check for the lastest
  })

  const classes = useStyles(props)

  if (!vendorID) {
    return null
  }

  if (loading && firstLoad.current) {
    return <ViewLoader />
  }

  firstLoad.current = false

  const products =
    data?.getVendorStoreDepartmentAccountForVendor?.orderGuide?.vendorProducts
      ?.nodes || []

  return (
    <Container maxWidth="md">
      <Typography variant="h5">Order Guide</Typography>
      <div className={classes.productsContainer}>
        {products.length === 0 && 'No Order Guides found.'}
        {products.map((product) => (
          <div
            className={classes.productContainer}
            key={`${product.id}-container`}
          >
            <EnhancedProductCard
              activeDeal={
                product.activeDeal as FixedDeal &
                  BuyXGetYFreeDeal &
                  PercentageDeal
              }
              fromGQL
              key={product.id}
              orderHistory={[]}
              productData={product}
              showInStock
            />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default VendorOrderGuide
