import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { makeStyles } from '@material-ui/core/styles'

import { MarketGetCartsQuery } from '@vori/gql-market'

import { displayCurrency } from '../../helpers/utils'
import CartItem from '../checkout/CartItem'
import SubtleCard from '../ui/SubtleCard'
import VendorAvatar from '../vendor/VendorAvatar'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  vendorBasket: {
    '&+&': {
      marginTop: theme.spacing(6),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  headerCenter: {
    flex: 1,
  },
  headerCenterTopRow: {
    display: 'flex',
    alignItems: 'baseline',
  },
  vendorName: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    marginRight: theme.spacing(2),
  },
  subtotal: {
    color: theme.colors.GRAY_2,
  },
  deliveryTime: {
    color: theme.colors.GRAY_2,
  },
  productsList: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    marginBottom: theme.spacing(4),
  },
  subtleCard: {
    padding: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
}))

type Props = {
  cart: NonNullable<MarketGetCartsQuery['me']>['user']['carts'][number]
  expandedByDefault: boolean
}

const VendorBasket = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { cart, expandedByDefault } = props
  const [viewExpanded, setViewExpanded] = useState(expandedByDefault !== false)

  const { vendor, lineItems, subTotal } = cart
  const { imgURL } = vendor
  const vendorName = vendor.name || 'Vendor'

  const toggleExpanded = () => {
    setViewExpanded(!viewExpanded)
  }
  const ArrowIcon = viewExpanded ? ArrowDropUpIcon : ArrowDropDownIcon
  return (
    <div className={classes.vendorBasket}>
      <div className={classes.header}>
        <VendorAvatar imgURL={imgURL as string} vendorName={vendorName} />
        <div className={classes.headerCenter}>
          <div className={classes.headerCenterTopRow}>
            <Typography className={classes.vendorName}>{vendorName}</Typography>
            <div className={classes.subtotal}>{`${displayCurrency(
              subTotal,
            )}`}</div>
          </div>
          <div className={classes.deliveryTime}>Delivery: Next Available</div>
        </div>
        <IconButton onClick={toggleExpanded}>
          <ArrowIcon />
        </IconButton>
      </div>
      {viewExpanded && (
        <div className={classes.productsList}>
          {lineItems.nodes.map((lineItem) => (
            <SubtleCard className={classes.subtleCard} key={lineItem.id}>
              <CartItem lineItem={lineItem} />
            </SubtleCard>
          ))}
        </div>
      )}
    </div>
  )
}

export default VendorBasket
