import { cloneDeep, set } from 'lodash'
import { isValid } from 'date-fns'
import { useParams, useHistory, generatePath, Redirect } from 'react-router-dom'
import clsx from 'clsx'
import React, { useCallback, useMemo, useState } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

import {
  useMarketGetCartsQuery,
  useMarketPlaceOrderMutation,
} from '@vori/gql-market'

import CheckoutErrorModal from '../../components/checkout/CheckoutErrorModal'
import CheckoutSideNav from '../../components/checkout/CheckoutSideNav'
import CheckoutSuccessModal from '../../components/checkout/CheckoutSuccessModal'
import LoadingModal from '../../components/checkout/LoadingModal'
import ShippingInfo from '../../components/checkout/ShippingInfo'
import VendorCartDetails from '../../components/checkout/VendorCartDetails'
import BodyBackground from '../../components/ui/BodyBackground'
import SubtleCard from '../../components/ui/SubtleCard'
import VendorCartSummary from '../../components/checkout/VendorCartSummary'
import { Colors, CustomTheme } from '../../theme'
import { trackAnalyticsEvent, Events } from '../../helpers/analytics'
import { CHECKOUT, HOME } from '../../constants/routes'
import { useLayout } from '../../helpers/hooks'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: { marginTop: theme.spacing(4) },
  mobileContainer: { marginTop: theme.spacing(2) },
  icon: {
    width: '2em',
    height: '2em',
  },
  card: {
    backgroundColor: theme.colors.WHITE,
    borderRadius: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(
      4,
    )}px ${theme.spacing(4)}px`,
  },
  cartSelectorGridItem: {
    display: 'flex',
  },
  sectionHeadline: {
    marginTop: 0,
  },
}))

const Checkout = (): JSX.Element => {
  const classes = useStyles()
  const isMobile = useLayout()
  const { vendor: vendorSlug } = useParams<{ vendor: string }>()
  const history = useHistory()
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [creatingOrder, setCreatingOrder] = useState(false)
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)

  const {
    data: cartData,
    loading,
    updateQuery,
  } = useMarketGetCartsQuery({
    fetchPolicy: 'network-only',
  })

  const [placeOrder] = useMarketPlaceOrderMutation()

  const vendorCarts = useMemo(
    () =>
      cartData?.me?.user?.carts.filter((cart) => cart.vendor.isMarketEnabled) ||
      [],
    [cartData?.me?.user?.carts],
  )

  const activeCart = useMemo(
    () =>
      vendorCarts?.find(
        (cart) =>
          vendorSlug != null &&
          (cart.vendor?.slug === vendorSlug || cart.vendor?.id === vendorSlug),
      ),
    [vendorCarts, vendorSlug],
  )

  const activeCartEmpty = !activeCart || activeCart.lineItemCount === 0
  const activeVendor = activeCart?.vendor
  const activeVendorID = activeVendor?.id

  const updateCartQueryOnRemoveProduct = useCallback(
    (lineItemID: string) => {
      updateQuery((previousResult) => {
        const cartIndex =
          previousResult.me?.user.carts.findIndex(
            (cart) => cart.id === activeCart?.id,
          ) ?? -1

        if (cartIndex !== -1) {
          return set(
            cloneDeep(previousResult),
            `me.user.carts[${cartIndex}].lineItems.nodes`,
            previousResult.me?.user.carts[cartIndex].lineItems.nodes.filter(
              (lineItem) => lineItem.id !== lineItemID,
            ),
          )
        } else {
          return previousResult
        }
      })
    },
    [activeCart, updateQuery],
  )

  const handleOnSelectVendor = (vendorId: string) => {
    history.push(generatePath(CHECKOUT, { vendor: vendorId }))
  }

  const handleOnPlaceOrder = async (shippingInfo: {
    storeID: string
    noteToVendor: string
    deliveryTimePreference: string
  }) => {
    if (activeVendor == null) {
      return
    }

    setCreatingOrder(true)
    setLoadingModalOpen(true)
    // Track all this info to enable recovery in case cart items gets lost.
    // (see https://vori.atlassian.net/browse/VP-543)
    trackAnalyticsEvent(Events.placeOrderClicked, {
      activeCart,
      activeVendor,
      shippingInfo,
    })
    const { noteToVendor, deliveryTimePreference } = shippingInfo
    const UTCDate = new Date(deliveryTimePreference).toUTCString()
    const isValidDate = isValid(new Date(UTCDate))

    try {
      await placeOrder({
        variables: {
          vendorID: activeVendorID || '',
          storeID: shippingInfo.storeID || '',
          // Legit key not really needed here due to existing state management
          idempotencyKey: `c_${activeCart?.id}_${new Date().toISOString()}`,
          noteToVendor,
          deliveryDate: isValidDate ? UTCDate : null,
        },
      })
      // TODO ensure the proper success modal is shown despite top-level re-renders after the mutation
      // eslint-disable-next-line no-alert
      alert('Order placed successfully!')
      setLoadingModalOpen(false)
      setSuccessModalOpen(true)
      setCreatingOrder(false)
    } catch {
      setLoadingModalOpen(false)
      setCreatingOrder(false)
      setErrorMessage(
        'An error occured while creating order. Please contact info@vori.com for support.',
      )
      setErrorModalOpen(true)
    }
  }
  const handleOnHideSuccessModal = async () => {
    setSuccessModalOpen(false)
    history.push(generatePath(CHECKOUT, {}))
  }

  if (!loading && vendorCarts.length > 0 && !activeVendor) {
    const firstVendor = vendorCarts[0].vendor
    return (
      <Redirect
        to={generatePath(CHECKOUT, {
          vendor: firstVendor?.slug || firstVendor?.id,
        })}
      />
    )
  }
  const shippingCard = activeCart && (
    <SubtleCard>
      <ShippingInfo
        cart={activeCart}
        hasOutOfStock={
          activeCart.vendor.disableOutOfStockPurchases &&
          activeCart.outOfStockProducts.nodes.length > 0
        }
        disablePlaceOrder={
          creatingOrder ||
          (activeCart.vendor.disableOutOfStockPurchases &&
            activeCart.outOfStockProducts.nodes.length > 0)
        }
        onPlaceOrder={handleOnPlaceOrder}
      />
    </SubtleCard>
  )
  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      {
        <CheckoutSuccessModal
          handleOnClose={handleOnHideSuccessModal}
          isOpen={successModalOpen}
          vendorName={activeVendor?.name || ''}
        />
      }
      {errorModalOpen && (
        <CheckoutErrorModal
          errorMessage={errorMessage}
          handleOnClose={() => setErrorModalOpen(false)}
          isOpen={errorModalOpen}
        />
      )}
      {loadingModalOpen && <LoadingModal isOpen={loadingModalOpen} />}
      {isMobile ? (
        loading ? (
          'Loading...'
        ) : (
          <Container className={classes.mobileContainer}>
            <Grid container spacing={2}>
              {vendorCarts.length > 1 && (
                <Grid className={classes.cartSelectorGridItem} item xs={12}>
                  <div className={clsx(classes.card, classes.cardContent)}>
                    <h2 className={classes.sectionHeadline}>Select Cart</h2>
                    <Select
                      value={vendorSlug}
                      onChange={(
                        event: React.ChangeEvent<{
                          name?: string
                          value: unknown
                        }>,
                      ) => {
                        handleOnSelectVendor(event.target.value as string)
                      }}
                      renderValue={() => activeVendor?.name || 'Select Cart'}
                    >
                      {vendorCarts &&
                        vendorCarts.map((cart) => (
                          <MenuItem
                            key={cart.id}
                            value={cart.vendor.slug || cart.vendor.id}
                          >
                            <VendorCartSummary cart={cart} />
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                {activeVendor && (
                  <div className={clsx(classes.card, classes.cardContent)}>
                    <h2 className={classes.sectionHeadline}>{`Your ${
                      activeVendor?.name || ''
                    } Cart`}</h2>
                    {activeCartEmpty && 'No items in cart'}
                    {!activeCartEmpty && (
                      <VendorCartDetails
                        cart={activeCart}
                        onLineItemRemove={(lineItemID) => {
                          updateCartQueryOnRemoveProduct(lineItemID)
                        }}
                      />
                    )}
                  </div>
                )}
              </Grid>
              {!activeCartEmpty && (
                <Grid item xs={12}>
                  {shippingCard}
                </Grid>
              )}
            </Grid>
          </Container>
        )
      ) : (
        <Container className={classes.container} maxWidth="lg">
          <Grid container spacing={3}>
            <Grid className={classes.cartSelectorGridItem} item xs={3}>
              <div className={classes.card}>
                {vendorCarts && (
                  <CheckoutSideNav
                    carts={vendorCarts}
                    handleOnSelectVendor={handleOnSelectVendor}
                    selectedVendorSlug={vendorSlug}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className={clsx(classes.card, classes.cardContent)}>
                <Grid container spacing={1}>
                  {loading && 'Loading'}
                  {!loading &&
                    (vendorCarts.length === 0 ? (
                      <Redirect to={HOME} />
                    ) : (
                      <>
                        {!activeVendor && 'Select a Vendor'}
                        {activeVendor && (
                          <>
                            <Grid item xs={7}>
                              <h2
                                className={classes.sectionHeadline}
                              >{`Items in your ${
                                activeVendor?.name || ''
                              } Cart`}</h2>
                              {activeCartEmpty && 'No items in cart'}
                              {!activeCartEmpty && (
                                <VendorCartDetails
                                  cart={activeCart}
                                  onLineItemRemove={(lineItemID) => {
                                    updateCartQueryOnRemoveProduct(lineItemID)
                                  }}
                                />
                              )}
                            </Grid>
                            {!activeCartEmpty && (
                              <Grid item xs={5}>
                                {shippingCard}
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </BodyBackground>
  )
}

export default Checkout
