import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
}))

type Props = {
  className?: string
  children?: ReactNode
}

const FormSection = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { className, children } = props
  return <div className={clsx(className, classes.section)}>{children}</div>
}

export default FormSection
