import * as Mixpanel from 'mixpanel-browser'

// Standardized event names
// Caution: When adding new events, make sure that they don't conflict
// with existing event names that we're already tracking eg on other platforms.
export const Events = {
  placeOrderClicked: 'placeOrderClicked',
}
const EventNames = {
  [Events.placeOrderClicked]: 'Market_PlaceOrder_Clicked',
}

export function trackAnalyticsEvent(
  eventType: string,
  data: Mixpanel.Dict | undefined,
): void {
  const eventName = EventNames[eventType]
  if (!eventName) {
    // Don't throw an error to fail gracefully
    // eslint-disable-next-line no-console
    console.error(`Event "${eventType}" is not registered and won't be logged`)
    return
  }
  try {
    // Catch Mixpanel errors, e.g. if it wasn't initialized while in development
    Mixpanel.track(eventName, data)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Error tracking Mixpanel event`, e)
  }
}
