import React from 'react'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {
  BuyXGetYFreeDeal,
  FixedDeal,
  MarketVendorProductWithActiveDealFieldsFragment,
  PercentageDeal,
} from '@vori/gql-market'
import { Flex } from '@vori/gourmet-components'

import { useActivePromotionsByVendor, useLayout } from '../../helpers/hooks'
import { CustomTheme } from '../../theme'
import ProductCard from '../product/ProductCard'
import WithActiveDeal from '../product/WithActiveDeal'
import WithBasketQuantity from '../product/WithBasketQuantity'
import HorizontalScrollList from '../ui/HorizontalScrollList'
import ViewLoader from '../ui/ViewLoader'

const useStyles = makeStyles((theme: CustomTheme) => ({
  sectionLink: {
    background: 'transparent',
    border: '0 none',
    padding: 0,
    fontSize: '1rem',
    color: theme.colors.VORI_PURPLE,
    fontWeight: 'bold',
    textDecoration: 'none',
  },

  emptyHeadline: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    fontWeight: 'bold',
    color: theme.colors.GRAY_5,
  },

  filterTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
}))

const EnhancedProductCard = WithActiveDeal(WithBasketQuantity(ProductCard))

type Deal = NonNullable<
  MarketVendorProductWithActiveDealFieldsFragment['activeDeal']
>

type ProductWithDeal =
  Partial<MarketVendorProductWithActiveDealFieldsFragment> & {
    deal: Deal
  }

type ProductsByDeal = Record<string, ProductWithDeal[]>

type Props = { vendorID: string }

const VendorPromotions = (props: Props): JSX.Element | null => {
  const { vendorID } = props
  const classes = useStyles(props)
  const isMobile = useLayout()

  const {
    data: vendorPromotions,
    loading,
    error,
  } = useActivePromotionsByVendor(vendorID)

  if (error != null) {
    return (
      <Grid item>
        <Typography className={classes.emptyHeadline} variant="h5">
          There was an error fetching promotions.
        </Typography>
      </Grid>
    )
  }

  if (loading) {
    return <ViewLoader />
  }

  const productsWithDeal = vendorPromotions.reduce((products, promotion) => {
    const deals = promotion.deals.nodes || []

    return products?.concat(
      ...deals.map((deal) => {
        const dealProducts = deal.vendorProducts.nodes || []

        return dealProducts.map((dealProduct) => {
          if (dealProduct) {
            return {
              ...dealProduct,
              deal,
            }
          } else {
            return {
              deal,
            }
          }
        })
      }),
    )
  }, [] as ProductWithDeal[])

  const productsByDeal: ProductsByDeal = productsWithDeal.reduce(
    (productsByBrand, productWithDeal) => {
      const dealName = productWithDeal?.deal?.displayName
      const brandName = productWithDeal?.product?.brandName
      const deal = `${brandName} ${dealName}`

      return deal != null
        ? {
            ...productsByBrand,
            [deal]: [
              ...(productsByBrand[deal] || []),
              {
                ...productWithDeal,
              },
            ],
          }
        : productsByBrand
    },
    {} as ProductsByDeal,
  )

  return (
    <Grid container spacing={isMobile ? 1 : 4}>
      {
        <>
          {productsWithDeal.length === 0 ? (
            <Flex center flex={1}>
              <Typography className={classes.emptyHeadline} variant="h5">
                No promotions found
              </Typography>
            </Flex>
          ) : null}

          {Object.keys(productsByDeal).map((dealName) => {
            const products = productsByDeal[dealName] || []

            return (
              <div key={dealName}>
                <Grid item>
                  <Typography variant="h5">{dealName}</Typography>
                </Grid>

                <Grid
                  key={`vendor-promotion-${dealName}`}
                  container
                  spacing={isMobile ? 1 : 4}
                >
                  <Grid item>
                    <HorizontalScrollList
                      items={products.slice(0, 10).map((product) => (
                        <EnhancedProductCard
                          activeDeal={
                            product.deal as PercentageDeal &
                              BuyXGetYFreeDeal &
                              FixedDeal
                          }
                          fromGQL
                          key={product.id}
                          productData={product}
                          showInStock
                        />
                      ))}
                    />
                  </Grid>
                </Grid>
              </div>
            )
          })}
        </>
      }
    </Grid>
  )
}

export default VendorPromotions
