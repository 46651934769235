import { generatePath } from 'react-router-dom'

import { VENDOR_HOME } from '../constants/routes'

export const VENDOR_HOME_ROUTE = ''
export const VENDOR_PROMOTIONS_ROUTE = 'promotions'
export const VENDOR_ORDERGUIDE_ROUTE = 'order-guide'
export const VENDOR_CATALOG_ROUTE = 'catalog'

export function getVendorRoutes(slugOrID: string): {
  promotionsURL: string
  orderGuideURL: string
  catalogURL: string
} {
  const promotionsURL = generatePath(VENDOR_HOME, {
    vendor: slugOrID,
    activeTab: VENDOR_PROMOTIONS_ROUTE,
  })

  const orderGuideURL = generatePath(VENDOR_HOME, {
    vendor: slugOrID,
    activeTab: VENDOR_ORDERGUIDE_ROUTE,
  })

  const catalogURL = generatePath(VENDOR_HOME, {
    vendor: slugOrID,
    activeTab: VENDOR_CATALOG_ROUTE,
  })

  return {
    promotionsURL,
    orderGuideURL,
    catalogURL,
  }
}
