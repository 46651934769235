import React from 'react'
import { useHistory, generatePath, useParams } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { useMarketGetCartsQuery } from '@vori/gql-market'

import VendorBasket from './VendorBasket'
import { CHECKOUT } from '../../constants/routes'
import { pluralize, displayCurrency } from '../../helpers/utils'
import { useClearCart } from '../../graphql/graphqlHooks'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  cart: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // prevent jumps when quantity control is engaged
    minWidth: 575,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  headerRow: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      1,
    )}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    boxShadow: `0 0 10px hsla(0, 0%, 73%, 0.15)`,
  },
  headline: {
    fontWeight: 'bold',
  },
  contentRow: {
    flex: 1,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0 ${theme.spacing(
      3,
    )}px`,
    overflowY: 'scroll',
  },
  footerRow: {
    padding: theme.spacing(2),
    display: 'flex',
    boxShadow: `0 -3px 10px hsla(0, 0%, 73%, 0.15)`,
    '& .MuiButton-label': {
      display: 'flex',
    },
  },
  checkoutButton: {
    flex: 1,
    // letterSpacing: 0.5,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
  checkoutButtonText: {
    flex: 1,
  },
  checkoutTotal: {
    borderRadius: 20,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    backgroundColor: ' rgba(0,0,0,0.2)',
    marginLeft: theme.spacing(1),
  },
  emptyMessage: {
    textAlign: 'center',
  },
  clearBtn: {
    borderColor: theme.colors.ERROR,
    color: theme.colors.ERROR,
  },
}))

type Props = {
  onClose: () => void
  vendorID: string
}

const Cart = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const history = useHistory()
  const { vendor: vendorSlug } = useParams<{ vendor: string }>()
  const clearCart = useClearCart()

  const { data: cartData, loading } = useMarketGetCartsQuery()

  const [openClearBasketDialog, setClearBasketDialog] = React.useState(false)
  const showClearBasketDialog = () => {
    setClearBasketDialog(true)
  }
  const hideClearBasketDialog = () => {
    setClearBasketDialog(false)
  }
  const { onClose, vendorID: currentVendorID } = props

  const handleClearBasket = () => {
    // eslint-disable-next-line no-console
    console.log('User cleared basket') // Leave a breadcrumb for Fullstory
    hideClearBasketDialog()
    clearCart()
    onClose()
  }

  const vendorCartsRaw = cartData?.me?.user.carts || []

  const vendorCarts = vendorCartsRaw
    .filter((cart) => cart.vendor.isMarketEnabled && cart.lineItemCount > 0)
    // Show cart of active vendor on top
    .sort((cartA, cartB) => {
      if (!currentVendorID) {
        return 0
      }
      return cartA.vendor.id === currentVendorID
        ? -1
        : cartB.vendor.id === currentVendorID
          ? 1
          : 0
    })
  const cartCount = vendorCarts.length

  const activeCart = vendorCarts?.find(
    (cart) =>
      vendorSlug != null &&
      (cart.vendor.slug === vendorSlug || cart.vendor.id === vendorSlug),
  )

  return (
    <div className={classes.cart}>
      <div className={classes.headerRow}>
        <div>
          <Typography className={classes.headline} variant="h5">
            My {pluralize('Cart', cartCount)}
          </Typography>
          <Typography variant="caption">
            {cartCount} {pluralize('Vendor', cartCount)}
          </Typography>
        </div>
        <div>
          <Button
            data-test-id="clear-basket-btn"
            disabled={cartCount === 0}
            variant="outlined"
            onClick={showClearBasketDialog}
          >
            Clear Basket
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
      <div className={classes.contentRow}>
        {loading && 'Loading...'}
        {!loading && (
          <>
            {cartCount > 0 &&
              vendorCarts.map((cart) => (
                <VendorBasket
                  key={cart.id}
                  cart={cart}
                  expandedByDefault={
                    currentVendorID ? currentVendorID === cart.vendor.id : false
                  }
                />
              ))}
            {cartCount === 0 && (
              <Typography className={classes.emptyMessage} variant="h6">
                Your cart is empty.
              </Typography>
            )}
          </>
        )}
      </div>
      <div className={classes.footerRow}>
        <Button
          disabled={cartCount === 0}
          data-test-id="go-to-checkout-button"
          className={classes.checkoutButton}
          variant="contained"
          color="primary"
          onClick={() => {
            // While visiting vendor-specific pages, open the corresponding
            // vendor-specific checkout page.
            const checkoutURL = generatePath(CHECKOUT, { vendor: vendorSlug })
            onClose()
            history.push(checkoutURL)
          }}
        >
          <div className={classes.checkoutButtonText}>Go to Checkout</div>
          <div className={classes.checkoutTotal}>
            {displayCurrency(
              activeCart
                ? activeCart.subTotal
                : vendorCarts.reduce((sum, cart) => sum + cart.subTotal, 0),
            )}
          </div>
        </Button>
      </div>
      <Dialog open={openClearBasketDialog} onClose={hideClearBasketDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove all items from your Cart?
            <br />
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideClearBasketDialog} autoFocus variant="outlined">
            Cancel
          </Button>
          <Button
            data-test-id="clear-cart-btn"
            className={classes.clearBtn}
            onClick={handleClearBasket}
            variant="outlined"
          >
            Clear Cart
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Cart
