import React from 'react'
import { useParams, Redirect } from 'react-router-dom'

import { getVendorRoutes } from '../../routers/VendorRoutes'

const VendorHome = (): JSX.Element => {
  const { vendor: slugOrID } = useParams<{ vendor: string }>()
  const { catalogURL } = getVendorRoutes(slugOrID)

  return <Redirect to={catalogURL} />
}

export default VendorHome
