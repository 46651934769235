import React, { useState, useContext, useEffect, useCallback } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { Link, Redirect, useLocation } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import SubtleCard from '../../components/ui/SubtleCard'
import BodyBackground from '../../components/ui/BodyBackground'
import FormField from '../../components/ui/FormField'
import FormSection from '../../components/ui/FormSection'
import { Colors } from '../../theme'
import { FORGOT_PASSWORD, SIGN_UP, HOME } from '../../constants/routes'
import { FeatureFlags } from '../../config/AppConfig'
import { AuthContext } from '../../auth/AuthContext'
import { REDIRECT, DEMO_TRIGGERED } from '../../constants'
import { getLocalStorage, setLocalStorage } from '../../helpers/utils'
import { KeysMap } from '../../helpers/types'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(4),
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  nextButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
  alternateLink: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
}))

const DEMO_ACCOUNTS: KeysMap = {
  SUNRIDGE_FARMS: 'f4348f46-cad5-11eb-b8bc-0242ac130003',
}

const SignIn = (): JSX.Element => {
  const classes = useStyles()
  const { signIn, auth } = useContext(AuthContext)
  const { errorMessage, isAuthenticating, isAuthenticated } = auth
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  })
  const { search, state } = useLocation<{
    search: string
    from: { pathname: string }
  }>()
  const searchParams = useCallback(
    () => new URLSearchParams(search),
    [search],
  )()
  const type = searchParams.get('type')
  const vendor = searchParams.get('vendor')
  const demoID = searchParams.get('id')
  const demoTriggered = getLocalStorage(DEMO_TRIGGERED)

  const onEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setEmail(val)

    if (val === '') {
      setError({ ...error, email: 'Email is required' })
    } else if (!isEmail(val)) {
      setError({ ...error, email: 'Email is invalid' })
    } else {
      setError({ ...error, email: '' })
    }
  }

  const onPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setPassword(val)

    if (val === '') {
      setError({ ...error, password: 'Password is required' })
    } else {
      setError({ ...error, password: '' })
    }
  }

  const isValid = password && email && !error.email && !error.password

  const handleRedirect = useCallback(() => {
    let redirectObj = null
    const currentRedirect = getLocalStorage(REDIRECT)
    const redirectParam = searchParams.get('redirect')

    if (
      state &&
      state.from?.pathname !== '/' &&
      currentRedirect &&
      currentRedirect?.shouldRedirect
    ) {
      redirectObj = {
        url: state?.from?.pathname?.replace('/', ''),
        shouldRedirect: true,
      }
    }

    if (redirectParam) {
      redirectObj = { url: redirectParam, shouldRedirect: true }
    }

    if (redirectObj) {
      setLocalStorage(REDIRECT, redirectObj)
    }
  }, [searchParams, state])

  const handleSignIn = () => {
    handleRedirect()

    if (isValid) {
      signIn(email, password)
    }
  }

  useEffect(() => {
    // This is a feature flag hack for Sunridge farms
    const email = process.env[`REACT_APP_${vendor}_DEMO_EMAIL`]
    const password = process.env[`REACT_APP_${vendor}_DEMO_PW`]
    if (
      type === 'demo' &&
      DEMO_ACCOUNTS[vendor as string] &&
      DEMO_ACCOUNTS[vendor as string] === demoID &&
      !isAuthenticated &&
      !demoTriggered &&
      email &&
      password
    ) {
      setLocalStorage(DEMO_TRIGGERED, true)

      handleRedirect()
      signIn(email, password)
    }

    if (isAuthenticated && demoTriggered) {
      setLocalStorage(DEMO_TRIGGERED, false)
    }
  }, [
    type,
    demoID,
    isAuthenticated,
    vendor,
    signIn,
    demoTriggered,
    handleRedirect,
  ])

  if (isAuthenticated) {
    return <Redirect to={HOME} />
  }

  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justify="center">
          <Grid item xs={10} sm={6} md={5} lg={4}>
            <SubtleCard>
              <div className={classes.cardContent}>
                <Typography variant="h5" className={classes.headline}>
                  Sign in to Vori
                </Typography>
                <FormField
                  data-test-id="email-input"
                  autoFocus
                  label="Email"
                  id="email"
                  errorText={error.email}
                  placeholder="jess@localmarket.com"
                  onChange={onEmailInput}
                  value={email}
                />
                <FormField
                  data-test-id="password-input"
                  label="Password"
                  id="password"
                  errorText={error.password}
                  placeholder="••••••••"
                  onChange={onPasswordInput}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleSignIn()
                    }
                  }}
                  value={password}
                  type="password"
                />

                <FormSection>
                  <Link to={FORGOT_PASSWORD}>
                    <span>Forgot password?</span>
                  </Link>
                </FormSection>

                {errorMessage && (
                  <FormSection>
                    <Typography color="error">
                      {errorMessage as string}
                    </Typography>
                  </FormSection>
                )}
                <FormSection>
                  <Button
                    className={classes.nextButton}
                    disabled={isAuthenticating || !isValid}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleSignIn}
                  >
                    {isAuthenticating ? 'Signing in...' : 'Sign in'}
                  </Button>
                </FormSection>
              </div>
            </SubtleCard>
            {FeatureFlags.selfServeSignup && (
              <div className={classes.alternateLink}>
                <Typography>
                  Donʼt have an account? <Link to={SIGN_UP}>Sign Up</Link>
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </BodyBackground>
  )
}

export default SignIn
