import { Button as MdButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Button = withStyles({
  root: {
    // height: '40px'
    textTransform: 'capitalize',
  },
  containedSecondary: {
    color: '#3C279B',
    backgroundColor: '#eaeaf0',
    '&:hover': {
      backgroundColor: '#dcdce7',
    },
    '&:focus': {
      backgroundColor: '#dcdce7',
    },
    '&:active': {
      backgroundColor: '#dcdce7',
    },
  },

  containedPrimary: {
    backgroundColor: '#3C279B',
    '&:focus': {
      backgroundColor: '#452cb1',
    },
    '&:hover': {
      backgroundColor: '#452cb1',
    },
    '&:active': {
      backgroundColor: '#452cb1',
    },
  },
  outlinedPrimary: {
    color: '#3C279B',
  },
  textPrimary: {
    color: '#3C279B',
  },
})(MdButton)

export default Button
