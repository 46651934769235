import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import isEmpty from 'validator/es/lib/isEmpty'
import isEmail from 'validator/es/lib/isEmail'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiPhoneInput from 'material-ui-phone-number'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import {
  useMarketGetCurrentUserQuery,
  useMarketUpdateBuyerProfileMutation,
} from '@vori/gql-market'

import firebase from '../../firebase/firebase'
import Boundary from '../../components/ui/Boundary'
import SubtleCard from '../../components/ui/SubtleCard'
import BodyBackground from '../../components/ui/BodyBackground'
import FormField from '../../components/ui/FormField'
import FormSection from '../../components/ui/FormSection'
import { ACCOUNT } from '../../constants/routes'
import { Colors } from '../../theme'
import { KeysMap } from '../../helpers/types'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(4),
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  buttonRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  updateButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
}))

function EditProfileContainer(): JSX.Element | null {
  const { data, loading } = useMarketGetCurrentUserQuery()

  if (loading) {
    return null
  }

  const isStoreUser = data?.me?.user.retailUser != null

  return (
    <EditProfile
      profile={{
        addressLine1:
          data?.me?.user.retailUser?.retailCompany.store?.address || '',
        email: data?.me?.user.email || '',
        fullname: data?.me?.user.displayName || '',
        mobile: data?.me?.user.phoneNumber || '',
        storeName: data?.me?.user.retailUser?.retailCompany.name || '',
      }}
      isStoreUser={isStoreUser}
    />
  )
}

interface Profile extends KeysMap {
  fullname: string | null
  email: string
  mobile: string | null
  storeName: string | null
  addressLine1: string | null
}

type EditProfileProps = {
  isStoreUser: boolean
  profile: Profile
}

const EditProfile = ({
  profile,
  isStoreUser,
}: EditProfileProps): JSX.Element => {
  const [updateProfileMutation] = useMarketUpdateBuyerProfileMutation()

  const classes = useStyles()
  const history = useHistory()
  const [user, setProfile] = useState<Profile>({
    fullname: profile?.fullname,
    storeName: profile?.storeName,
    email: profile?.email,
    addressLine1: profile?.addressLine1,
    mobile: profile?.mobile,
  })
  const [isOpenModal, setModalOpen] = useState(false)
  const [error, setError] = useState<KeysMap>({})
  const [password, setPassword] = useState<string>('')
  const [updateError, setUpdateError] = useState<string>('')
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const hasChanges = [
    'fullname',
    'storeName',
    'email',
    'addressLine1',
    'mobile',
  ].some((key) => profile?.[key] !== user[key])

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setProfile({ ...user, email: val })
    if (isEmpty(val)) {
      setError({ ...error, email: 'Email is required' })
    } else if (!isEmail(val)) {
      setError({ ...error, email: 'Email is invalid' })
    } else {
      setError({ ...error, email: '' })
    }
  }

  const onFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setProfile({ ...user, fullname: val })
    if (isEmpty(val)) {
      setError({ ...error, fullname: 'Your name is required.' })
    } else {
      setError({ ...error, fullname: '' })
    }
  }
  const onStoreNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setProfile({ ...user, storeName: val })
    if (isEmpty(val)) {
      setError({ ...error, storeName: 'Store name is required.' })
    } else {
      setError({ ...error, storeName: '' })
    }
  }

  const onAddressLine1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setProfile({ ...user, addressLine1: val })
    if (isEmpty(val)) {
      setError({ ...error, addressLine1: 'Address is required.' })
    } else {
      setError({ ...error, addressLine1: '' })
    }
  }

  const onMobileChange = (value: string) => {
    setProfile({ ...user, mobile: value })
    if (isEmpty(value)) {
      setError({ ...error, mobile: 'Phone number required.' })
    } else if (!isMobilePhone(value, 'en-US')) {
      setError({ ...error, mobile: 'Phone number invalid.' })
    } else {
      setError({ ...error, mobile: '' })
    }
  }

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const onPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const updateProfile = async () => {
    if (!hasChanges) {
      return
    }
    try {
      const { email, fullname, mobile } = user
      setIsUpdating(true)
      // if email & password exist && the email has been edited
      // update the email
      if (email !== profile.email) {
        await firebase.updateEmail(password, email)
      }
      await updateProfileMutation({
        variables: {
          email,
          displayName: fullname,
          phoneNumber: mobile,
        },
      })
      setModalOpen(false)
    } catch (e: unknown) {
      if ((e as { code: string }).code === 'auth/wrong-password') {
        setUpdateError('Incorrect password.')
      } else {
        setUpdateError(
          `Something went wrong: ${(e as { message: string }).message}`,
        )
      }
    }
    setIsUpdating(false)
  }

  const isValid = Object.keys(error).every((key) => error[key] === '')

  const confirmModalPassword = () => {
    if (!isValid || !password) {
      return
    }
    updateProfile()
  }

  const onSubmitUpdate = () => {
    if (!isValid) {
      return
    }
    if (user.email !== profile.email) {
      setModalOpen(true)
    } else if (Object.keys(user).some((key) => profile[key] !== user[key])) {
      updateProfile()
    }
  }

  return (
    <Boundary>
      <BodyBackground backgroundColor={Colors.OFFWHITE}>
        <Container className={classes.container} maxWidth="lg">
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <SubtleCard>
                <div className={classes.cardContent}>
                  <Typography variant="h5" className={classes.headline}>
                    Edit Account Details
                  </Typography>
                  <FormField
                    label="Your Name"
                    errorText={error.fullname}
                    placeholder="Jess Smith"
                    onChange={onFullNameChange}
                    value={user.fullname}
                  />
                  <FormField
                    label="Your Email"
                    errorText={error.email}
                    placeholder="jess@localmarket.com"
                    onChange={onEmailChange}
                    value={user.email}
                  />
                  <FormField
                    label="Your Phone Number"
                    errorText={error.mobile}
                    placeholder="650-555-1234"
                    onChange={(value) =>
                      onMobileChange(value as unknown as string)
                    }
                    value={user.mobile}
                    fieldComponent={MuiPhoneInput}
                    defaultCountry="us"
                    disableDropdown
                    onlyCountries={['us']}
                  />
                  {isStoreUser && (
                    <>
                      <FormField
                        disabled
                        label="Your Storeʼs Name"
                        errorText={error.storeName}
                        placeholder="Local Market"
                        onChange={onStoreNameChange}
                        value={user.storeName}
                      />
                      <FormField
                        disabled
                        label="Your Store's Address"
                        errorText={error.addressLine1}
                        placeholder="123 Main Street"
                        onChange={onAddressLine1Change}
                        value={user.addressLine1}
                      />
                    </>
                  )}

                  <FormSection className={classes.buttonRow}>
                    <Button
                      className={classes.updateButton}
                      variant="contained"
                      size="large"
                      onClick={() => {
                        history.push(ACCOUNT)
                      }}
                    >
                      Back to Account
                    </Button>
                    <Button
                      className={classes.updateButton}
                      disabled={!hasChanges || !isValid || isUpdating}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={onSubmitUpdate}
                    >
                      {isUpdating ? 'Updating Profile' : 'Update Profile'}
                    </Button>
                  </FormSection>
                  <Dialog open={isOpenModal} onClose={onCloseModal}>
                    <DialogTitle>Confirm Update</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Please enter your password to confirm your updated email
                        address:
                      </DialogContentText>
                      <FormField
                        autoFocus
                        errorText={error.password}
                        placeholder="••••••••"
                        onChange={onPasswordInput}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                          if (e.key === 'Enter') {
                            confirmModalPassword()
                          }
                        }}
                        value={password}
                        type="password"
                      />
                      {updateError && (
                        <FormSection>
                          <Typography color="error">{updateError}</Typography>
                        </FormSection>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button variant="contained" onClick={onCloseModal}>
                        Cancel
                      </Button>
                      <Button
                        disabled={isUpdating}
                        variant="contained"
                        onClick={confirmModalPassword}
                        color="primary"
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </SubtleCard>
            </Grid>
          </Grid>
        </Container>
      </BodyBackground>
    </Boundary>
  )
}

export default EditProfileContainer
