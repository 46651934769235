/**
 * Used to circumvent Jest's limitation with ES modules which Vite depends on.
 * We basically route all environmental variables through this file/module so
 * we can easily mock the file in Jest.
 *
 * @see {@link https://stackoverflow.com/a/74882007}
 */

const {
  PACKAGE_VERSION: ENV_VERSION,
  PROD: ENV_PROD,
  VITE_ALGOLIA_API_KEY: ENV_ALGOLIA_API_KEY,
  VITE_ALGOLIA_APP_ID: ENV_ALGOLIA_APP_ID,
  VITE_ALGOLIA_ID: ENV_ALGOLIA_ID,
  VITE_ALGOLIA_INDEX_NAME: ENV_ALGOLIA_INDEX_NAME,
  VITE_ALGOLIA_INDEX_PRODUCTS: ENV_ALGOLIA_INDEX_PRODUCTS,
  VITE_ALGOLIA_SEARCH_ONLY_KEY: ENV_ALGOLIA_SEARCH_ONLY_KEY,
  VITE_ENVIRONMENT: ENVIRONMENT,
  VITE_FIREBASE_API_KEY: ENV_FIREBASE_API_KEY,
  VITE_FIREBASE_APP_ID: ENV_FIREBASE_APP_ID,
  VITE_FIREBASE_AUTH_DOMAIN: ENV_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_DB_URL: ENV_FIREBASE_DB_URL,
  VITE_FIREBASE_MEASUREMENT_ID: ENV_FIREBASE_MEASUREMENT_ID,
  VITE_FIREBASE_MSG_SENDER_ID: ENV_FIREBASE_MSG_SENDER_ID,
  VITE_FIREBASE_PROJECT_ID: ENV_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET: ENV_FIREBASE_STORAGE_BUCKET,
  VITE_GAE_REST_ENDPOINT: ENV_GAE_REST_ENDPOINT,
  VITE_GOOGLE_MAPS_API_KEY: ENV_GOOGLE_MAPS_API_KEY,
  VITE_GRAPHQL_ENDPOINT: ENV_GRAPHQL_ENDPOINT,
  VITE_PEXELS_API_KEY: ENV_PEXELS_API_KEY,
  VITE_PLAID_ENDPOINT: ENV_PLAID_ENDPOINT,
  VITE_PLAID_ENV: ENV_PLAID_ENV,
  VITE_PLAID_PUBLIC_KEY: ENV_PLAID_PUBLIC_KEY,
  VITE_STRIPE_PUBLISHABLE_KEY: ENV_STRIPE_PUBLISHABLE_KEY,
} = import.meta.env

const ENV_FULLSTORY_ORGANIZATION_ID = 'TFYB4'
const ENV_SENTRY_DSN =
  'https://34c3e7aa785c4a9c916d8e38c2d5fd7c@o388880.ingest.sentry.io/5226303'

export {
  ENV_ALGOLIA_API_KEY,
  ENV_ALGOLIA_APP_ID,
  ENV_ALGOLIA_ID,
  ENV_ALGOLIA_INDEX_NAME,
  ENV_ALGOLIA_INDEX_PRODUCTS,
  ENV_ALGOLIA_SEARCH_ONLY_KEY,
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_DB_URL,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MSG_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
  ENV_FULLSTORY_ORGANIZATION_ID,
  ENV_GAE_REST_ENDPOINT,
  ENV_GOOGLE_MAPS_API_KEY,
  ENV_GRAPHQL_ENDPOINT,
  ENV_PEXELS_API_KEY,
  ENV_PLAID_ENDPOINT,
  ENV_PLAID_ENV,
  ENV_PLAID_PUBLIC_KEY,
  ENV_PROD,
  ENV_SENTRY_DSN,
  ENV_STRIPE_PUBLISHABLE_KEY,
  ENV_VERSION,
  ENVIRONMENT,
}
