import React from 'react'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { ZapIcon } from '@vori/gourmet-icons'

import { toShortDate, getMaxQuantity } from '../../helpers/utils'
import { CustomTheme } from '../../theme'

export const styles = makeStyles((theme: CustomTheme) => ({
  container: {
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      transform: 'translateY(-3px) scale(1.005) translateZ(0)',
    },
  },
  buyAgainCard: {
    padding: theme.spacing(1, 3),
    backgroundColor: theme.colors.OFFWHITE,
    borderRadius: 4,
    '& path': {
      fill: theme.colors.VORI_PURPLE,
    },
    '& svg.MuiSvgIcon-root': {
      width: 8,
      height: 14,
      marginRight: 5,
      verticalAlign: 'text-top',
    },
    '& p': {
      margin: '5px 0 0 0',
      textAlign: 'center',
    },
    '& p:first-child': {
      fontSize: '0.875rem',
      color: theme.colors.GRAY_3,
    },
    '& p:last-of-type': {
      fontSize: '1rem',
      textTransform: 'capitalize',
    },
  },
  textContainer: {
    marginTop: 5,
    color: theme.colors.VORI_PURPLE,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '0.75rem',
    letterSpacing: 1,
    fontWeight: 700,
    width: '100%',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.75,
    filter: 'grayscale(0.55)',
  },
}))

type Props = {
  data: {
    orderDateTime: string | Date
    quantity: number
    unitOfMeasure: string
  }
  basketProps: {
    onQuantityChange: (newQuantity: number) => void
    quantity: number
    maxQuantity: number
    disabled: boolean
  }
  isMobile?: boolean
}

const BuyAgainCard = ({ isMobile, data, basketProps }: Props): JSX.Element => {
  const classes = styles()
  const { orderDateTime, quantity, unitOfMeasure } = data
  const maxQuantity = getMaxQuantity(basketProps.maxQuantity)

  const addToCart = () => {
    if (basketProps.onQuantityChange) {
      basketProps.onQuantityChange(
        Math.min(maxQuantity, basketProps.quantity + quantity),
      )
    }
  }
  return (
    <Grid
      item
      xs={isMobile ? 6 : 'auto'}
      role="button"
      onClick={basketProps.disabled ? undefined : addToCart}
      className={clsx(classes.container, {
        [classes.disabled]: basketProps.disabled,
      })}
    >
      <div className={classes.buyAgainCard}>
        <p>{toShortDate(orderDateTime as string)}</p>
        <p>{`${quantity} ${unitOfMeasure.toLowerCase()}`}</p>
        <div className={classes.textContainer}>
          <ZapIcon />
          get it again
        </div>
      </div>
    </Grid>
  )
}

export default BuyAgainCard
