import React from 'react'
import clsx from 'clsx'

import { MarketGetCartsQuery } from '@vori/gql-market'

import { makeStyles } from '@material-ui/core/styles'

import { displayCurrency, pluralize } from '../../helpers/utils'
import VendorAvatar from '../vendor/VendorAvatar'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  vendorTabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
    textTransform: 'none',
  },
  right: {
    flex: 1,
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  vendorAvatar: {
    border: `2px solid ${theme.colors.VORI_PURPLE_LIGHT}`,
    marginRight: theme.spacing(2),
  },
  vendorName: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: '-6px',
    whiteSpace: 'nowrap',
  },
  cartSummaryLine: {
    color: theme.colors.GRAY_4,
    lineHeight: '0.8',
    fontSize: '0.8rem',
  },
  cartTotalBelowMinimumHighlight: {
    color: theme.colors.ERROR,
  },
  orderMinimumLine: {
    marginTop: 1,
    fontSize: '0.8rem',
  },
}))

type Props = {
  cart: NonNullable<MarketGetCartsQuery['me']>['user']['carts'][number]
}

const VendorCartSummary = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { cart } = props
  const vendor = cart?.vendor
  const vendorName = vendor?.name || 'Vendor'
  const imgURL = vendor?.imgURL
  const retailCompanyID = cart.store.id
  let orderMin = vendor?.orderMin || 0
  const orderMinimums = vendor?.orderMinimums
  const cartSize = cart?.lineItemCount
  const orderTotal = cart?.subTotal || 0

  const storeOrderMin =
    orderMinimums &&
    orderMinimums.find(({ storeID }) => storeID === retailCompanyID)

  if (storeOrderMin) {
    orderMin = storeOrderMin.orderMin
  }

  return (
    <div className={classes.vendorTabContainer}>
      <div>
        <VendorAvatar imgURL={imgURL as string} vendorName={vendorName} />
      </div>
      <div className={classes.right}>
        <div className={classes.vendorName}>{vendorName}</div>
        <div className={classes.cartSummaryLine}>
          <span
            className={clsx({
              [classes.cartTotalBelowMinimumHighlight]:
                orderMin > 0 && orderTotal < orderMin,
            })}
          >
            {displayCurrency(orderTotal)}
          </span>
          {` · ${cartSize} ${pluralize('item', cartSize)}`}
        </div>
        {orderMin > 0 && (
          <div className={classes.orderMinimumLine}>{`${displayCurrency(
            orderMin,
          )} order minimum`}</div>
        )}
      </div>
    </div>
  )
}

export default VendorCartSummary
