import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import FormSection from './FormSection'
import { TextFieldProps } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  sectionHeadline: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}))

type Props = {
  label?: string
  errorText?: string | number | boolean | string[] | null
  placeholder?: string
  value?: unknown
  disabled?: boolean
  fieldComponent?: React.ElementType
  defaultCountry?: string
  disableDropdown?: boolean
  onlyCountries?: string[]
}

const FormField = (props: Props & Partial<TextFieldProps>): JSX.Element => {
  const classes = useStyles(props)
  const {
    label,
    errorText,
    placeholder,
    fieldComponent,
    onChange,
    value,
    ...rest
  } = props
  const FieldComponent = fieldComponent || TextField

  return (
    <FormSection>
      <div className={classes.sectionHeadline}>{label}</div>
      <FieldComponent
        variant="outlined"
        fullWidth
        error={Boolean(errorText)}
        helperText={errorText}
        placeholder={placeholder}
        onChange={onChange}
        value={
          value ||
          '' /* Prevent passing `null`, which would imply uncontrolled mode */
        }
        {...rest}
      />
    </FormSection>
  )
}

export default FormField
