// Login & Account-related routes
export const HOME = '/'
export const ACCOUNT = '/account'
export const ACCOUNT_EDIT = '/account/edit'
export const SIGN_IN = '/sign-in'
export const SIGN_UP = '/sign-up'
export const STORE_INVITATION = '/store-invitation/:inviteCode'
export const FORGOT_PASSWORD = '/forgot-password'

// Home page
export const MY_VENDORS = '/vendors' // full-page list of all vendors
export const MY_PROMOS = '/promos' // full-page list of promos across vendors
export const MY_BESTSELLERS = '/bestsellers'
export const NEW_ITEMS = '/new-items'

// Vendor-specific pages
export const VENDOR_HOME = '/vendors/:vendor/:activeTab?'
export const VENDOR_NOT_FOUND = '/vendors/not-found'
export const VENDOR_SEARCH = '/vendors/:vendor/search'
export const CHECKOUT = '/checkout/:vendor?'

// Deprecated
export const ONBOARDING_PAYMENT_INFO = '/onboarding/payment-info'

// development mode only:
export const STYLEGUIDE = '/dev/styleguide'
