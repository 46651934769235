import React, { useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import { PlaidConfig } from '../../config/AppConfig'
import { CustomTheme } from '../../theme'
import { PlaidAccountType } from '../../views/account/OnboardingPaymentInfo'

const useStyles = makeStyles((theme: CustomTheme) => ({
  connectButton: {
    textAlign: 'center',
  },
  errorMessage: {
    color: theme.colors.ERROR,
    marginTop: theme.spacing(1),
  },
}))

// Note: `usePlaidLink` does not play well with Webpack's hot reloader,
// since it re-embeds their SDK on each reload.
// In development, make sure to reload the page fully after making changes.
const PlaidAccountControl = (props: {
  onPlaidResponseSuccess: (newPlaidResponse: PlaidAccountType) => void
}): JSX.Element => {
  const classes = useStyles(props)
  const { onPlaidResponseSuccess } = props
  const onSuccess = useCallback(
    (token, metadata) => {
      onPlaidResponseSuccess({ token, metadata })
    },
    [onPlaidResponseSuccess],
  )

  const config = {
    ...PlaidConfig,
    onSuccess,
    onExit: (error: unknown, metadata: unknown) => {
      if (error) {
        // TODO log this error to crashlytics
        // eslint-disable-next-line no-console
        console.error('onExit', error, metadata)
      }
    },
  }
  const { open, ready, error } = usePlaidLink(config)
  return (
    <>
      <div className={classes.connectButton}>
        <Button
          onClick={(e) => open(e)}
          disabled={!ready}
          variant="contained"
          color="primary"
        >
          <AddIcon /> Add a bank account
        </Button>
        {error && (
          <div className={classes.errorMessage}>
            An error occurred while adding your bank account
          </div>
        )}
      </div>
    </>
  )
}

export default PlaidAccountControl
