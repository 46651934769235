import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import VendorCard from '../components/vendor/VendorCard'
import ViewLoader from '../components/ui/ViewLoader'

import { useVendorsForViewer } from '../graphql/graphqlHooks'
import { CustomTheme } from '../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  vendorGrid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const MyVendors = (): JSX.Element => {
  const classes = useStyles()
  const [isMounted, setIsMounted] = useState(false)

  const { loading, vendors } = useVendorsForViewer({
    skip: !isMounted,
  })

  // Prevents memory leaks, only need to query VendorsForViewer when component is mounted
  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (loading) return <ViewLoader />

  const noVendors = !vendors || vendors?.length === 0

  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid
        className={classes.vendorGrid}
        container
        spacing={2}
        data-test-id="vendors-list"
      >
        {!loading &&
          !noVendors &&
          vendors.map((vendor) => (
            <Grid item xs={6} sm={4} md={3} lg={3} xl={3} key={vendor.id}>
              <VendorCard vendor={vendor} />
            </Grid>
          ))}
        {noVendors && 'Unable to load vendors. Please contact support'}
      </Grid>
    </Container>
  )
}

export default MyVendors
