import React, { useContext, useState, useEffect } from 'react'
import {
  Redirect,
  Link,
  useHistory,
  useParams,
  generatePath,
} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles'

import { InvitationStatus } from '@vori/gql-market'
import { ENV_GAE_REST_ENDPOINT } from '@vori/market-env'

import isEmail from 'validator/es/lib/isEmail'
import isEmpty from 'validator/es/lib/isEmpty'
import isLength from 'validator/es/lib/isLength'
import trim from 'validator/es/lib/trim'

import SubtleCard from '../../components/ui/SubtleCard'
import BodyBackground from '../../components/ui/BodyBackground'
import FormField from '../../components/ui/FormField'
import FormSection from '../../components/ui/FormSection'
import { Colors, CustomTheme } from '../../theme'
import { SIGN_IN, HOME, VENDOR_HOME } from '../../constants/routes'
import { startIntercomConversation } from '../../config/Intercom'
import { AuthContext } from '../../auth/AuthContext'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  cardContent: {
    padding: theme.spacing(4),
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  sectionHeadline: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  passwordField: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.colors.ERROR,
  },
  nextButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
  alternateLink: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
}))

const isValid = (
  field: Record<string, string>,
  error: Record<string, string>,
) =>
  Object.values(error).every((v) => v === '') &&
  ['fullname', 'email', 'password'].every((fieldName) =>
    Boolean(field[fieldName]),
  )

type Invite = {
  valid: boolean
  didSignUp: boolean
  data: {
    email: string
    invitedByVendorName: string | null
    invitedByVendorSlug: string | null
    invitedByVendorID: string
    orderGuide: string[]
    phoneNumber: string | null
    status: InvitationStatus
    storeID: string
    storeName: string
  }
}

const StoreInvitationSignup = (): JSX.Element => {
  const classes = useStyles()
  const { signUpFromInvite, auth } = useContext(AuthContext)
  const { inviteCode } = useParams<{ inviteCode: string }>()
  const { errorMessage, isAuthenticating, isAuthenticated, isCreatingInvite } =
    auth
  const history = useHistory()
  const [error, setError] = useState<Record<string, string>>({})
  const [field, setField] = useState<Record<string, string>>({})
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [passwordConfirmHidden, setPasswordConfirmHidden] = useState(true)

  const [isFetchingInvite, setIsFetchingInvite] = useState(true)
  const [invite, setInvite] = useState<Invite | null>(null)

  useEffect(() => {
    let isMounted = true

    if (invite || !inviteCode || isCreatingInvite) {
      return
    }

    // We need to use promises here VS async/await to properly detect if the component is mounted
    fetch(`${ENV_GAE_REST_ENDPOINT}/store-invite?code=${inviteCode}`).then(
      (raw) => {
        raw.json().then((json) => {
          if (isMounted) {
            setIsFetchingInvite(false)
            setInvite(json)
            const email = json?.data?.email
            if (email) {
              setField({ ...field, email })
            }
          }
        })
      },
    )

    return () => {
      isMounted = false
    }
  }, [isAuthenticated, invite, inviteCode, isCreatingInvite, field])

  const handleSignUp = async () => {
    const { fullname, email, password } = field
    if (!isValid(field, error)) {
      return
    }
    // Create Firebase Auth user + userConfig
    const success = await signUpFromInvite({
      displayName: fullname,
      email,
      password,
      inviteCode,
    })
    if (success) {
      const vendorSlug = invite?.data?.invitedByVendorSlug
      const targetPath = vendorSlug
        ? generatePath(VENDOR_HOME, {
            vendor: vendorSlug,
            activeTab: 'order-guide',
          })
        : HOME
      history.push(targetPath)
      // Hack to force refresh just in case
      window.location.reload()
    }
  }

  const onEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const val = trim(rawValue)
    setField({ ...field, email: rawValue })

    if (isEmpty(val)) {
      setError({ ...error, email: 'Email is required' })
    } else if (!isEmail(val)) {
      setError({ ...error, email: 'Email is invalid' })
    } else {
      setError({ ...error, email: '' })
    }
  }

  const onFullnameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const val = trim(rawValue)
    setField({ ...field, fullname: rawValue })
    if (isEmpty(val)) {
      setError({ ...error, fullname: 'Your name is required.' })
    } else {
      setError({ ...error, fullname: '' })
    }
  }

  const onPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setField({ ...field, password: val })
    if (isEmpty(val)) {
      setError({ ...error, password: 'Password is required.' })
    } else if (!isLength(val, { min: 6, max: undefined })) {
      setError({
        ...error,
        password: 'Password should have a minimum length of 6 characters.',
      })
    } else if (val !== field.confirmPassword) {
      setError({
        ...error,
        password: '',
        passwordConfirm: 'Passwords do not match.',
      })
    } else {
      setError({ ...error, password: '' })
    }
  }

  const onPasswordConfirmInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setField({ ...field, passwordConfirm: val })
    if (isEmpty(val) || val !== field.password) {
      setError({ ...error, passwordConfirm: 'Passwords do not match.' })
    } else {
      setError({ ...error, passwordConfirm: '' })
    }
  }

  const isFormValid = isValid(field, error)
  const alternateLink = (
    <div className={classes.alternateLink}>
      <Typography>
        Already have an account? <Link to={SIGN_IN}>Sign In</Link>
      </Typography>
    </div>
  )

  if (invite?.didSignUp) {
    // eslint-disable-next-line no-console
    console.log('User already invited; redirecting to home.')
    return <Redirect to={HOME} />
  }

  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      <Container maxWidth="md">
        <Grid className={classes.container} container spacing={4}>
          <Grid item xs={12} md={6}>
            {isFetchingInvite && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {(!invite || !invite?.valid) && !isFetchingInvite && (
              <>
                <SubtleCard>
                  <div className={classes.cardContent}>
                    <Typography variant="h6" className={classes.headline}>
                      Sorry, this invitation is invalid.
                    </Typography>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                      Having trouble?{' '}
                      <Link
                        to="#"
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                          startIntercomConversation(
                            'Please describe your issue here',
                          )
                        }}
                      >
                        Contact support
                      </Link>
                    </div>
                  </div>
                </SubtleCard>
                {alternateLink}
              </>
            )}

            {invite && invite?.valid && (
              <>
                <SubtleCard>
                  <div className={classes.cardContent}>
                    <div className={classes.headline}>
                      <Typography variant="h4">
                        Welcome, {invite?.data?.storeName}!
                      </Typography>
                      <Typography variant="h6" style={{ marginTop: '1em' }}>
                        {invite?.data?.invitedByVendorName
                          ? `${invite?.data?.invitedByVendorName} invited you to join Vori. Let's create your account:`
                          : "Let's get you set up with an account."}
                      </Typography>
                    </div>
                    <FormField
                      label="Your Email"
                      errorText={error.email}
                      placeholder="jess@localmarket.com"
                      onChange={onEmailInput}
                      value={field.email}
                    />
                    <FormField
                      autoFocus
                      label="Your Name"
                      errorText={error.fullname}
                      placeholder="Jess Smith"
                      onChange={onFullnameInput}
                      value={field.fullname}
                    />

                    <div className={classes.sectionHeadline}>Password</div>
                    <TextField
                      className={classes.passwordField}
                      error={Boolean(error.password)}
                      helperText={error.password}
                      required
                      fullWidth
                      placeholder="At least 6 characters"
                      variant="outlined"
                      onChange={onPasswordInput}
                      value={field.password}
                      type={passwordHidden ? 'password' : 'text'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              tabIndex="-1"
                              onClick={() => {
                                setPasswordHidden(!passwordHidden)
                              }}
                              edge="end"
                            >
                              {passwordHidden ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className={classes.sectionHeadline}>
                      Confirm Password
                    </div>
                    <TextField
                      className={classes.passwordField}
                      error={Boolean(error.passwordConfirm)}
                      helperText={error.passwordConfirm}
                      required
                      fullWidth
                      variant="outlined"
                      onChange={onPasswordConfirmInput}
                      value={field.passwordConfirm}
                      type={passwordConfirmHidden ? 'password' : 'text'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              tabIndex="-1"
                              onClick={() => {
                                setPasswordConfirmHidden(!passwordConfirmHidden)
                              }}
                              edge="end"
                            >
                              {passwordConfirmHidden ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {errorMessage && (
                      <FormSection>
                        <div className={classes.error}>
                          {errorMessage as string}
                        </div>
                      </FormSection>
                    )}
                    <FormSection>
                      <Button
                        className={classes.nextButton}
                        disabled={
                          isCreatingInvite || isAuthenticating || !isFormValid
                        }
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleSignUp}
                      >
                        {isCreatingInvite
                          ? 'Creating account...'
                          : 'Create your Account'}
                      </Button>
                    </FormSection>
                  </div>
                </SubtleCard>
                {alternateLink}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </BodyBackground>
  )
}

export default StoreInvitationSignup
