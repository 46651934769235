import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { ReactNode } from 'react'

import Navigation, { SparseNavigation } from './Navigation'
import Footer from './Footer'

import { useLayout } from '../../helpers/hooks'

const PageMargins = {
  ContentDefault: 80,
  ContentSparse: 60,
  ContentMobile: 50,
}

const useStyles = makeStyles((theme) => ({
  page: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    paddingBottom: theme.spacing(4),
  },
  contentSparse: {
    display: 'flex',
  },
}))

type Props = {
  sparseLayout?: boolean
  HeaderComponent?: React.ElementType
  children?: ReactNode
}

const MainLayout = (props: Props): JSX.Element => {
  const { children, HeaderComponent, sparseLayout } = props
  const classes = useStyles(props)
  const isMobile = useLayout()

  const marginTop = isMobile
    ? PageMargins.ContentMobile
    : sparseLayout
      ? PageMargins.ContentSparse
      : PageMargins.ContentDefault

  return (
    <div className={classes.page}>
      {!sparseLayout && (
        <Navigation isMobile={isMobile} HeaderComponent={HeaderComponent} />
      )}
      {sparseLayout && <SparseNavigation isMobile={isMobile} />}
      <div
        className={clsx(classes.content, {
          [classes.contentSparse]: sparseLayout,
        })}
        style={{ marginTop }}
      >
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default MainLayout
