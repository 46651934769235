import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const Bestsellers = (): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          Bestsellers
        </Grid>
      </Grid>
    </Container>
  )
}

export default Bestsellers
