import React, { ReactNode, useEffect, useRef, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

import SubtleCard from './SubtleCard'
import { SearchSuggestions } from '../../config/ProductConfig'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  clearButton: {
    cursor: 'pointer',
  },
  searchBox: {
    flex: 1,
    '& .MuiOutlinedInput-notchedOutline': {
      boxShadow: '6px 6px 54px hsla(0, 0%, 0%, 0.05)',
      borderRadius: '60px',
      borderColor: theme.colors.GRAY_3,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.VORI_PURPLE,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.VORI_PURPLE,
      boxShadow: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: 12,
      color: theme.colors.GRAY_3,
    },
  },
  searchBoxDesktop: {
    minWidth: 220,
    maxWidth: 720,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 5,
    },
  },
  searchBoxMobile: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
  searchIcon: {
    background: theme.colors.VORI_PURPLE,
    color: theme.colors.WHITE,
    borderRadius: 20,
    padding: 4,
    marginLeft: 6,
    fontSize: 24,
    boxShadow: `0 0 6px ${theme.colors.VORI_PURPLE_HSLA(0.3)}`,
  },
  autoComplete: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  popper: {
    marginTop: -4,
  },
  card: {
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    // Move shadow downward to prevent showing transition point
    boxShadow: `0 15px 15px ${theme.colors.SHADOW}`,
  },
}))

const SearchPopper = (props: { children?: ReactNode }): JSX.Element => {
  const classes = useStyles(props)
  const { children, ...rest } = props
  return (
    <Popper {...rest} open={true} keepMounted={true}>
      <div className={classes.popper}>{children}</div>
    </Popper>
  )
}

const SearchCard = (props: {
  className?: string
  children?: ReactNode
}): JSX.Element => {
  const classes = useStyles(props)
  const { children, className } = props
  return (
    <SubtleCard className={clsx(className, classes.card)}>
      {children}
    </SubtleCard>
  )
}

type Props = {
  mobile?: boolean
  vendorSearchURL: string
}

const SearchBox = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { mobile, vendorSearchURL } = props
  const location = useLocation()
  const history = useHistory()
  const { search } = location
  const params = useMemo(() => new URLSearchParams(search), [search])
  const query = params.get('query')
  const textfieldElement = useRef(null)
  const [value, setValue] = useState(query || '')

  useEffect(() => {
    if (query != null && query !== value) {
      setValue(query)
    }
  }, [query, value])
  return (
    <Autocomplete
      className={classes.autoComplete}
      disableClearable
      freeSolo
      groupBy={() => 'Popular Searches'}
      limitTags={8}
      options={SearchSuggestions}
      PaperComponent={SearchCard}
      PopperComponent={SearchPopper}
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue)
        if (typeof newValue === 'string' && newValue !== '') {
          history.push(
            `${vendorSearchURL}?query=${encodeURIComponent(newValue)}`,
          )
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            autoFocus={location.pathname.match('search') != null}
            inputRef={textfieldElement}
            className={clsx(classes.searchBox, {
              [classes.searchBoxDesktop]: !mobile,
              [classes.searchBoxMobile]: mobile,
            })}
            placeholder="Search for anything"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        )
      }}
    />
  )
}

export default SearchBox
