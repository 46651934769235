import React from 'react'
import clsx from 'clsx'

import { Link, generatePath } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

import Typography from '@material-ui/core/Typography'

import { MarketVendorFieldsFragment } from '@vori/gql-market'
import SubtleCard from '../ui/SubtleCard'

import { CustomTheme, GRAY_4_BG } from '../../theme'
import { VENDOR_HOME } from '../../constants/routes'

const useStyles = makeStyles((theme: CustomTheme) => ({
  link: {
    alignItems: 'stretch',
    color: theme.colors.BLACK,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textDecoration: 'none',
    transition: 'transform 0.2s ease-out',

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },

  card: {
    borderRadius: theme.spacing(1),
    height: '100%',
  },

  coverImage: ({ vendor }: { vendor: MarketVendorFieldsFragment }) => ({
    backgroundImage: `url(${vendor.headerImageURL || GRAY_4_BG})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.colors.OFFWHITE,
    border: `1px solid ${theme.colors.GRAY_0}`,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    height: 120,
    marginBottom: -50,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 75,
      marginBottom: -30,
    },
  }),

  content: {
    padding: theme.spacing(0, 4, 4.5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 1.5),
    },
  },

  vendorAvatar: {
    backgroundColor: theme.colors.WHITE,
    border: `1px solid ${theme.colors.GRAY_1}`,
    height: 100,
    margin: theme.spacing(0, 'auto', 1),
    width: 100,

    [theme.breakpoints.down('sm')]: {
      height: 60,
      margin: theme.spacing(0, 'auto', 0.5),
      width: 60,
    },
  },

  vendorName: {
    fontWeight: 'bold',
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal',
    },
  },
}))

type Props = {
  className?: string
  vendor: MarketVendorFieldsFragment
}

function VendorCard(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { className, vendor } = props

  return (
    <Link
      data-test-id="vendor-card-link"
      className={clsx(classes.link, className)}
      to={generatePath(VENDOR_HOME, {
        vendor: vendor.slug || vendor.id,
        activeTab: 'catalog',
      })}
    >
      <SubtleCard className={classes.card}>
        <div className={classes.coverImage} />
        <div className={classes.content}>
          <Avatar
            alt={`${vendor.name} Logo`}
            className={classes.vendorAvatar}
            src={vendor.imgURL || ''}
          />
          <Typography
            align="center"
            color="textPrimary"
            className={classes.vendorName}
            variant="body1"
          >
            {vendor.name}
          </Typography>
        </div>
      </SubtleCard>
    </Link>
  )
}

export default VendorCard
