import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../ui/Button'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  errorIcon: {
    color: theme.colors.ERROR,
    fontSize: '2rem',
    marginRight: theme.spacing(1),
  },
  message: {
    textAlign: 'center',
  },
  title: {
    '& h2': { alignItem: 'center', display: 'flex', justifyContent: 'center' },
  },
}))

type Props = {
  errorMessage?: string
  isOpen: boolean
  handleOnClose: () => void
}

const CheckoutErrorModal = (props: Props): JSX.Element => {
  const { errorMessage, isOpen, handleOnClose } = props
  const classes = useStyles(props)
  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <DialogContent className={classes.message}>
        <DialogTitle className={classes.title}>
          <ErrorIcon className={classes.errorIcon} /> Sorry!
        </DialogTitle>
        <DialogContentText>
          {errorMessage ||
            'An error occured while creating order. Please try again or contact support.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckoutErrorModal
