import { isEqual } from 'lodash'
import { usePreviousValue, useStableCallback } from '@vori/react-hooks'
import React from 'react'

import { HookOptions, HookReturn, ReducerAction, ReducerState } from './types'
import { getDefaultState, reducer } from './reducer'

/**
 * Creates a headless calendar based on the given options
 *
 * @todo Add unit tests
 */
export function useCalendar(options?: HookOptions): HookReturn {
  const {
    defaultSelectedDates,
    defaultSelectedTimes,
    onDateSelectionChange,
    onMonthChange,
    onYearChange,
    ...otherOptions
  } = options || {}

  const prevOptions = usePreviousValue(otherOptions)

  const [state, dispatch] = React.useReducer<
    React.Reducer<ReducerState, ReducerAction>,
    Partial<HookOptions>
  >(
    reducer,
    {
      ...otherOptions,
      selectedDates: otherOptions.selectedDates || defaultSelectedDates || [],
      selectedTimes: otherOptions.selectedTimes || defaultSelectedTimes || [],
    },
    getDefaultState,
  )

  const prevMonth = usePreviousValue(state.month)
  const prevSelectedDates = usePreviousValue(state.selectedDates)
  const prevYear = usePreviousValue(state.year)
  const prevSelectedTimes = usePreviousValue(state.selectedTimes)

  const stableOnDateSelectionChange = useStableCallback(onDateSelectionChange)
  const stableOnMonthChange = useStableCallback(onMonthChange)
  const stableOnYearChange = useStableCallback(onYearChange)

  const deselectAll = React.useCallback<HookReturn['deselectAll']>(
    () => dispatch({ type: 'DESELECT_ALL' }),
    [],
  )

  const setNextMonth = React.useCallback<HookReturn['setNextMonth']>(
    (): void => dispatch({ type: 'SET_NEXT_MONTH' }),
    [],
  )

  const setNextYear = React.useCallback<HookReturn['setNextYear']>(
    (): void => dispatch({ type: 'SET_NEXT_YEAR' }),
    [],
  )

  const setPrevMonth = React.useCallback<HookReturn['setPrevMonth']>(
    (): void => dispatch({ type: 'SET_PREV_MONTH' }),
    [],
  )

  const setPrevYear = React.useCallback<HookReturn['setPrevYear']>(
    (): void => dispatch({ type: 'SET_PREV_YEAR' }),
    [],
  )

  const select = React.useCallback<HookReturn['select']>(
    (date): void => dispatch({ type: 'SELECT', payload: { date } }),
    [],
  )

  const deselect = React.useCallback<HookReturn['deselect']>(
    (date): void => dispatch({ type: 'DESELECT', payload: { date } }),
    [],
  )

  const toggle = React.useCallback<HookReturn['toggle']>(
    (date): void => dispatch({ type: 'TOGGLE', payload: { date } }),
    [],
  )

  const setSelected = React.useCallback<HookReturn['setSelected']>(
    (dates): void => dispatch({ type: 'SET_DATES', payload: { dates } }),
    [],
  )

  const setMonth = React.useCallback<HookReturn['setMonth']>(
    (month): void => dispatch({ type: 'SET_MONTH', payload: { month } }),
    [],
  )

  const setTime = React.useCallback<HookReturn['setTime']>(
    (times): void => dispatch({ type: 'SET_TIME', payload: { times } }),
    [],
  )

  const setYear = React.useCallback<HookReturn['setYear']>(
    (year): void => dispatch({ type: 'SET_YEAR', payload: { year } }),
    [],
  )

  React.useEffect(() => {
    if (prevOptions && !isEqual(otherOptions, prevOptions)) {
      const {
        defaultSelectedDates,
        defaultSelectedTimes,
        ...stateWithoutDefaults
      } = state

      dispatch({
        type: 'INIT',
        payload: {
          options: getDefaultState(
            { ...stateWithoutDefaults, ...otherOptions } || {},
          ),
        },
      })
    }
  }, [otherOptions, prevOptions, state])

  React.useEffect(() => {
    if (prevMonth && prevMonth !== state.month) {
      stableOnMonthChange(state.month, prevMonth)
    }
  }, [stableOnMonthChange, prevMonth, state.month])

  React.useEffect(() => {
    const prevSelectedDatesToLocaleString =
      prevSelectedDates?.map((date) => date.toLocaleDateString()) || []

    if (
      (prevSelectedDates &&
        (prevSelectedDatesToLocaleString.length !==
          state.selectedDates.length ||
          state.selectedDates.some(
            (date) =>
              !prevSelectedDatesToLocaleString.includes(
                date.toLocaleDateString(),
              ),
          ))) ||
      (prevSelectedTimes &&
        (prevSelectedTimes.length !== state.selectedTimes.length ||
          state.selectedTimes.some(
            (time) => !prevSelectedTimes.includes(time),
          )))
    ) {
      stableOnDateSelectionChange(state.selectedDates, prevSelectedDates || [])
    }
  }, [
    stableOnDateSelectionChange,
    prevSelectedDates,
    state.selectedDates,
    state.selectedTimes,
    prevSelectedTimes,
  ])

  React.useEffect(() => {
    if (prevYear && prevYear !== state.year) {
      stableOnYearChange(state.year, prevYear)
    }
  }, [stableOnYearChange, prevYear, state.year])

  return React.useMemo<HookReturn>(
    () => ({
      ...state,
      deselect,
      deselectAll,
      select,
      setMonth,
      setNextMonth,
      setNextYear,
      setPrevMonth,
      setPrevYear,
      setSelected,
      setTime,
      setYear,
      toggle,
    }),
    [
      deselect,
      deselectAll,
      select,
      setMonth,
      setNextMonth,
      setNextYear,
      setPrevMonth,
      setPrevYear,
      setSelected,
      setTime,
      setYear,
      state,
      toggle,
    ],
  )
}
