import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import isEmail from 'validator/es/lib/isEmail'
import isEmpty from 'validator/es/lib/isEmpty'
import isLength from 'validator/es/lib/isLength'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import trim from 'validator/es/lib/trim'
import MuiPhoneInput from 'material-ui-phone-number'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles'

import SubtleCard from '../../components/ui/SubtleCard'
import BodyBackground from '../../components/ui/BodyBackground'
import FormField from '../../components/ui/FormField'
import FormSection from '../../components/ui/FormSection'
import { Colors } from '../../theme'
import { SIGN_IN } from '../../constants/routes'
import OnboardingStepper from '../account/OnboardingStepper'
import { AuthContext } from '../../auth/AuthContext'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(4),
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  sectionHeadline: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  splitField: {
    flexDirection: 'row',
  },
  pwField: {
    flex: 1,
    '&+&': {
      marginLeft: theme.spacing(2),
    },
  },
  nextButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
  alternateLink: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
}))

const isValid = (
  field: Record<string, string>,
  error: Record<string, string>,
) =>
  Object.values(error).every((v) => v === '') &&
  [
    'fullname',
    'email',
    'password',
    'addressLine1',
    'mobile',
    'storeName',
  ].every((fieldName) => Boolean(field[fieldName]))

const SignUp = (): JSX.Element => {
  console.log('sign up trigger')
  const classes = useStyles()
  const { auth } = useContext(AuthContext)
  const { errorMessage, isAuthenticating, isAuthenticated } = auth
  const history = useHistory()
  const [error, setError] = useState<Record<string, string>>({})
  const [field, setField] = useState<Record<string, string>>({})
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [isSigningUp, setIsSigningUp] = useState(false)
  const [passwordConfirmHidden, setPasswordConfirmHidden] = useState(true)

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    history.push('/')
  }, [history, isAuthenticated])

  const handleSignUp = async () => {
    // const { fullname, email, password, addressLine1, mobile, storeName } = field
    if (!isValid(field, error)) {
      return
    }
    setIsSigningUp(true)
    // Todo need a gql mutation to handle self serve signups
  }

  const onEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const val = trim(rawValue)
    setField({ ...field, email: rawValue })

    if (isEmpty(val)) {
      setError({ ...error, email: 'Email is required' })
    } else if (!isEmail(val)) {
      setError({ ...error, email: 'Email is invalid' })
    } else {
      setError({ ...error, email: '' })
    }
  }

  const onFullnameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const val = trim(rawValue)
    setField({ ...field, fullname: rawValue })
    if (isEmpty(val)) {
      setError({ ...error, fullname: 'Your name is required.' })
    } else {
      setError({ ...error, fullname: '' })
    }
  }

  const onStoreNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const val = trim(rawValue)
    setField({ ...field, storeName: rawValue })
    if (isEmpty(val)) {
      setError({ ...error, storeName: 'Store name is required.' })
    } else {
      setError({ ...error, storeName: '' })
    }
  }

  const onPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setField({ ...field, password: val })
    if (isEmpty(val)) {
      setError({ ...error, password: 'Password is required.' })
    } else if (!isLength(val, { min: 8, max: undefined })) {
      setError({
        ...error,
        password: 'Password should have a minimum length of 8 characters.',
      })
    } else if (val !== field.confirmPassword) {
      setError({
        ...error,
        password: '',
        passwordConfirm: 'Passwords do not match.',
      })
    } else {
      setError({ ...error, password: '' })
    }
  }

  const onPasswordConfirmInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setField({ ...field, passwordConfirm: val })
    if (isEmpty(val) || val !== field.password) {
      setError({ ...error, passwordConfirm: 'Passwords do not match.' })
    } else {
      setError({ ...error, passwordConfirm: '' })
    }
  }

  const onAddressLine1Input = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const val = trim(rawValue)
    setField({ ...field, addressLine1: rawValue })
    if (isEmpty(val)) {
      setError({ ...error, addressLine1: 'Address is required.' })
    } else {
      setError({ ...error, addressLine1: '' })
    }
  }

  const onMobileChange = (val: string) => {
    setField({ ...field, mobile: val })
    if (isEmpty(val)) {
      setError({ ...error, mobile: 'Phone number required.' })
    } else if (!isMobilePhone(val, 'en-US')) {
      setError({ ...error, mobile: 'Phone number invalid.' })
    } else {
      setError({ ...error, mobile: '' })
    }
  }

  const isFormValid = isValid(field, error)
  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <OnboardingStepper activeStep={0} />
          </Grid>
          <Grid item xs={6}>
            <SubtleCard>
              <div className={classes.cardContent}>
                <Typography variant="h5" className={classes.headline}>
                  Welcome to Vori Market!
                </Typography>
                <FormField
                  autoFocus
                  label="Your Name"
                  errorText={error.fullname}
                  placeholder="Jess Smith"
                  onChange={onFullnameInput}
                  value={field.fullname}
                />
                <FormField
                  label="Your Storeʼs Name"
                  errorText={error.storeName}
                  placeholder="Local Market"
                  onChange={onStoreNameInput}
                  value={field.storeName}
                />
                <FormField
                  label="Your Email"
                  errorText={error.email}
                  placeholder="jess@localmarket.com"
                  onChange={onEmailInput}
                  value={field.email}
                />
                <FormField
                  label="Store Address"
                  errorText={error.addressLine1}
                  placeholder="123 Main Street"
                  onChange={onAddressLine1Input}
                  value={field.addressLine1}
                />
                <FormField
                  label="Phone Number"
                  errorText={error.mobile}
                  placeholder="650-555-1234"
                  onChange={(value) =>
                    onMobileChange(value as unknown as string)
                  }
                  value={field.mobile}
                  fieldComponent={MuiPhoneInput}
                  defaultCountry="us"
                  disableDropdown
                  onlyCountries={['us']}
                />
                <FormSection className={classes.splitField}>
                  <div className={classes.pwField}>
                    <div className={classes.sectionHeadline}>Password</div>
                    <TextField
                      error={Boolean(error.password)}
                      helperText={error.password}
                      required
                      fullWidth
                      placeholder="At least 8 characters"
                      variant="outlined"
                      onChange={onPasswordInput}
                      value={field.password}
                      type={passwordHidden ? 'password' : 'text'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              tabIndex="-1"
                              onClick={() => {
                                setPasswordHidden(!passwordHidden)
                              }}
                              edge="end"
                            >
                              {passwordHidden ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.pwField}>
                    <div className={classes.sectionHeadline}>
                      Confirm Password
                    </div>
                    <TextField
                      error={Boolean(error.passwordConfirm)}
                      helperText={error.passwordConfirm}
                      required
                      fullWidth
                      variant="outlined"
                      onChange={onPasswordConfirmInput}
                      value={field.passwordConfirm}
                      type={passwordConfirmHidden ? 'password' : 'text'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              tabIndex="-1"
                              onClick={() => {
                                setPasswordConfirmHidden(!passwordConfirmHidden)
                              }}
                              edge="end"
                            >
                              {passwordConfirmHidden ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </FormSection>
                {errorMessage && <FormSection>{errorMessage}</FormSection>}
                <FormSection>
                  <Button
                    className={classes.nextButton}
                    disabled={isSigningUp || isAuthenticating || !isFormValid}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleSignUp}
                  >
                    {isSigningUp ? 'Signing up...' : 'Next'}
                  </Button>
                </FormSection>
              </div>
            </SubtleCard>
            <div className={classes.alternateLink}>
              <Typography>
                Already have an account? <Link to={SIGN_IN}>Sign In</Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </BodyBackground>
  )
}

export default SignUp
