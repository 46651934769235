import React from 'react'
import clsx from 'clsx'

import { Modal as MuiModal } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import { CustomTheme } from '../../theme'

export const styles = makeStyles((theme: CustomTheme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 20px',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    padding: theme.spacing(4),
    width: 1040,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      height: '100%',
      padding: 24,
      overflow: 'auto',
    },
  },
  closeBtn: {
    borderRadius: 2,
    float: 'right',
    backgroundColor: theme.colors.GRAY_0,
    '&.MuiButton-root': {
      padding: 6,
      minWidth: 24,
    },
    [theme.breakpoints.down('xs')]: {
      '&.MuiButton-root': {
        padding: 2,
      },
    },
  },
  headContainer: {
    width: '100%',
  },
}))

type Props = {
  description: string
  open: boolean
  toggleModal: () => void
  importedClasses?: string
  body?: JSX.Element
  title?: string
  titleElement?: JSX.Element
}

function Modal({
  body,
  title,
  titleElement,
  description,
  open,
  toggleModal,
  importedClasses,
}: Props): JSX.Element {
  const classes = styles()

  return (
    <MuiModal
      aria-labelledby={title}
      aria-describedby={description}
      className={clsx(classes.modal, importedClasses)}
      open={open}
      onClose={toggleModal}
      onClick={(e) => {
        e.stopPropagation()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <div className={classes.headContainer}>
              <Button
                variant="contained"
                className={classes.closeBtn}
                onClick={toggleModal}
              >
                <CloseIcon />
              </Button>
            </div>
            <div className={classes.headContainer}>{titleElement}</div>
            {body}
          </Grid>
        </div>
      </Fade>
    </MuiModal>
  )
}

export default Modal
