// Cookies
export const IOS_APP_NOTIFY = 'ios-app-notify'

// LocalStorage
export const REDIRECT = 'redirect'

export const DEMO_TRIGGERED = 'DEMO_TRIGGERED'

// Native Mobile App
export const CUSTOM_IOS_URL = 'https://g170h.app.link/y9gkJqNd3cb'
// export const CUSTOM_IOS_URL = 'vori-marketplace://vori.market.com/';
// export const IOS_APP_STORE_LINK =
//   'https://apps.apple.com/us/app/vori/id1487512594';
