import React, { useRef } from 'react'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { Flex, SpacerNext as Spacer } from '@vori/gourmet-components'

import {
  BuyXGetYFreeDeal,
  Deal,
  FixedDeal,
  MarketVendorProductWithActiveDealFieldsFragment,
  PercentageDeal,
} from '@vori/gql-market'

import clsx from 'clsx'
import { useActiveDeals, useLayout } from '../../helpers/hooks'
import { gqlVendorProductToAlgolia } from '../../helpers/utils'
import { CustomTheme } from '../../theme'
import ViewLoader from '../ui/ViewLoader'
import ProductCard from './ProductCard'
import WithActiveDeal from './WithActiveDeal'
import WithBasketQuantity from './WithBasketQuantity'

const EnhancedProductCard = WithActiveDeal(WithBasketQuantity(ProductCard))

const useStyles = makeStyles((theme: CustomTheme) => ({
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${theme.spacing(8)}px auto`,
  },
  emptyHeadline: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    fontWeight: 'bold',
    color: theme.colors.GRAY_5,
  },
  button: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(2),
    backgroundColor: theme.colors.VORI_PURPLE_HSLA(0.1),
    color: theme.colors.VORI_PURPLE_HSLA(0.8),

    '&:hover': {
      backgroundColor: theme.colors.VORI_PURPLE_HSLA(0.3),
      color: theme.colors.VORI_PURPLE,
    },
  },
  spacer: {
    flex: 1,
    height: 2,
    borderRadius: 2,
  },
  spacerLeft: {
    background: `linear-gradient(-90deg, ${theme.colors.VORI_PURPLE_HSLA(
      0.12,
    )}, ${theme.colors.VORI_PURPLE_HSLA(0.05)})`,
  },
  spacerRight: {
    background: `linear-gradient(90deg, ${theme.colors.VORI_PURPLE_HSLA(
      0.12,
    )}, ${theme.colors.VORI_PURPLE_HSLA(0.05)})`,
  },
}))

type Props = {
  vendorID: string
  products: MarketVendorProductWithActiveDealFieldsFragment[]
  hasMore: boolean
  loading: boolean
  isFetchingMore: boolean
  loadMoreItems: () => Promise<unknown> | null
}

const VendorProductList = ({
  hasMore,
  vendorID,
  products,
  loading,
  isFetchingMore,
  loadMoreItems,
}: Props): JSX.Element => {
  const firstLoadRef = useRef(true)
  const classes = useStyles()
  const isMobile = useLayout()

  const { data: activeDeals, loading: isLoadingDeals } = useActiveDeals(
    products?.map((product) => product.id),
    vendorID,
  )

  if ((loading || isLoadingDeals) && firstLoadRef.current) {
    firstLoadRef.current = false

    return <ViewLoader />
  }
  const noResults = products?.length === 0

  return (
    <>
      <Grid container spacing={isMobile ? 1 : 4}>
        {noResults && (
          <Flex center flex={1}>
            <Typography className={classes.emptyHeadline} variant="h5">
              No products found
            </Typography>
          </Flex>
        )}

        {!noResults &&
          products?.map((product, i) => (
            <Grid
              key={`grid-item-${product.id || i}`}
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <EnhancedProductCard
                activeDeal={
                  (activeDeals as Map<string, Deal>).get(
                    product.id,
                  ) as FixedDeal & BuyXGetYFreeDeal & PercentageDeal
                }
                key={product.id || i}
                productData={gqlVendorProductToAlgolia(product)}
                showInStock
              />
            </Grid>
          ))}
      </Grid>

      {hasMore && (
        <div className={classes.showMoreContainer}>
          <div className={clsx(classes.spacer, classes.spacerLeft)} />
          <Button
            className={classes.button}
            onClick={loadMoreItems}
            size="small"
            variant="contained"
          >
            {isFetchingMore ? (
              <>
                <CircularProgress size="0.5rem" />
                <Spacer size="space.050" />
                Loading more...
              </>
            ) : (
              'Show more'
            )}
          </Button>
          <div className={clsx(classes.spacer, classes.spacerRight)} />
        </div>
      )}
    </>
  )
}

export default VendorProductList
