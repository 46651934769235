import React, { ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import { throttle } from 'lodash'

import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core'

import { ArrowLeftIcon, ArrowRightIcon } from '@vori/gourmet-icons'
import { CustomTheme } from '../../theme'

const GRADIENT_WIDTH = 100
const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    position: 'relative',
  },
  scroller: {
    display: 'flex',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    // vertical padding should be larger than `SubtleCard` box-shadow outer bounds
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: GRADIENT_WIDTH,
    paddingRight: GRADIENT_WIDTH,
    marginLeft: -GRADIENT_WIDTH,
    marginRight: -GRADIENT_WIDTH,

    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },

  item: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  navOverlay: {
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  navOverlayLeft: {
    background: 'linear-gradient(90deg, #fff, #ffffff00)',
    left: -GRADIENT_WIDTH,
    width: GRADIENT_WIDTH,
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      left: 0,
      width: 0,
    },
  },
  navOverlayRight: {
    background: 'linear-gradient(90deg, #ffffff00, #fff)',
    right: -GRADIENT_WIDTH,
    width: GRADIENT_WIDTH,
    justifyContent: 'flex-start',
    [theme.breakpoints.only('xs')]: {
      right: 0,
      width: 0,
    },
  },
  scrollButton: {
    boxShadow: `0 0 50px hsla(0,0%,28%,0.15)`,
    pointerEvents: 'all',
    backgroundColor: theme.colors.WHITE,
    '&:hover': {
      backgroundColor: theme.colors.VORI_PURPLE_LIGHT,
    },
    transition: 'opacity 0.2s ease-out',
  },
  scrollButtonHidden: {
    opacity: 0,
  },
  scrollButtonLeft: {
    [theme.breakpoints.down('md')]: {
      marginRight: -59, // Offset button width, for smaller viewports
    },
  },
  scrollButtonRight: {
    [theme.breakpoints.down('md')]: {
      marginLeft: -59, // Offset button width, for smaller viewports
    },
  },
  arrowIconLeft: {
    transform: 'rotate(180deg)',
  },
}))

type Props = {
  itemClassName?: string
  items: ReactNode[]
}

export default function HorizontalScrollList(props: Props): JSX.Element {
  const classes = useStyles(props)
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)
  const { items, itemClassName } = props
  const [scrollerEl, setScrollerEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollerEl) {
        const domElement = scrollerEl
        const { clientWidth, scrollLeft, scrollWidth } = domElement

        setShowLeft(scrollLeft > 0)
        setShowRight(scrollLeft < scrollWidth - clientWidth)
      }
    }

    const throttledHandleScroll = throttle(handleScroll, 100)

    if (scrollerEl) {
      scrollerEl.addEventListener('scroll', throttledHandleScroll)
      // Calculate button visibility after initial render
      handleScroll()
    }

    return () => {
      throttledHandleScroll.cancel()

      if (scrollerEl) {
        scrollerEl.removeEventListener('scroll', throttledHandleScroll)
      }
    }
  }, [scrollerEl])

  const handleScrollRight = () => {
    if (scrollerEl) {
      const domElement = scrollerEl
      const { clientWidth, scrollWidth, scrollLeft } = domElement
      const delta = clientWidth - GRADIENT_WIDTH * 2
      domElement.scrollLeft = Math.min(
        scrollWidth - clientWidth,
        scrollLeft + delta,
      )
    }
  }

  const handleScrollLeft = () => {
    if (scrollerEl) {
      const domElement = scrollerEl
      const { clientWidth, scrollLeft } = domElement
      const delta = clientWidth - GRADIENT_WIDTH * 2
      domElement.scrollLeft = Math.max(0, scrollLeft - delta)
    }
  }
  return (
    <div className={classes.container}>
      <div className={classes.scroller} ref={setScrollerEl}>
        {/* {items} */}
        {items.map((item, i) => (
          <div className={clsx(classes.item, itemClassName)} key={i}>
            {item}
          </div>
        ))}
      </div>
      <div className={clsx(classes.navOverlay, classes.navOverlayLeft)}>
        <IconButton
          className={clsx(classes.scrollButton, classes.scrollButtonLeft, {
            [classes.scrollButtonHidden]: !showLeft,
          })}
          onClick={handleScrollLeft}
        >
          <ArrowLeftIcon variant="primary" />
        </IconButton>
      </div>
      <div className={clsx(classes.navOverlay, classes.navOverlayRight)}>
        <IconButton
          className={clsx(classes.scrollButton, classes.scrollButtonRight, {
            [classes.scrollButtonHidden]: !showRight,
          })}
          onClick={handleScrollRight}
        >
          <ArrowRightIcon variant="primary" />
        </IconButton>
      </div>
    </div>
  )
}
