import React, { useState, useContext } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import SubtleCard from '../../components/ui/SubtleCard'
import BodyBackground from '../../components/ui/BodyBackground'
import FormField from '../../components/ui/FormField'
import FormSection from '../../components/ui/FormSection'
import { AuthContext } from '../../auth/AuthContext'
import { Colors } from '../../theme'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(4),
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  progress: {
    marginRight: theme.spacing(1),
  },
  submitButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
}))

const ForgotPassword = (): JSX.Element => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const { sendPasswordReset } = useContext(AuthContext)
  const [isResetting, setIsResetting] = useState(false)
  const [didReset, setDidReset] = useState(false)
  const [remoteError, setRemoteError] = useState<string>('')
  const [emailError, setEmailError] = useState('')

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setEmail(val)
    if (val === '') {
      setEmailError('Email is required')
    } else if (!isEmail(val)) {
      setEmailError('Email is invalid')
    } else {
      setEmailError('')
    }
    setRemoteError('')
    setDidReset(false)
  }

  const isValid = email && !emailError
  const onSubmitEmail = async () => {
    if (!isValid) {
      return
    }
    try {
      setIsResetting(true)
      await sendPasswordReset(email)
      setDidReset(true)
      setEmail('')
    } catch (e: unknown) {
      setRemoteError(e as string)
    }
    setIsResetting(false)
  }

  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <SubtleCard>
              <div className={classes.cardContent}>
                <Typography variant="h5" className={classes.headline}>
                  Forgot Your Password?
                </Typography>
                <FormSection>
                  Enter your email address and we will send you a password reset
                  email:
                </FormSection>
                <FormField
                  autoFocus
                  errorText={emailError}
                  placeholder="jess@localmarket.com"
                  onChange={onEmailChange}
                  value={email}
                />
                {remoteError && (
                  <FormSection>
                    <Typography color="error">{remoteError}</Typography>
                  </FormSection>
                )}
                <FormSection>
                  <Button
                    className={classes.submitButton}
                    disabled={didReset || isResetting || !isValid}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={onSubmitEmail}
                  >
                    {isResetting && (
                      <CircularProgress
                        className={classes.progress}
                        size={20}
                      />
                    )}
                    {didReset
                      ? 'Success! Check your email.'
                      : isResetting
                        ? 'Sending Password Reset Email'
                        : 'Send Password Reset Email'}
                  </Button>
                </FormSection>
              </div>
            </SubtleCard>
          </Grid>
        </Grid>
      </Container>
    </BodyBackground>
  )
}

export default ForgotPassword
