import React, { useEffect, useMemo } from 'react'
import { useRouteMatch, Redirect, Switch } from 'react-router-dom'

import {
  MarketGetCartsQuery,
  MarketUpdateVendorCartMutationVariables,
  useMarketGetCartsQuery,
  useMarketUpdateVendorCartMutation,
} from '@vori/gql-market'

import * as ROUTES from '../constants/routes'

import SignUp from '../views/auth/SignUp'
import StoreInvitationSignup from '../views/auth/StoreInvitationSignup'
import SignIn from '../views/auth/SignIn'
import ForgotPassword from '../views/auth/ForgotPassword'
import UserAccount from '../views/account/UserAccount'
import EditAccount from '../views/account/EditAccount'
import OnboardingPaymentInfo from '../views/account/OnboardingPaymentInfo'
import Vendor from '../views/Vendor'
import Checkout from '../views/checkout/Checkout'
import PageNotFound from '../views/404/PageNotFound'
import VendorNotFound from '../views/404/VendorNotFound'
import Home from '../views/Home'
import MyVendors from '../views/MyVendors'
import MyPromos from '../views/MyPromos'
import MyBestsellers from '../views/MyBestsellers'
import NewItems from '../views/NewItems'

import MainLayout from '../components/ui/MainLayout'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Styleguide from '../components/ui/Styleguide'

import { FeatureFlags } from '../config/AppConfig'
import { useAuth } from '../auth/AuthContext'
import { useIsMounted } from '../helpers/hooks'

declare global {
  interface Window {
    __synced_baskets: boolean
  }
}

type LineItem = NonNullable<
  MarketGetCartsQuery['me']
>['user']['carts'][number]['lineItems']['nodes'][number]

type VendorBasket = LineItem & {
  objectID: string
}

const AppRouter = (): JSX.Element => {
  const authContext = useAuth()
  const { isAuthenticated, isCreatingInvite } = authContext.auth
  const [updateVendorCart] = useMarketUpdateVendorCartMutation()
  const isMounted = useIsMounted()

  const { data: cartQueryData, loading: loadingCart } = useMarketGetCartsQuery({
    skip: !isAuthenticated || isCreatingInvite || !isMounted,
  })

  const basket = useMemo(() => {
    return (
      JSON.parse(localStorage.getItem('persist:root') || '{}')?.basket || {}
    )
  }, [])

  useEffect(() => {
    if (!isMounted) {
      return
    }

    // Migrate old baskets stored in redux localStorage to GraphQL
    if (loadingCart || Object.keys(basket).length === 0) {
      return
    }

    // only attempt this once per session
    if (window.__synced_baskets) {
      return
    }

    window.__synced_baskets = true

    // eslint-disable-next-line no-console
    console.log('found deprecated basket', basket)
    for (const [vendorID, vendorBasket] of Object.entries(basket)) {
      const wouldConflict = cartQueryData?.me?.user?.carts?.find(
        (cart) => cart?.vendor.id === vendorID,
      )

      if (wouldConflict) {
        // eslint-disable-next-line no-console
        console.log(
          `basket migration for ${vendorID} would conflict with cart; aborting`,
        )
        // eslint-disable-next-line no-continue
        continue
      }

      const lineItems = Object.values(
        vendorBasket as { value: VendorBasket },
      ).map((basketItem: VendorBasket) => ({
        vendorProductID: basketItem.objectID,
        quantity: basketItem.quantity,
        unitOfMeasure: 'CASE',
      })) as MarketUpdateVendorCartMutationVariables['lineItems']

      updateVendorCart({
        variables: { vendorID, lineItems },
      })
    }

    // Clear deprecated baskets from localstorage
    localStorage.removeItem('persist:root')
  }, [basket, cartQueryData, isMounted, loadingCart, updateVendorCart])

  const sparseLayout =
    useRouteMatch([
      ROUTES.SIGN_UP,
      ROUTES.SIGN_IN,
      ROUTES.STORE_INVITATION,
      ROUTES.FORGOT_PASSWORD,
      ROUTES.ONBOARDING_PAYMENT_INFO,
    ]) !== null

  return (
    <MainLayout sparseLayout={sparseLayout}>
      <Switch>
        <PrivateRoute
          component={VendorNotFound}
          exact
          path={ROUTES.VENDOR_NOT_FOUND}
        />
        <PrivateRoute component={Vendor} path={ROUTES.VENDOR_HOME} />
        {FeatureFlags.selfServeSignup && (
          <PublicRoute component={SignUp} path={ROUTES.SIGN_UP} />
        )}
        <PublicRoute
          component={StoreInvitationSignup}
          path={ROUTES.STORE_INVITATION}
        />
        <PublicRoute component={SignIn} path={ROUTES.SIGN_IN} />
        <PublicRoute component={ForgotPassword} path={ROUTES.FORGOT_PASSWORD} />
        {FeatureFlags.plaidAccountPayments ? (
          <PrivateRoute
            component={OnboardingPaymentInfo}
            exact
            path={ROUTES.ONBOARDING_PAYMENT_INFO}
          />
        ) : (
          <PrivateRoute exact path={ROUTES.ONBOARDING_PAYMENT_INFO}>
            <Redirect to={ROUTES.HOME} />
          </PrivateRoute>
        )}
        <PrivateRoute component={UserAccount} exact path={ROUTES.ACCOUNT} />
        <PrivateRoute
          component={EditAccount}
          exact
          path={ROUTES.ACCOUNT_EDIT}
        />
        <PrivateRoute component={Checkout} exact path={ROUTES.CHECKOUT} />
        <PrivateRoute component={Home} exact path={ROUTES.HOME} />
        <PrivateRoute component={MyVendors} exact path={ROUTES.MY_VENDORS} />
        <PrivateRoute component={MyPromos} exact path={ROUTES.MY_PROMOS} />
        <PrivateRoute
          component={MyBestsellers}
          exact
          path={ROUTES.MY_BESTSELLERS}
        />
        <PrivateRoute component={NewItems} exact path={ROUTES.NEW_ITEMS} />
        {process.env.NODE_ENV !== 'production' && (
          <PublicRoute component={Styleguide} exact path={ROUTES.STYLEGUIDE} />
        )}
        <PublicRoute component={PageNotFound} />
      </Switch>
    </MainLayout>
  )
}

export default AppRouter
