import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  card: {
    backgroundColor: theme.colors.WHITE,
    borderRadius: 20,
    boxShadow: `0 2px 15px ${theme.colors.SHADOW}`,
  },
  withPadding: {
    padding: theme.spacing(4),
  },
}))

type Props = {
  className?: string
  children?: ReactNode
  withPadding?: boolean
}

const SubtleCard = (
  props: Props & Partial<React.HTMLAttributes<HTMLDivElement>>,
): JSX.Element => {
  const classes = useStyles(props)
  const { className, children, withPadding, ...rest } = props
  return (
    <div
      className={clsx(className, classes.card, {
        [classes.withPadding]: withPadding,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

export default SubtleCard
