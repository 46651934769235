import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import {
  MarketAddProductsToUserOrderGuideMutation,
  MarketRemoveProductsFromOrderGuideMutation,
  useMarketAddProductsToUserOrderGuideMutation,
  useMarketRemoveProductsFromOrderGuideMutation,
  User,
} from '@vori/gql-market'

import {
  useCurrentUser,
  useProductOrderHistory,
  useVendorStoreDepartmentAccountID,
  useOrderGuide,
} from '../../graphql/graphqlHooks'

import Modal from '../shared/Modal'
import QuantityControls from './QuantityControls'
import Price from './Price'
import ProductTags from './ProductTags'
import SizeDetails from './SizeDetails'
import BuyAgainCard from './BuyAgainCard'

import { useIsMounted } from '../../helpers/hooks'
import { CustomTheme } from '../../theme'
import { BasketProps } from './helpers/types'
import { GqlVendorProductToAlgolia } from '../../helpers/utils'

import { Star01Icon } from '@vori/gourmet-icons'

export const styles = makeStyles((theme: CustomTheme) => ({
  modal: {
    marginBottom: 64,
  },
  modalProductMetaTitle: {
    color: theme.colors.GRAY_2,
    marginBottom: 16,
  },
  modalProductMetaData: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  modalHeader: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 1,
    marginTop: -26,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginTop: -20,
      marginBottom: 30,
    },
  },
  modalProductDetail: {
    [theme.breakpoints.up('md')]: {
      minWidth: 736,
      maxWidth: 736,
      marginTop: 32,
    },
  },
  modalProductImg: {
    width: '100%',
    margin: '0 1.875rem' /* 0 30px */,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    maxHeight: 300,
    [theme.breakpoints.down('xs')]: {
      height: 154,
    },
  },
  modalPrice: {
    '& div': {
      fontSize: '1.5rem',
    },
    marginBottom: '0.8125rem',
    [theme.breakpoints.down('xs')]: {
      '& div': {
        fontSize: 14,
      },
      marginBottom: 0,
    },
  },
  modalDescription: {
    fontSize: '1.5rem',
    margin: '0.375rem 0 0.6875rem 0',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      fontWeight: 700,
    },
  },
  modalBestSeller: {
    backgroundColor: theme.colors.BADGE_YELLOW_BACKGROUND,
    color: theme.colors.BADGE_YELLOW_TEXT,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 15,
    '& .MuiSvgIcon-root path': {
      fill: theme.colors.BADGE_YELLOW_TEXT,
    },
    '& svg.MuiSvgIcon-root': {
      width: 15,
      height: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      '& svg.MuiSvgIcon-root': {
        width: 12,
        fontSize: 12,
      },
    },
  },
  modalAddToOrderGuideBtn: {
    borderRadius: 4,
    minHeight: 40,
    height: 50,
    width: '100%',
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginTop: 15,
    fontSize: 12,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  modalRemoveFromOrderGuideBtn: {
    color: theme.colors.BADGE_RED_TEXT,
    borderColor: theme.colors.BADGE_RED_TEXT,
    '&:hover': {
      backgroundColor: theme.colors.BADGE_RED_BACKGROUND,
    },
  },
  modalIcon: {
    marginRight: 15,
  },
  modalDeleteIcon: {
    color: theme.colors.BADGE_RED_TEXT,
  },
  modalProductMeta: {
    marginTop: 16,
  },
  sectionContainer: {
    width: '100%',
    '& h3': {
      fontSize: 24,
      marginBottom: 16,
    },
    [theme.breakpoints.down('xs')]: {
      '& h3': {
        fontSize: 18,
      },
    },
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.GRAY_0,
    marginBottom: 16,
  },
  modalUnitDetailContainer: {
    color: theme.colors.GRAY_6,
  },
  stickyQuanityControls: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    padding: 12,
    zIndex: 100,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 12px',
    backgroundColor: theme.colors.WHITE,
  },
  orderHistoryLoader: {
    margin: theme.spacing(6, 'auto'),
  },
}))

type Props = {
  isModalOpen: boolean
  productData: Partial<GqlVendorProductToAlgolia>
  basketProps: BasketProps
  toggleAlert: (event: null, message: string) => void
  toggleModal: () => void
}

const ProductDetailModal = ({
  isModalOpen,
  productData,
  toggleAlert,
  toggleModal,
  basketProps,
}: Props): JSX.Element => {
  const {
    unitUPC,
    notes,
    category,
    brandName,
    caseSize,
    description,
    vendorID,
    imgURL,
    itemCode,
    project19Featured: isBestseller,
    unitVolume,
    objectID,
  } = productData

  const classes = styles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isLargeMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isProductInOrderGuide, setIsProductInOrderGuide] =
    useState<boolean>(false)
  const isMounted = useIsMounted()
  const { data } = useCurrentUser()
  const { vsdaID } = useVendorStoreDepartmentAccountID(vendorID || '')

  const { data: orderGuideData, loading: loadingOrderGuide } = useOrderGuide(
    vendorID || '',
    {
      fetchPolicy: 'cache-and-network', // we need to check for the lastest
    },
  )

  const isDemoMode = (
    data as { me: { user: User } }
  )?.me?.user?.canSeeFeatures?.enabledFeatures.includes(
    'market.sunridgefarmsdemo',
  )

  const { data: productOrderHistory, loading: isLoadingProductOrderHistory } =
    useProductOrderHistory(objectID as string, {
      fetchPolicy: 'cache-and-network',
      skip: !isMounted || !isModalOpen,
    })

  const [addProductsToUserOrderGuide] =
    useMarketAddProductsToUserOrderGuideMutation()

  const [removeProductsFromOrderGuide] =
    useMarketRemoveProductsFromOrderGuideMutation()

  const orderGuideArray = useMemo(
    () =>
      orderGuideData?.getVendorStoreDepartmentAccountForVendor?.orderGuide
        ?.vendorProducts?.nodes || [],
    [
      orderGuideData?.getVendorStoreDepartmentAccountForVendor?.orderGuide
        ?.vendorProducts?.nodes,
    ],
  )

  const findProductInNode = useCallback(
    (
      nodes: NonNullable<
        MarketAddProductsToUserOrderGuideMutation['addProductsToV2OrderGuide']['orderGuide']['vendorProducts']
      >['nodes'],
    ) => Boolean(nodes.find(({ product: { id } }) => id === objectID)),
    [objectID],
  )

  useEffect(() => {
    if (orderGuideArray) {
      setIsProductInOrderGuide(findProductInNode(orderGuideArray))
    }
  }, [findProductInNode, orderGuideArray])

  const ProductMetaGroup = ({
    title,
    data,
  }: {
    title: string
    data: string
  }) => {
    return (
      <>
        <Grid item xs={6} className={classes.modalProductMetaTitle}>
          {title}
        </Grid>
        <Grid item xs={6} className={classes.modalProductMetaData}>
          {data}
        </Grid>
      </>
    )
  }

  const ProductMetaSection = () => {
    return (
      <Grid
        container
        item
        xs={12}
        className={clsx({ [classes.modalProductMeta]: !isMobile })}
      >
        {[
          { title: 'UPC', data: unitUPC },
          { title: 'Category', data: category },
          { title: 'Vendor Notes', data: notes },
        ].map(({ title, data }) => (
          <ProductMetaGroup key={title} title={title} data={data as string} />
        ))}
      </Grid>
    )
  }

  const orderHistoryNodes =
    productOrderHistory?.me?.user.retailUser?.retailCompany.store
      ?.productOrderHistory.nodes || []

  const orderHistoryFiltered = orderHistoryNodes.filter(
    (item, i, a) =>
      i === a.findIndex((order) => order.quantity === item.quantity),
  )

  const handleOrderGuideBtn = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (isDemoMode) {
      window.open('https://g170h.app.link/e/E3voPUhRVgb', '_blank')
      return
    }
    e.stopPropagation()

    const productIDs = objectID ? [objectID] : []

    const updateOrderGuideBtn = (
      data:
        | MarketAddProductsToUserOrderGuideMutation
        | MarketRemoveProductsFromOrderGuideMutation,
      input: boolean,
      message: string,
    ) => {
      if (data) {
        setIsProductInOrderGuide(input)
        toggleAlert(null, message)
      }
    }

    if (!isProductInOrderGuide) {
      const { data } = await addProductsToUserOrderGuide({
        variables: {
          input: {
            vendorProductIDs: productIDs,
            vendorStoreDepartmentAccountID: vsdaID || '',
          },
        },
      })

      if (data != null) {
        updateOrderGuideBtn(
          data,
          findProductInNode(
            data.addProductsToV2OrderGuide.orderGuide.vendorProducts?.nodes ||
              [],
          ),
          'Product added to order guide!',
        )
      }
    } else {
      const { data } = await removeProductsFromOrderGuide({
        variables: {
          input: {
            vendorProductIDs: productIDs,
            vendorStoreDepartmentAccountID: vsdaID || '',
          },
        },
      })

      if (data != null) {
        updateOrderGuideBtn(data, false, 'Product removed from order guide!')
      }
    }
  }

  const OGButtonIcon = isProductInOrderGuide ? DeleteIcon : AddIcon

  return (
    <Modal
      open={isModalOpen}
      title={productData.vendorName as string}
      description={description as string}
      toggleModal={toggleModal}
      importedClasses={classes.modal}
      titleElement={
        <Typography
          className={classes.modalHeader}
          id={productData.vendorName as string}
          variant="h2"
        >
          {productData.vendorName}
        </Typography>
      }
      body={
        <>
          <Grid container item xs={12} className={classes.modalProductDetail}>
            <Grid container item xs={12} sm={6}>
              <div
                role="img"
                style={{ backgroundImage: `url("${imgURL}")` }}
                className={classes.modalProductImg}
              />
            </Grid>

            <Grid container item xs={12} sm={6}>
              <Grid item xs={12}>
                <Price
                  productData={productData}
                  className={classes.modalPrice}
                  hidePriceByLine
                />
                <ProductTags
                  itemCode={itemCode as string}
                  productData={productData}
                  brand={brandName}
                  modalMode
                />
                <p className={classes.modalDescription}>{description}</p>

                {isBestseller && (
                  <Chip
                    icon={<Star01Icon />}
                    label="bestseller"
                    className={classes.modalBestSeller}
                  />
                )}

                {isMobile ? (
                  <Grid
                    container
                    item
                    xs={8}
                    justify="space-between"
                    className={classes.modalUnitDetailContainer}
                  >
                    <Grid item>
                      {typeof caseSize === 'string' &&
                      caseSize.trim().length > 0
                        ? `${caseSize} per case`
                        : ''}
                    </Grid>
                    <Grid item>
                      {typeof unitVolume === 'string' &&
                      unitVolume.trim().length > 0
                        ? `${unitVolume}`
                        : ''}
                    </Grid>
                    <Grid item>{unitUPC}</Grid>
                  </Grid>
                ) : (
                  <SizeDetails
                    caseSize={caseSize as string}
                    unitVolume={unitVolume as string}
                    modalMode
                  />
                )}
                {!isMobile ? (
                  <QuantityControls
                    basketProps={basketProps}
                    isDemoMode={isDemoMode}
                  />
                ) : (
                  <div className={classes.stickyQuanityControls}>
                    <QuantityControls
                      basketProps={basketProps}
                      productData={productData}
                      isDemoMode={isDemoMode}
                    />
                  </div>
                )}

                <Button
                  disabled={loadingOrderGuide}
                  variant="outlined"
                  className={clsx(classes.modalAddToOrderGuideBtn, {
                    [classes.modalRemoveFromOrderGuideBtn]:
                      isProductInOrderGuide,
                  })}
                  onClick={handleOrderGuideBtn}
                >
                  <OGButtonIcon
                    fontSize="small"
                    className={classes.modalIcon}
                  />
                  {isProductInOrderGuide ? 'Remove from' : 'Add To'} Order Guide
                </Button>
                {!isMobile && <ProductMetaSection />}
              </Grid>
            </Grid>
          </Grid>

          {isLoadingProductOrderHistory && (
            <CircularProgress className={classes.orderHistoryLoader} />
          )}

          {!isLoadingProductOrderHistory && orderHistoryNodes.length > 0 && (
            <>
              <div className={classes.sectionContainer}>
                <h3>Buy It Again</h3>
              </div>
              <div className={classes.divider} />
              <Grid
                item
                container
                xs={12}
                spacing={2}
                justify={
                  !isLargeMobile && orderHistoryFiltered.length >= 5
                    ? 'space-between'
                    : 'flex-start'
                }
              >
                {orderHistoryFiltered.map((data, idx) => (
                  <BuyAgainCard
                    key={idx}
                    isMobile={isMobile}
                    data={data}
                    basketProps={basketProps}
                  />
                ))}
              </Grid>
            </>
          )}

          {!isLoadingProductOrderHistory && isMobile && (
            <>
              <div className={classes.sectionContainer}>
                <h3>Product Details</h3>
              </div>
              <div className={classes.divider} />
              <ProductMetaSection />
            </>
          )}
        </>
      }
    />
  )
}

export default ProductDetailModal
