import React, { useState } from 'react'

import {
  algoliaVendorProductToGQL,
  GqlVendorProductToAlgolia,
  gqlVendorProductToAlgolia,
} from '../../helpers/utils'

import {
  useProductQuantity,
  useUpdateProductQuantity,
  useVendor,
} from '../../graphql/graphqlHooks'

import {
  MaxOrderableProductQuantity,
  QuantityThresholdRed,
  QuantityThresholdYellow,
} from '../../config/ProductConfig'
import { BasketProps, PriceProps, ProductExtras } from './helpers/types'
import { MarketVendorProductWithActiveDealFieldsFragment } from '@vori/gql-market'

type Props = {
  basketProps: BasketProps
  productData: (
    | Partial<
        Omit<
          MarketVendorProductWithActiveDealFieldsFragment,
          keyof { wholesaleCasePrice?: string | null; itemCode?: string }
        >
      >
    | Partial<GqlVendorProductToAlgolia>
  ) &
    ProductExtras

  stockIndicator: string
}

const WithBasketQuantity = (Component: (props: Props) => JSX.Element) =>
  function WithBasketQuantityEnhancedComponent(props: PriceProps): JSX.Element {
    const [isEditingQuantity, setIsEditingQuantity] = useState(false)

    const productData = props.fromGQL
      ? gqlVendorProductToAlgolia(props?.productData || {})
      : props?.productData || {}

    const currentVendor = useVendor(productData.vendorID as string)
    const productID = productData.objectID
    const quantity = useProductQuantity(productID as string)
    const updateQuantity = useUpdateProductQuantity()

    const onQuantityChange = (newQuantity: number) => {
      updateQuantity(
        {
          ...algoliaVendorProductToGQL(productData as Record<string, string>),
          ...props,
        },
        newQuantity,
      )
    }

    const parsedInventory = parseInt(String(productData.inventory))
    const canShowInStock = !Number.isNaN(parsedInventory)
    const inStockCount = canShowInStock ? Math.floor(parsedInventory) : null

    const outOfStock = canShowInStock && inStockCount === 0
    const disabled =
      (currentVendor?.disableDiscontinuedPurchases &&
        props?.productData.status === 'DISCONTINUED') ||
      (currentVendor?.disableOutOfStockPurchases &&
        canShowInStock &&
        outOfStock)

    const enhancedProps = {
      ...props,

      basketProps: {
        canShowInStock,
        disabled,
        inStockCount,
        isEditingQuantity,
        maxQuantity: MaxOrderableProductQuantity,
        onEditingQuantityChange: setIsEditingQuantity,
        onQuantityChange,
        outOfStock,
        quantity,
      },

      fromGQL: false,
      productData,

      stockIndicator:
        (productData.inventory || 0) < QuantityThresholdRed
          ? 'red'
          : (productData.inventory || 0) < QuantityThresholdYellow
            ? 'yellow'
            : 'green',
    }

    return <Component {...enhancedProps} />
  }

export default WithBasketQuantity
