import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useMarketGetCurrentUserQuery } from '@vori/gql-market'

import BodyBackground from '../../components/ui/BodyBackground'
import FormSection from '../../components/ui/FormSection'
import SubtleCard from '../../components/ui/SubtleCard'
import ViewLoader from '../../components/ui/ViewLoader'

import { ACCOUNT_EDIT } from '../../constants/routes'
import { Colors, CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  cardContent: {
    padding: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(4),
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  attributeLabel: {
    fontWeight: 'bold',
  },
  attributeValue: {
    color: theme.colors.GRAY_4,
    marginBottom: theme.spacing(2),
  },
  editButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
}))

const UserAccount = (): JSX.Element => {
  const classes = useStyles()
  const [isMounted, setIsMounted] = useState(false)
  const history = useHistory()

  // Prevents memory leaks, only need to query VendorsForViewer when component is mounted
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const { data, loading } = useMarketGetCurrentUserQuery({ skip: !isMounted })
  const user = data?.me?.user

  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <SubtleCard>
              <div className={classes.cardContent}>
                <Typography variant="h5" className={classes.headline}>
                  Your Account
                </Typography>
                {loading && <ViewLoader />}
                {!loading && data && (
                  <>
                    <FormSection>
                      <div className={classes.attributeLabel}>Store Name</div>
                      <div className={classes.attributeValue}>
                        {user?.retailUser?.retailCompany?.name}
                      </div>
                      <div className={classes.attributeLabel}>
                        Delivery Address
                      </div>
                      <div className={classes.attributeValue}>
                        {user?.retailUser?.retailCompany?.store?.address}
                      </div>
                      <div className={classes.attributeLabel}>Name</div>
                      <div className={classes.attributeValue}>
                        {user?.displayName || 'Not set'}
                      </div>
                      <div className={classes.attributeLabel}>Email</div>
                      <div className={classes.attributeValue}>
                        {user?.email}
                      </div>
                      <div className={classes.attributeLabel}>Mobile</div>
                      <div className={classes.attributeValue}>
                        {user?.phoneNumber || 'Not set'}
                      </div>
                    </FormSection>
                    <FormSection>
                      <Button
                        className={classes.editButton}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                          history.push(ACCOUNT_EDIT)
                        }}
                      >
                        Edit Account Info
                      </Button>
                    </FormSection>
                  </>
                )}
              </div>
            </SubtleCard>
          </Grid>
        </Grid>
      </Container>
    </BodyBackground>
  )
}

export default UserAccount
