import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import BrandTag from './BrandTag'
import ItemCodeTag from './ItemCodeTag'
import { GqlVendorProductToAlgolia } from '../../helpers/utils'

export const styles = makeStyles((theme) => ({
  brandRow: {
    flexWrap: 'wrap',
  },
  brandRowItem: {
    marginTop: theme.spacing(),
  },
  itemCode: {
    marginRight: theme.spacing(1),
  },
  tags: {
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
}))

type Props = {
  itemCode: string
  productData: Partial<GqlVendorProductToAlgolia>
  brand?: string
  modalMode?: boolean
}

const ProductTags = ({
  itemCode,
  productData,
  brand,
  modalMode,
}: Props): JSX.Element => {
  const classes = styles()

  return (
    <div className={classes.brandRow}>
      {typeof itemCode === 'string' && itemCode.length > 0 && (
        <ItemCodeTag
          className={clsx(classes.brandRowItem, classes.itemCode, {
            [classes.tags]: modalMode,
          })}
          distributor={productData.vendorName as string}
          itemCode={productData.itemCode as string}
        />
      )}

      {typeof brand === 'string' && brand.length > 0 && (
        <BrandTag
          brand={brand}
          className={clsx(classes.brandRowItem, {
            [classes.tags]: modalMode,
          })}
        />
      )}
    </div>
  )
}

export default ProductTags
