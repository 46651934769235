import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

import { CustomTheme } from '../../theme'

const BORDER_SIZE = 2

const useStyles = makeStyles((theme: CustomTheme) => ({
  vendorAvatar: {
    backgroundColor: theme.colors.WHITE,
    border: `${BORDER_SIZE}px solid ${theme.colors.VORI_PURPLE_LIGHT}`,
    marginRight: theme.spacing(),
  },
}))

type Props = {
  className?: string
  imgURL: string
  vendorName: string
}

const VendorAvatar = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { className = '', imgURL, vendorName } = props

  return (
    <Avatar
      className={clsx(className, classes.vendorAvatar)}
      alt={`${vendorName} Logo`}
      src={imgURL}
    />
  )
}

export default VendorAvatar
