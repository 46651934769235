import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import { useMarketGetVendorDetailsBySlugOrIdQuery } from '@vori/gql-market'

import { getVendorRoutes } from '../routers/VendorRoutes'
import { isIOS } from '../helpers/utils'
import { shouldNotify } from '../helpers/ios-app-notify'
import { useLayout } from '../helpers/hooks'
import { VENDOR_NOT_FOUND } from '../constants/routes'
import { useCurrentUser } from '../graphql/graphqlHooks'

import VendorCatalog from '../components/vendor/VendorCatalog'
import VendorHeader from '../components/vendor/VendorHeader'
import VendorHome from '../components/vendor/VendorHome'
import VendorOrderGuide from '../components/vendor/VendorOrderGuide'
import VendorPromotions from '../components/vendor/VendorPromotions'
import VendorTabMenu from '../components/vendor/VendorTabMenu'
import ViewLoader from '../components/ui/ViewLoader'
import IOSAppNotify from '../components/shared/iOSAppNotify'

const useStyles = makeStyles((theme) => ({
  tabContent: {
    marginTop: theme.spacing(2),
  },
}))

const Vendor = (): JSX.Element => {
  const classes = useStyles()
  const { vendor: slugOrID } = useParams<{ vendor: string }>()
  const isMobile = useLayout()
  const [isMounted, setIsMounted] = useState(false)
  const [showIOSAppNotify, setShowIOSAppNotify] = useState(false)

  const { data, loading } = useMarketGetVendorDetailsBySlugOrIdQuery({
    variables: { slugOrID },
    skip: !isMounted,
  })

  const { data: currentUser } = useCurrentUser()
  const demoMode = (
    (
      currentUser as {
        me: { user: { canSeeFeatures: { enabledFeatures: string[] } } }
      }
    )?.me?.user?.canSeeFeatures?.enabledFeatures || []
  ).includes('market.sunridgefarmsdemo')

  // Prevents memory leaks
  useEffect(() => {
    setIsMounted(true)

    function handleWindowSize() {
      if (isIOS() && shouldNotify() && !demoMode) {
        setShowIOSAppNotify(true)
      } else {
        setShowIOSAppNotify(false)
      }
    }
    handleWindowSize()

    window.addEventListener('resize', handleWindowSize)

    return () => {
      window.removeEventListener('resize', handleWindowSize)
    }
  }, [isMounted, demoMode])

  const vendor = data?.vendorBySlug

  const { catalogURL, orderGuideURL, promotionsURL } = getVendorRoutes(slugOrID)

  const showVendorHeader = useRouteMatch(catalogURL) !== null

  if (loading) {
    return <ViewLoader />
  }

  if (vendor === null) {
    return <Redirect to={VENDOR_NOT_FOUND} />
  }

  return vendor ? (
    <>
      {showIOSAppNotify && (
        <IOSAppNotify initalOpen={showIOSAppNotify} vendor={vendor} />
      )}

      {showVendorHeader && <VendorHeader vendor={vendor} />}
      <VendorTabMenu />
      <Container maxWidth="xl">
        <Grid
          container
          className={classes.tabContent}
          spacing={isMobile ? 1 : 4}
        >
          <Switch>
            <Route path={promotionsURL}>
              <Grid item container>
                <VendorPromotions vendorID={vendor.id} />
              </Grid>
            </Route>
            <Route path={orderGuideURL}>
              <Grid item container>
                <VendorOrderGuide vendorID={vendor.id} />
              </Grid>
            </Route>
            <Route path={catalogURL}>
              <Grid item container>
                <VendorCatalog vendorID={vendor.id} />
              </Grid>
            </Route>
            <Route>
              <Grid item>
                <VendorHome />
              </Grid>
            </Route>
          </Switch>
        </Grid>
      </Container>
    </>
  ) : (
    <ViewLoader />
  )
}

export default Vendor
