const INTERCOM_APP_ID = 'i1sp17e9'

export function initIntercom(data: Intercom_.IntercomSettings): void {
  if (window.Intercom == null) {
    return
  }
  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    ...data,
  })
}

export function shutdownIntercom(): void {
  if (!window.Intercom) {
    return
  }
  window.Intercom('shutdown')
}

export function showIntercom(): void {
  if (!window.Intercom) {
    return
  }

  window.Intercom('show')
}

export function hideIntercom(): void {
  if (!window.Intercom) {
    return
  }

  window.Intercom('hide')
}

export function startIntercomConversation(initialUserInput: string): void {
  if (!window.Intercom) {
    return
  }
  window.Intercom('showNewMessage', initialUserInput)
}
