import React from 'react'
import { Loader, Flex } from '@vori/gourmet-components'

function ViewLoader(): JSX.Element {
  return (
    <Flex center flex={1}>
      <Loader />
    </Flex>
  )
}

export default ViewLoader
