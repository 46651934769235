import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { MarketGetVendorDetailsBySlugOrIdQuery } from '@vori/gql-market'

import { displayCurrency } from '../../helpers/utils'
import { CustomTheme, GRAY_4_BG } from '../../theme'
import { useCurrentUser } from '../../graphql/graphqlHooks'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    minHeight: 200,
    position: 'relative',
    display: 'flex',
  },

  headerImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    filter: 'brightness(0.5)',
    zIndex: 100,
  },

  content: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1000,
  },

  avatar: {
    backgroundColor: theme.colors.WHITE,
    border: `1px solid ${theme.colors.GRAY_1}`,
    height: 72,
    width: 72,
  },

  vendorName: {
    marginTop: theme.spacing(1),
    color: theme.colors.WHITE,
    fontWeight: 'bold',
  },

  vendorMeta: {
    marginTop: theme.spacing(1),
    color: theme.colors.WHITE,
  },
}))

type Props = {
  vendor: NonNullable<MarketGetVendorDetailsBySlugOrIdQuery['vendorBySlug']>
}

/**
 * @todo add mobile variant
 */
function VendorHeader(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { data: currentUser } = useCurrentUser()
  const retailCompanyID = currentUser?.me?.user.retailUser?.retailCompany.id

  const meta = []
  const { vendor } = props
  const {
    headerImageURL,
    name,
    imgURL,
    orderMin,
    orderDeadline,
    orderMinimums,
  } = vendor

  const storeOrderMin =
    orderMinimums &&
    orderMinimums.find(({ storeID }) => storeID === retailCompanyID)

  if (storeOrderMin) {
    meta.push(`${displayCurrency(storeOrderMin.orderMin)} minimum`)
  } else if (orderMin) {
    meta.push(`${displayCurrency(orderMin)} minimum`)
  }

  if (orderDeadline) {
    meta.push(`due by ${orderDeadline}`)
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.headerImage}
        style={{
          backgroundImage: `url(${headerImageURL || GRAY_4_BG})`,
        }}
      ></div>
      <div className={classes.content}>
        <Avatar
          className={classes.avatar}
          alt={`${name} Logo`}
          src={imgURL as string}
        />
        <Typography
          data-test-id="vendor-name-text"
          className={classes.vendorName}
          id="vendor-name"
        >
          {name}
        </Typography>
        <Typography className={classes.vendorMeta}>
          {meta.join(' • ')}
        </Typography>
      </div>
    </div>
  )
}

export default VendorHeader
