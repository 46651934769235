import React, { useMemo, useState } from 'react'

import {
  useMarketGetVendorProductsQuery,
  MarketGetVendorProductsQuery,
  MarketVendorProductWithActiveDealFieldsFragment,
} from '@vori/gql-market'
import { useLocation } from 'react-router-dom'
import VendorProductList from '../product/VendorProductList'
import SearchResultStats from '../product/SearchResultStats'
import { cloneDeep, set } from 'lodash'
import ViewLoader from '../ui/ViewLoader'

type Props = {
  vendorID: string
}

const VendorCatalog = (props: Props): JSX.Element | null => {
  const { vendorID } = props
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])
  const query = params.get('query')
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const { data, error, fetchMore, loading } = useMarketGetVendorProductsQuery({
    variables: {
      vendorID,
      query,
      limit: 16,
    },
  })

  if (error != null) {
    return null
  }

  if (loading) {
    return <ViewLoader />
  }

  const products: MarketVendorProductWithActiveDealFieldsFragment[] =
    data?.vendorProducts.nodes || []
  const hasMore = Boolean(data?.vendorProducts.hasMore)
  const cursor = data?.vendorProducts.cursor

  if (!products) {
    return null
  }

  const loadMoreItems = () => {
    if (!hasMore) {
      return null
    }

    setIsFetchingMore(true)
    return fetchMore({
      variables: { cursor: cursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult == null) {
          return previousResult
        }

        const newProducts = (fetchMoreResult?.vendorProducts?.nodes ||
          []) as MarketVendorProductWithActiveDealFieldsFragment[]
        const previousProducts = (previousResult?.vendorProducts?.nodes ||
          []) as MarketVendorProductWithActiveDealFieldsFragment[]
        const combinedProducts: MarketVendorProductWithActiveDealFieldsFragment[] =
          [...previousProducts, ...newProducts]

        const newQuery: MarketGetVendorProductsQuery = set(
          cloneDeep(fetchMoreResult),
          'vendorProducts.nodes',
          combinedProducts,
        )
        setIsFetchingMore(false)

        return newQuery
      },
    })
  }

  return (
    <>
      <SearchResultStats
        query={query}
        hasMore={hasMore}
        totalCount={products.length}
      />
      <VendorProductList
        vendorID={vendorID}
        products={products}
        hasMore={hasMore}
        loading={loading}
        loadMoreItems={loadMoreItems}
        isFetchingMore={isFetchingMore}
      />
    </>
  )
}

export default VendorCatalog
