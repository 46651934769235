import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { pluralize } from '../../helpers/utils'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  byline: {
    color: theme.colors.GRAY_5,
  },
  queryString: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
}))

const SearchResultStats = (props: {
  query: string | null
  hasMore: boolean
  totalCount: number
}): JSX.Element => {
  const classes = useStyles(props)
  const { query, totalCount, hasMore } = props
  const resultsString = pluralize('result', totalCount)
  const countString = `${totalCount}${hasMore ? '+' : ''}`

  return (
    <div>
      {query && query.length > 0 ? (
        <>
          <div className={classes.byline}>
            {`${countString} ${resultsString} for `}
          </div>
          <div className={classes.queryString}>
            &ldquo;{query.trim()}&rdquo;
          </div>
        </>
      ) : null}
    </div>
  )
}

export default SearchResultStats
