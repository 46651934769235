import { useEffect, useRef } from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
  MarketPromotionFieldsFragment,
  useMarketGetActivePromotionsForVendorQuery,
} from '@vori/gql-market'

/**
 * Executes the given `callback` when clicking outside of the `element` being
 * tracked, or any of the elements specified as `otherTargets`.
 *
 * @param {null|HTMLElement} element - The DOM element to track.
 * @param {Function} callback - Callback function executed when clicking outside.
 * @param {object[]} otherTargets - Array of other React `refs` pointing elements.
 */
export function useOnClickOutside(
  element: HTMLElement | null,
  callback: (event: MouseEvent | TouchEvent) => void,
  otherTargets: HTMLElement[] = [],
): void {
  useEffect(() => {
    const otherTargetsArray = Array.isArray(otherTargets)
      ? otherTargets
      : [otherTargets]

    const eventListener = (event: MouseEvent | TouchEvent): void => {
      /**
       * The `callback` callback function will be invoked tf the `target`
       * of the click:
       *
       * - Is not `element`.
       * - Doesn't contain `element`.
       * - Is not any of the elements in the `otherTargets` array.
       * - Is not contained by any of the elements in the `otherTargets` array.
       */
      if (
        element &&
        element !== event.target &&
        !element.contains(event.target as Node) &&
        !otherTargetsArray.includes(event.target as HTMLElement) &&
        !otherTargetsArray.some((otherTarget) => {
          if (otherTarget instanceof HTMLElement) {
            return otherTarget.contains(event.target as Node)
          }

          return false
        })
      ) {
        callback(event)
      }
    }

    document.addEventListener('mousedown', eventListener)
    document.addEventListener('touchstart', eventListener)

    return () => {
      document.removeEventListener('mousedown', eventListener)
      document.removeEventListener('touchstart', eventListener)
    }
  }, [callback, element, otherTargets])
}

export function useLayout(): boolean {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('xs'))
}

export function useActivePromotionsByVendor(vendorID: string): {
  data: MarketPromotionFieldsFragment[]
  error: unknown
  loading: boolean
} {
  const { data, error, loading } = useMarketGetActivePromotionsForVendorQuery({
    variables: { vendorID },
  })
  const vendorPromotions = data?.vendorByID?.activePromotions?.nodes || []

  return {
    data: vendorPromotions,
    error,
    loading,
  }
}

export function useActiveDeals(
  productIds: string[] = [],
  vendorID: string,
): {
  data: unknown
  error: unknown
  loading: boolean
} {
  const activeDeals = new Map()

  const {
    data: activePromotions,
    error,
    loading,
  } = useActivePromotionsByVendor(vendorID)

  if (!activePromotions == null || !productIds?.length || vendorID == null) {
    return { data: activeDeals, error, loading }
  }

  productIds.forEach((productId: string) => {
    activePromotions.every(function (promotion) {
      const deals = promotion?.deals?.nodes || []

      const activeDeal = deals.find((deal) => {
        const vendorProducts = deal?.vendorProducts?.nodes || []

        return vendorProducts.find(
          (vendorProduct) => vendorProduct?.id === productId,
        )
      })

      if (activeDeal !== undefined) {
        if (activeDeal) {
          activeDeals.set(productId, activeDeal)
        }

        return false
      }

      return true
    })
  })

  return { data: activeDeals, error, loading }
}

export function useIsMounted(): boolean {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted.current
}
