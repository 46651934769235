import './styles/index.css'

import React, { StrictMode, useEffect, useState } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

// `fetch()` polyfill
import 'whatwg-fetch'
import smoothscroll from 'smoothscroll-polyfill'

import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/browser'
import * as Mixpanel from 'mixpanel-browser'

import { useMarketGetActivePromotionsQuery } from '@vori/gql-market'
import { ENV_FULLSTORY_ORGANIZATION_ID, ENV_SENTRY_DSN } from '@vori/market-env'

import { apolloClient } from './graphql'
import { AuthContext, useAuth } from './auth/AuthContext'

import { FIREBASE_CONFIG } from './config/AppConfig'

import { initIntercom, shutdownIntercom } from './config/Intercom'
import AppRouter from './routers/AppRouter'
import firebase from './firebase/firebase'
import Preloader from './components/ui/Preloader'
import reportWebVitals from './reportWebVitals'
import theme from './theme'

import { useVendorsForViewer } from './graphql/graphqlHooks'

/**
 * Type definitions based on {@link https://twitter.com/heap/status/1029152073609170945?s=20}
 */
declare global {
  interface Window {
    heap: Heap
  }
}

interface Heap {
  track: (event: string, properties?: Record<string, unknown>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: Record<string, unknown>) => void
  addEventProperties: (properties: Record<string, unknown>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
}

smoothscroll.polyfill()

const Content = ({ isLoadingOtherSources = false }) => {
  const authContext = useAuth()
  const { isAuthenticated, isCreatingInvite } = authContext.auth

  /**
   * Preload vendors and promotions
   */
  const { loading: isLoadingVendors } = useVendorsForViewer({
    skip: !isAuthenticated || isCreatingInvite,
  })

  const { loading: isLoadingActivePromos } = useMarketGetActivePromotionsQuery({
    skip: !isAuthenticated || isCreatingInvite,
  })

  return isLoadingOtherSources || isLoadingVendors || isLoadingActivePromos ? (
    <Preloader key="initial-loader" />
  ) : (
    <Router>
      <AppRouter />
    </Router>
  )
}

const AppRoot = () => {
  const authContext = useAuth()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const { isAuthenticating } = authContext.auth

  useEffect(() => {
    if (!isAuthenticating) {
      setIsInitialLoad(false)
    }
  }, [isAuthenticating])

  return (
    <StrictMode>
      <AuthContext.Provider value={authContext}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {isAuthenticating || isInitialLoad ? (
              <Preloader key="initial-loader" />
            ) : (
              <Content />
            )}
          </ThemeProvider>
        </ApolloProvider>
      </AuthContext.Provider>
    </StrictMode>
  )
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: ENV_SENTRY_DSN,
    environment: (FIREBASE_CONFIG.projectId || '').includes('dev')
      ? 'environment'
      : 'production',
  })
  FullStory.init({ orgId: ENV_FULLSTORY_ORGANIZATION_ID })
  Mixpanel.init('fd4218a7c6d438a28d95f33be0ff8a01')

  firebase.auth.onIdTokenChanged((user) => {
    const { heap } = window
    if (user != null) {
      // On login or logged-in page load
      const { email, uid, displayName } = user
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: email || undefined,
          id: uid,
          displayName,
        })
      })
      FullStory.identify(uid, {
        email: email || undefined,
        displayName: displayName || undefined,
      })
      Mixpanel.identify(uid)
      Mixpanel.people.set({
        email,
        displayName,
      })
      if (heap != null) {
        heap.identify(uid)
        heap.addUserProperties({ displayName, email })
      }
      // Don't initialize intercom until signed in
      initIntercom({
        email: email || undefined,
        name: displayName || undefined,
        user_id: uid || undefined,
        // Don't show the Intercom bubble unless users actively request support
        // Otherwise, the bubble might obscure some UI elements, especially on mobile
        hide_default_launcher: true,
      })
    } else {
      // on logout, or logged-out page load
      Sentry.configureScope((scope) => {
        scope.setUser(null)
      })
      FullStory.anonymize()
      Mixpanel.reset()
      if (heap != null) {
        heap.resetIdentity()
      }
      shutdownIntercom()
    }
  })
}

if (window.navigator.onLine) {
  // Render the preloader on initial load
  render(<AppRoot />, document.getElementById('root'))
} else {
  render(
    <div>
      <h2>:( No internet connection.</h2>
    </div>,
    document.getElementById('root'),
  )
}

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  // Prior to 2020-05-25, the app leveraged PWA caching.
  // This removes all service workers to prevent old version of app shell from loading.
  const unregisterOldServiceWorkers = async () => {
    const registrations = await navigator.serviceWorker.getRegistrations()
    registrations.forEach((registration) => {
      registration.unregister()
    })
  }
  unregisterOldServiceWorkers()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
