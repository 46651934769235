import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { ENV_GRAPHQL_ENDPOINT, ENV_VERSION } from '@vori/market-env'

import firebase from '../firebase/firebase'

if (!ENV_GRAPHQL_ENDPOINT) {
  throw new Error('Missing GraphQL endpoint config')
}

const cache = new InMemoryCache({
  possibleTypes: {
    Deal: ['PercentageDeal', 'FixedDeal', 'BuyXGetYFreeDeal'],
  },
  typePolicies: {
    RetailUser: {
      fields: {
        user: {
          merge: true,
        },
      },
      keyFields: ['user', ['id']],
    },
    OrderLineItem: {
      fields: {
        quantity: {
          merge: true,
        },
      },
    },
  },
})

const httpLink = createHttpLink({
  uri: ENV_GRAPHQL_ENDPOINT,
})

const authLink = setContext(async (_, { headers }) => {
  const token = (await firebase?.auth?.currentUser?.getIdToken()) || null

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// eslint-disable-next-line import/prefer-default-export
export const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  name: 'market-web',
  version: ENV_VERSION,
})
