import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'

import { AuthContext } from '../../auth/AuthContext'
import { ACCOUNT, HOME, SIGN_IN } from '../../constants/routes'
import { startIntercomConversation } from '../../config/Intercom'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  drawer: {
    justifyContent: 'space-between',
  },
  nonActionableMenuItem: {
    color: theme.colors.GRAY_5,
  },
}))

type Props = {
  onClose: () => void
  shown: boolean
}

const MobileMenu = (props: Props): JSX.Element => {
  const {
    signOut,
    auth: { isAuthenticated, user },
  } = useContext(AuthContext)
  const { shown, onClose } = props
  const classes = useStyles(props)
  const history = useHistory()
  return (
    <Drawer
      PaperProps={{ className: classes.drawer }}
      anchor="left"
      open={shown}
      onClose={onClose}
    >
      <MenuList>
        <MenuItem
          onClick={() => {
            onClose()
            history.push(HOME)
          }}
        >
          Vori Market
        </MenuItem>
      </MenuList>
      <MenuList>
        <Divider />
        <MenuItem
          onClick={() => {
            onClose()
            startIntercomConversation('Please describe your issue here')
          }}
        >
          Get Support
        </MenuItem>
        <Divider />
        {!isAuthenticated && (
          <MenuItem
            onClick={() => {
              onClose()
              history.push(SIGN_IN)
            }}
          >
            Sign in
          </MenuItem>
        )}
        {isAuthenticated && [
          /* `MenuList` does not accept Fragment children, so use an Array */
          <MenuItem className={classes.nonActionableMenuItem} key="username">
            Logged in{user?.email && ` as ${user.email}`}
          </MenuItem>,
          <MenuItem
            key="account"
            onClick={() => {
              onClose()
              history.push(ACCOUNT)
            }}
          >
            Your Account
          </MenuItem>,
          <MenuItem key="signout" onClick={signOut}>
            Sign Out
          </MenuItem>,
        ]}
      </MenuList>
    </Drawer>
  )
}

export default MobileMenu
