import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'

import { ChevronDownIcon, ChevronUpIcon } from '@vori/gourmet-icons'

import { Colors, CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  expansionPanel: {
    '&.MuiAccordion-root': {
      '& .MuiAccordionSummary-root': {
        padding: 0,
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
      '& .MuiAccordionSummary-content': {
        marginBottom: 0,
        marginTop: 0,
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        marginTop: 0,
        marginBottom: theme.spacing(2),
      },
      '&.Mui-expanded:before': {
        opacity: 1,
      },
    },
  },
  summaryContainer: {
    alignItems: 'center',
    color: theme.colors.GRAY_5,
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
  iconBlock: {
    width: 50,
    height: 50,
    marginRight: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightSideContent: { flex: 1, display: 'flex', flexDirection: 'column' },
  chevronIcon: {
    fontSize: '1rem',
    transition: 'transform 0.2s ease-out',
  },
  chevronIconRotated: {
    transform: 'rotate(-180deg)',
  },
  summaryTitle: { color: theme.colors.BLACK, fontWeight: 'bold' },
  contentContainer: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    // Same as .MuiAccordion-root:before
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}))

type Props = {
  isExpandable?: boolean
  isExpanded: boolean
  onExpandedChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    newExpanded: boolean,
  ) => void
  IconComponent: (props: { color: string }) => JSX.Element
  content: JSX.Element
  summaryContent: JSX.Element
  summaryTitle: string | JSX.Element
}

const ShippingDetailCollapsiblePane = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const {
    isExpandable,
    isExpanded,
    onExpandedChange,
    IconComponent,
    content,
    summaryContent,
    summaryTitle,
  } = props
  const expandable = isExpandable !== false
  return (
    <Accordion
      className={classes.expansionPanel}
      elevation={0}
      square
      expanded={expandable ? isExpanded : false}
      onChange={onExpandedChange}
    >
      <AccordionSummary>
        <div className={classes.summaryContainer}>
          <div className={classes.iconBlock}>
            <IconComponent color={Colors.GRAY_5} />
          </div>
          <div className={classes.rightSideContent}>
            <div className={classes.summaryTitle}>{summaryTitle}</div>
            {summaryContent}
          </div>
          {expandable && (
            <IconButton>
              {isExpanded ? (
                <ChevronUpIcon variant="default" />
              ) : (
                <ChevronDownIcon variant="default" />
              )}
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.contentContainer}>{content}</div>
      </AccordionDetails>
    </Accordion>
  )
}

export default ShippingDetailCollapsiblePane
