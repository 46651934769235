import Cookies from 'js-cookie'

import { IOS_APP_NOTIFY } from '../constants'

const expires = 14 // cookie expires in 14 days

export const shouldNotify = (): boolean =>
  Cookies.get(IOS_APP_NOTIFY) === undefined ||
  Cookies.get(IOS_APP_NOTIFY) === 'true'

export const disableAppNotify = (): void => {
  Cookies.set(IOS_APP_NOTIFY, '', {
    expires,
  })
}

// export default () => {
//   const now = new Date().valueOf();

//   Cookies.set(IOS_APP_NOTIFY, true, {
//     expires,
//   });

//   setTimeout(() => {
//     // Allows us to cancel app store redirect if the user opens the app
//     if (new Date().valueOf() - now > 25) return;
//     window.location.replace(IOS_APP_STORE_LINK);
//   }, 25);

//   window.location = CUSTOM_IOS_URL;
// };
