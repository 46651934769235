import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { CustomTheme } from '../../theme'

export const styles = makeStyles((theme: CustomTheme) => ({
  sizeDetails: {
    color: theme.colors.GRAY_3,
  },
  modalSizeDetails: {
    fontSize: '1rem',
  },
}))

type Props = {
  caseSize: string | number | null
  unitVolume: string | null
  modalMode?: boolean
}

const SizeDetails = ({
  caseSize,
  unitVolume,
  modalMode,
}: Props): JSX.Element => {
  const classes = styles()

  return (
    <div
      className={clsx(classes.sizeDetails, {
        [classes.modalSizeDetails]: modalMode,
      })}
    >
      {`${
        typeof caseSize === 'string' && caseSize.trim().length > 0
          ? `${caseSize} per case`
          : ''
      }${
        typeof unitVolume === 'string' && unitVolume.trim().length > 0
          ? ` · ${unitVolume}`
          : ''
      }`}
    </div>
  )
}

export default SizeDetails
