import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  section: {
    marginBottom: theme.spacing(8),
  },
  sectionLink: {
    fontSize: '1rem',
    color: theme.colors.VORI_PURPLE,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  sectionMeta: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  sectionName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}))

type Props = {
  children?: ReactNode
  SeeAllComponent?: JSX.Element | null
  seeAllURL?: string | null
  title: string
}

const Section = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { children, SeeAllComponent = null, seeAllURL, title } = props

  return (
    <div className={classes.section}>
      <div className={classes.sectionMeta}>
        <Typography variant="h5" className={classes.sectionName}>
          {title}
        </Typography>
        {!seeAllURL && SeeAllComponent}
        {seeAllURL && (
          <Link className={classes.sectionLink} to={seeAllURL}>
            See all
          </Link>
        )}
      </div>
      {children}
    </div>
  )
}

export default Section
