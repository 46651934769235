import React, { useCallback } from 'react'
import { capitalize } from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import Typography from '@material-ui/core/Typography'

import { pluralize } from '../../helpers/utils'
import { ProductOrderHistory } from '../product/helpers/types'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.colors.GRAY_0,
    border: '0 none',
    borderRadius: 6,
    padding: `${theme.spacing()}px`,

    '& .MuiButton-label': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  dateText: {
    letterSpacing: 'normal',
  },

  caseText: {
    margin: '2px auto',
  },

  iconText: {
    alignItems: 'center',
    display: 'flex',
  },
}))

interface Props extends ProductOrderHistory {
  onReorderClick: ({
    orderDateTime,
    quantity,
    unitOfMeasure,
  }: Partial<ProductOrderHistory>) => void
}

function ReorderButton(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { orderDateTime, quantity, unitOfMeasure, onReorderClick } = props

  const onReorderClickLocal = useCallback(() => {
    if (onReorderClick) {
      onReorderClick({ orderDateTime, quantity, unitOfMeasure })
    }
  }, [onReorderClick, orderDateTime, quantity, unitOfMeasure])

  return (
    <Button
      className={classes.container}
      fullWidth
      onClick={onReorderClickLocal}
      variant="contained"
    >
      <Typography className={classes.dateText} variant="caption">
        {orderDateTime}
      </Typography>
      <Typography className={classes.caseText} variant="body1">
        {`${quantity} ${pluralize(capitalize(unitOfMeasure), quantity)}`}
      </Typography>
      <Typography
        className={classes.iconText}
        color="primary"
        variant="caption"
      >
        <FlashOnIcon fontSize="inherit" /> GET IT AGAIN
      </Typography>
    </Button>
  )
}

export default ReorderButton
