import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

const PublicRoute = ({
  component: Component,
  path,
  ...rest
}: RouteProps): JSX.Element => {
  return <Route {...rest} component={Component} />
}

export default PublicRoute
