import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

import { MarketVendorFieldsFragment } from '@vori/gql-market'

import VendorAvatar from './VendorAvatar'
import { useLayout } from '../../helpers/hooks'
import { useVendorsForViewer } from '../../graphql/graphqlHooks'

const MIN_SELECT_WIDTH = 180

const useStyles = makeStyles(() => ({
  formControl: {
    left: (props: { isMobile: boolean }) => (props.isMobile ? 0 : 50),
    minHeight: 32,
    minWidth: MIN_SELECT_WIDTH,
    overflow: 'visible',
    position: (props) => (props.isMobile ? 'relative' : 'absolute'),
    top: (props) => (props.isMobile ? 0 : 8.75),
    zIndex: 10000,

    '& .MuiInputLabel-root': {
      transform: 'none',
    },

    '& .MuiInputLabel-root + .MuiInput-formControl': {
      marginTop: 0,
      minHeight: 32,
    },

    '& .MuiSelect-root': {
      borderRadius: 9999,
      minHeight: 32,
      padding: 0,
    },
  },

  vendorAvatar: {
    height: 32,
    width: 32,
  },

  vendorContainer: {
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
  },
}))

type Props = {
  className?: string
  defaultVendorID: string
  onChange: (newSelectedVendor: MarketVendorFieldsFragment | null) => void
}

export default function VendorSelector({
  className = '',
  defaultVendorID,
  onChange,
}: Props): JSX.Element {
  const [currentVendor, setCurrentVendor] =
    useState<MarketVendorFieldsFragment | null>(null)
  const [selectLabelRef, setSelectLabelRef] = useState<HTMLLabelElement | null>(
    null,
  )
  const [selectWidth, setSelectWidth] = useState(MIN_SELECT_WIDTH)
  const { loading, vendors } = useVendorsForViewer()
  const isMobile = useLayout()
  const classes = useStyles({ isMobile })
  const theme = useTheme()

  const isLoading = loading || currentVendor == null

  const onChangeHandler = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const newSelectedVendor =
      (vendors as MarketVendorFieldsFragment[]).find(
        (vendor) => vendor.id === event.target.value,
      ) || null

    setCurrentVendor(newSelectedVendor)

    if (onChange) {
      onChange(newSelectedVendor)
    }
  }

  useEffect(() => {
    if (vendors != null && currentVendor == null && defaultVendorID != null) {
      setCurrentVendor(
        vendors.find(
          (vendor) =>
            vendor.id === defaultVendorID || vendor.slug === defaultVendorID,
        ) || null,
      )
    }
  }, [currentVendor, defaultVendorID, vendors])

  useEffect(() => {
    if (selectLabelRef) {
      /**
       * Label width + select icon width + spacing between them.
       */
      setSelectWidth(
        isMobile ? 56 : selectLabelRef.offsetWidth + 24 + theme.spacing(),
      )
    }
  }, [currentVendor, isMobile, selectLabelRef, theme])

  if (isLoading) {
    return (
      <div className={clsx(className, classes.formControl)}>
        <Typography align="center" component="div" color="textSecondary">
          <CircularProgress size="0.75rem" /> Loading vendors...
        </Typography>
      </div>
    )
  }

  return vendors && vendors.length <= 1 ? (
    <div className={clsx(className, classes.formControl)}>
      <Typography className={classes.vendorContainer} variant="subtitle2">
        <VendorAvatar
          className={classes.vendorAvatar}
          imgURL={currentVendor.imgURL as string}
          vendorName={currentVendor.name as string}
        />
        {!isMobile ? currentVendor.name : null}
      </Typography>
    </div>
  ) : (
    <FormControl
      className={clsx(className, classes.formControl)}
      style={{ minWidth: selectWidth }}
    >
      <InputLabel
        ref={setSelectLabelRef}
        disableAnimation
        id="vendor-selector"
        shrink={false}
      >
        <Typography className={classes.vendorContainer} variant="subtitle2">
          <VendorAvatar
            className={classes.vendorAvatar}
            imgURL={currentVendor.imgURL as string}
            vendorName={currentVendor.name as string}
          />
          {!isMobile ? currentVendor.name : null}
        </Typography>
      </InputLabel>
      <Select
        autoWidth
        disableUnderline
        labelId="vendor-selector"
        MenuProps={{ transitionDuration: 0 }}
        onChange={onChangeHandler}
        renderValue={() => null}
        value={currentVendor?.id}
      >
        {vendors &&
          vendors.map((vendor) => (
            <MenuItem key={`vendor-selector-${vendor.id}`} value={vendor.id}>
              <Typography
                className={classes.vendorContainer}
                variant="subtitle2"
              >
                <VendorAvatar
                  className={classes.vendorAvatar}
                  imgURL={vendor.imgURL as string}
                  vendorName={vendor.name as string}
                />
                {vendor.name}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
