import React, { useState } from 'react'

import { getMaxQuantity } from '../../helpers/utils'
import { WithAddToCartInputExtended } from './helpers/types'

const WithAddToCart = (
  Component: (props: Partial<WithAddToCartInputExtended>) => JSX.Element | null,
) =>
  function WithAddToCartEnhancedComponent(
    props: Partial<WithAddToCartInputExtended>,
  ): JSX.Element | null {
    const { basketProps } = props

    const [localQuantity, setLocalQuantity] = useState(
      basketProps?.quantity || props?.quantity || 0,
    )

    const maxQuantity = getMaxQuantity(
      basketProps?.maxQuantity || props?.maxQuantity || 0,
    )

    const incrementQuantity = (shiftKeyPressed: boolean) => {
      const delta = shiftKeyPressed ? 10 : 1
      setLocalQuantity(Math.min(maxQuantity, localQuantity + delta))
    }

    const decrementQuantity = (shiftKeyPressed: boolean) => {
      const delta = shiftKeyPressed ? 10 : 1
      setLocalQuantity(Math.max(0, localQuantity - delta))
    }

    const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'ArrowUp') {
        incrementQuantity(event.shiftKey)
        event.preventDefault()
      } else if (event.key === 'ArrowDown') {
        decrementQuantity(event.shiftKey)
        event.preventDefault()
      }
    }

    const DigitsOnlyRegEx = /[\D]/g

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const number = Number(event.target.value.replace(DigitsOnlyRegEx, ''))

      if (Number.isNaN(number)) {
        return
      }

      // eslint-disable-next-line no-param-reassign
      event.target.value = String(number)
      setLocalQuantity(number)
    }

    const enhancedProps = {
      ...props,
      basketProps,
      localQuantity,
      maxQuantity,
      setLocalQuantity,
      incrementQuantity,
      decrementQuantity,
      onInputKeyDown,
      onInputChange,
    }
    return <Component {...enhancedProps} />
  }

export default WithAddToCart
