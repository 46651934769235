import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'

import { useLayout } from '../../helpers/hooks'
import { showIntercom } from '../../config/Intercom'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0.5, 2),

    [theme.breakpoints.only('xs')]: {
      margin: '0 !important',
      minWidth: 'auto',
      padding: theme.spacing(1),

      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  },
}))

const HelpButton = (): JSX.Element => {
  const classes = useStyles()
  const isMobile = useLayout()

  return (
    <Button
      id="nav-help-button"
      className={clsx(classes.button)}
      color="primary"
      startIcon={<LiveHelpIcon />}
      variant={isMobile ? 'text' : 'contained'}
      onClick={() => {
        showIntercom()
      }}
    >
      {isMobile ? '' : 'Help'}
    </Button>
  )
}

export default HelpButton
