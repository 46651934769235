import {
  UserType,
  VendorProductStatus,
  MarketVendorProductWithActiveDealFieldsFragment,
  Maybe,
} from '@vori/gql-market'

export const displayCurrency = (n = 0): string => {
  const format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return format.format(n / 100)
}

export const isNonEmptyString = (input: string): boolean => {
  return input ? typeof input === 'string' && input.length !== 0 : false
}

export function pluralize(noun: string, count: number): string {
  return count === 1 ? noun : `${noun}s`
}

export const toShortDate = (date: string): string =>
  date
    ? new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(new Date(date))
    : date

export const toShortDateWithoutYear = (date: string): string =>
  date
    ? new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
      }).format(new Date(date))
    : date

export type LocalProduct = {
  id: string
  brandName: string | null
  category: string | null
  description: string | null
  imgURL: string | null
  unitVolume: string | null
  unitUPC: string | null
}

type LocalVendorVendor = {
  id: string
  name: string
}

type WholeSalePricing = {
  casePrice: number | string
  unitPrice: number | string
}

type LocalVendorProduct = {
  vendorID: string
  vendorName: string
  id: string
  caseSize: number | string
  itemCode: string
  wholesalePricing: WholeSalePricing
  sizing: string | number
  notes: string
  inventory: number | null
  product: LocalProduct
  vendor: LocalVendorVendor
}
export const algoliaVendorProductToGQL = (
  algoliaVendorProduct: Record<string, string>,
): LocalVendorProduct => {
  const product: LocalProduct = {
    id: algoliaVendorProduct.objectID,
    brandName: algoliaVendorProduct.brandName,
    category: algoliaVendorProduct.category,
    description: algoliaVendorProduct.description,
    imgURL: algoliaVendorProduct.imgURL,
    unitVolume: algoliaVendorProduct.unitVolume,
    unitUPC: algoliaVendorProduct.unitUPC,
  }

  const vendorProduct = {
    vendorID: algoliaVendorProduct.vendorID,
    vendorName: algoliaVendorProduct.vendorName,
    id: algoliaVendorProduct.objectID,
    caseSize: algoliaVendorProduct.caseSize || 0,
    itemCode: algoliaVendorProduct.itemCode,
    wholesalePricing: {
      casePrice: algoliaVendorProduct.wholesaleCasePrice || 0,
      unitPrice: algoliaVendorProduct.wholesaleUnitPrice || 0,
    },
    sizing: algoliaVendorProduct.sizing,
    notes: algoliaVendorProduct.notes,
    inventory: parseFloat(String(algoliaVendorProduct.inventory)) || null,
    product,
    vendor: {
      id: algoliaVendorProduct.vendorID,
      name: algoliaVendorProduct.vendorName,
    },
  }

  return vendorProduct
}

export type GqlVendorProductToAlgolia = {
  objectID?: string
  brandName?: string
  category?: string
  description?: string
  globalProductID?: string
  wholesaleUnitPrice?: number
  wholesaleCasePrice?: string
  itemCode?: string
  vendorID?: string
  caseSize: Maybe<number> | string | null
  imgURL: string | null
  notes: string | null
  unitVolume: string | null
  vendorName: string | null
  unitUPC: string
  inventory: number | null
  status?: VendorProductStatus
  project19Featured?: boolean
}

export const gqlVendorProductToAlgolia = (
  gqlVendorProduct: Partial<
    Omit<
      MarketVendorProductWithActiveDealFieldsFragment,
      keyof { caseSize?: string | null | number }
    > & {
      caseSize?: string | null | number
    }
  >,
): GqlVendorProductToAlgolia => ({
  brandName: gqlVendorProduct?.product?.brandName?.trim(),
  category: gqlVendorProduct?.product?.category?.trim(),
  description: gqlVendorProduct?.product?.description?.trim(),
  imgURL: gqlVendorProduct?.product?.imgURL ?? null,
  notes: gqlVendorProduct.notes || null,
  unitVolume: gqlVendorProduct?.product?.unitVolume || null,
  unitUPC: gqlVendorProduct?.product?.unitUPC?.replace(/[^0-9]+/g, '') || '',
  globalProductID: gqlVendorProduct?.product?.id,
  wholesaleUnitPrice: gqlVendorProduct?.wholesalePricing?.unitPrice || 0,
  wholesaleCasePrice: String(
    gqlVendorProduct?.wholesalePricing?.casePrice || 0,
  ),
  caseSize:
    (gqlVendorProduct.caseSize && String(gqlVendorProduct.caseSize)) || null,
  objectID: gqlVendorProduct.id,
  itemCode: gqlVendorProduct?.itemCode?.replace(/\s/g, ''),
  vendorName: gqlVendorProduct.vendor?.name || null,
  vendorID: gqlVendorProduct.vendor?.id,
  inventory: gqlVendorProduct.inventory || 0,
})

export function isVendorUser(userType: UserType): boolean {
  return userType === 'SALES_REP' || userType === 'VENDOR_ADMIN'
}

export function ensureNumericPrice(price?: string | number | null): number {
  const number =
    typeof price === 'number'
      ? price
      : typeof price === 'string'
        ? Number(price.replace(/(?!\.)[\D]/g, ''))
        : 0

  return Number.isNaN(number) ? 0 : number
}

export function getMaxQuantity(maxQuantity: number): number {
  const newMaxQuantity =
    maxQuantity == null ? Number.POSITIVE_INFINITY : maxQuantity
  return newMaxQuantity
}

export const isIOS = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

export const setLocalStorage = (
  key: string,
  data:
    | Record<string, string | number | boolean | null>
    | string
    | number
    | boolean
    | null,
): void => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const removeFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}

export const getLocalStorage = (
  key: string,
): Record<string, string | number | boolean | null> | null => {
  const data: string | null = localStorage.getItem(key)

  if (!data) {
    return null
  }

  try {
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
    return null
  } catch {
    return null
  }
}
