import React from 'react'

import { MarketGetCartsQuery } from '@vori/gql-market'

import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import VendorCartSummary from './VendorCartSummary'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  cardContent: {
    paddingBottom: theme.spacing(2),
  },
  sectionHeadline: {
    marginTop: 0,
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(
      3,
    )}px`,
  },
  tabs: {
    // workaround to target tab indicator, which uses dynamic class names
    '& > div.MuiTabs-fixed > span': {
      width: 3,
      marginTop: theme.spacing(2),
      maxHeight: 72 /* default */ - theme.spacing(2),
    },
    // Overwrite default opacity for non-selected tabs
    '& .MuiTab-textColorInherit': {
      opacity: 0.5,
      '&.Mui-selected': {
        opacity: 1,
      },
    },
  },
  tab: {
    padding: 0,
    minHeight: 72,
    '&+&': {
      marginTop: theme.spacing(2),
    },
  },
}))

type Props = {
  carts: NonNullable<MarketGetCartsQuery['me']>['user']['carts']
  handleOnSelectVendor: (vendorID: string) => void
  selectedVendorSlug: string
}

const CheckoutSideNav = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { carts, handleOnSelectVendor, selectedVendorSlug } = props
  return (
    <div className={classes.cardContent}>
      <h2 className={classes.sectionHeadline}>Your Shopping Carts</h2>

      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={selectedVendorSlug}
        onChange={(_, slugOrID) => {
          handleOnSelectVendor(slugOrID)
        }}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        indicatorColor="primary"
      >
        {carts.map((cart) => (
          <Tab
            className={classes.tab}
            key={cart.id}
            value={cart.vendor.slug || cart.vendor.id}
            label={<VendorCartSummary cart={cart} />}
          />
        ))}
      </Tabs>
    </div>
  )
}

export default CheckoutSideNav
