import React, { useState } from 'react'
import { match } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'

import { generatePath, Route, useRouteMatch } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import Toolbar from '@material-ui/core/Toolbar'

import { useMarketGetVendorDetailsBySlugOrIdQuery } from '@vori/gql-market'

import CartButton from '../basket/CartButton'
import CartOverlay from '../basket/CartOverlay'
import HelpButton from './HelpButton'
import MobileMenu from './MobileMenu'
import SearchBox from './SearchBox'
import UserBlock from './UserBlock'
import VoriLogo from './VoriLogo'

import { useIsMounted } from '../../helpers/hooks'
import { VENDOR_HOME } from '../../constants/routes'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  header: {
    boxShadow: '0px 2px 15px rgba(84, 83, 83, 0.07)',

    [theme.breakpoints.only('xs')]: {
      borderBottom: `1px solid ${theme.colors.GRAY_1}`,
      boxShadow: 'none',
    },
  },

  toolbar: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },

  toolbarDesktop: {
    display: 'flex',
    flexDirection: 'column',
  },

  toolbarSparse: {
    paddingBottom: theme.spacing(2),
  },
  toolbarSparseMobile: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },

  toolbarRowDesktop: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },

  toolbarRowMobile: {
    alignItems: 'stretch',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: 50,
    padding: 0,
  },

  toolbarTopRowMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 50,
  },

  toolbarRowMobileLeft: {
    alignItems: 'center',
    display: 'flex',
    minHeight: 'auto',
  },

  toolbarMobileLogo: {
    marginRight: theme.spacing(2),
  },

  toolbarMobileCenter: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '60%',
  },

  toolbarRowMobileRightButton: {
    minWidth: 'auto',
    padding: theme.spacing(1),
  },

  row: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.only('xs')]: {
      alignItems: 'stretch',
    },
  },

  toolbarRowDesktopSparse: {
    justifyContent: 'space-between',
  },

  searchArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  hidden: {
    maxWidth: 0,
    visibility: 'hidden',
  },

  mobileSearchBox: {
    alignItems: 'center',
    flex: 1,
    marginLeft: '6%',
  },

  vendorSelectorContainer: {
    position: 'relative',
    width: 30,
  },
}))

export const SparseNavigation = (props: { isMobile: boolean }): JSX.Element => {
  const classes = useStyles(props)
  const { isMobile } = props
  return (
    <AppBar className={classes.header} position="static" elevation={0}>
      <Toolbar
        className={clsx(classes.toolbar, {
          [classes.toolbarSparse]: !isMobile,
          [classes.toolbarSparseMobile]: isMobile,
        })}
      >
        <Container maxWidth="xl">
          <div className={classes.toolbarRowDesktop}>
            <VoriLogo />
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

type Props = {
  isMobile?: boolean
  HeaderComponent?: React.ElementType
}

const Navigation = (props: Props): JSX.Element => {
  const [basketShown, setBasketShown] = useState(false)
  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false)
  const [mobileMenuShown, setMobileMenuShown] = useState(false)
  const { HeaderComponent, isMobile } = props
  const classes = useStyles(props)
  const vendorRoute: match<{ vendor?: string } | undefined> | null =
    useRouteMatch(VENDOR_HOME)
  const isMounted = useIsMounted()

  const { data } = useMarketGetVendorDetailsBySlugOrIdQuery({
    skip: !vendorRoute?.params?.vendor || !isMounted,
    variables: { slugOrID: vendorRoute?.params?.vendor || '' },
  })

  const vendorID = data?.vendorBySlug?.id || ''

  const vendorSearchURL =
    vendorID && vendorRoute
      ? generatePath(VENDOR_HOME, {
          vendor: vendorRoute?.params?.vendor as string,
          activeTab: 'catalog',
        })
      : ''

  return (
    <>
      <CartOverlay
        onShownChange={setBasketShown}
        shown={basketShown}
        vendorID={vendorID}
      />

      <AppBar className={classes.header} position="fixed" elevation={0}>
        {isMobile ? (
          <Toolbar className={classes.toolbarRowMobile}>
            <div className={classes.toolbarTopRowMobile}>
              <div className={classes.toolbarRowMobileLeft}>
                <IconButton onClick={() => setMobileMenuShown(true)}>
                  <MenuIcon />
                </IconButton>

                <MobileMenu
                  shown={mobileMenuShown}
                  onClose={() => setMobileMenuShown(false)}
                />

                {!isMobileSearchActive && (
                  <VoriLogo className={classes.toolbarMobileLogo} mobile />
                )}

                <div
                  className={classes.vendorSelectorContainer}
                  data-portal="header-vendor-selector"
                />
              </div>

              <div className={classes.toolbarMobileCenter}>
                <div
                  className={clsx(classes.mobileSearchBox, {
                    [classes.hidden]: !isMobileSearchActive,
                  })}
                >
                  <SearchBox mobile vendorSearchURL={vendorSearchURL} />
                </div>
              </div>

              {isMobileSearchActive ? (
                <Button onClick={() => setIsMobileSearchActive(false)}>
                  Cancel
                </Button>
              ) : (
                <div className={classes.row}>
                  <Route path={VENDOR_HOME}>
                    <IconButton
                      className={classes.toolbarRowMobileRightButton}
                      onClick={() => setIsMobileSearchActive(true)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Route>
                  <CartButton
                    className={classes.toolbarRowMobileRightButton}
                    mobile
                    onClick={() => setBasketShown(true)}
                    vendorID={vendorID}
                  />
                  <HelpButton />
                </div>
              )}
            </div>

            {HeaderComponent && <HeaderComponent />}
          </Toolbar>
        ) : (
          <Toolbar className={classes.toolbar}>
            <Container maxWidth="xl">
              <div className={classes.toolbarDesktop}>
                <div className={classes.toolbarRowDesktop}>
                  <VoriLogo />
                  <div className={classes.searchArea}>
                    <Route path={VENDOR_HOME}>
                      <SearchBox vendorSearchURL={vendorSearchURL} />
                    </Route>
                  </div>
                  <UserBlock />
                  <CartButton
                    vendorID={vendorID}
                    onClick={() => setBasketShown(true)}
                  />
                  <HelpButton />
                </div>
                {HeaderComponent && <HeaderComponent />}
              </div>
            </Container>
          </Toolbar>
        )}
      </AppBar>
    </>
  )
}
export default Navigation
