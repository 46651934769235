import React, { useEffect, useState } from 'react'

import { ENV_GAE_REST_ENDPOINT } from '@vori/market-env'

import { apolloClient } from '../graphql'
import { REDIRECT } from '../constants'
import { setLocalStorage } from '../helpers/utils'
import { SIGN_IN } from '../constants/routes'
import firebase from '../firebase/firebase'

type AuthState = {
  isAuthenticating: boolean
  isAuthenticated: boolean
  user: firebase.User | null
  errorMessage: string | null
  isCreatingInvite?: boolean
}

type Credentials = {
  email: string
  password: string
}
interface SignUpProps extends Credentials {
  displayName: string
  inviteCode: string
}

export type AuthProps = {
  auth: AuthState
  signOut: () => Promise<void>
  signIn: (email: string, password: string) => Promise<void>
  signUp: (
    credentials: Credentials,
  ) => Promise<firebase.auth.UserCredential | null>
  signUpFromInvite: (signUpProps: SignUpProps) => Promise<boolean>
  sendPasswordReset: (email: string) => Promise<void>
}

const nullState: AuthState = {
  isAuthenticating: true,
  isAuthenticated: false,
  user: null,
  errorMessage: null,
}

const initialState = {
  auth: nullState,
  signOut: () => new Promise<void>(() => null),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signIn: (email: string, password: string) => new Promise<void>(() => null),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signUp: (credentials: Credentials) =>
    new Promise<firebase.auth.UserCredential | null>(() => null),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signUpFromInvite: (signUpProps: SignUpProps) =>
    new Promise<boolean>(() => null),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendPasswordReset: (email: string) => new Promise<void>(() => null),
}

export const AuthContext = React.createContext(initialState)

type ErrorMap = {
  [key: string]: string | number
}

const errorMap: ErrorMap = {
  'auth/network-request-failed': 'Network error has occured. Please try again.',
  'auth/email-already-in-use':
    'Email is already in use. Please use another email',
  'auth/wrong-password': 'Incorrect email or password',
  'auth/user-not-found': 'Incorrect email or password',
  'auth/reset-password-error':
    'Failed to send password reset email. Did you type your email correctly?',
  'auth/quota-exceeded': 'Too many login attempts, please wait.',
}

export function useAuth(): AuthProps {
  const [isCreatingInvite, setIsCreatingInvite] = useState(false)
  const [auth, setAuth] = useState(nullState)
  const resetUser = async () => {
    setAuth({
      isAuthenticating: false,
      isAuthenticated: false,
      user: null,
      errorMessage: null,
    })
    setIsCreatingInvite(false)
    await apolloClient.clearStore()
  }
  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setAuth({
          isAuthenticating: false,
          isAuthenticated: true,
          user: authUser,
          errorMessage: null,
        })
      } else {
        await resetUser()
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])
  const signOut = async (): Promise<void> => {
    await firebase.signOut()
    await resetUser()
    setLocalStorage(REDIRECT, { shouldRedirect: false })
    window.location.assign(SIGN_IN)
  }
  const signIn = async (email: string, password: string): Promise<void> => {
    await resetUser()
    setAuth(nullState)
    try {
      await firebase.signIn(email, password)
    } catch (e: unknown) {
      const errorMessage =
        (errorMap[(e as { code: string }).code] as string) ||
        'Something went wrong'
      setAuth({
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        errorMessage,
      })
    }
  }

  const signUp = async ({
    email,
    password,
  }: Credentials): Promise<firebase.auth.UserCredential | null> => {
    try {
      const userRef = await firebase.createAccount(email, password)
      return userRef
    } catch (e) {
      const errorMessage =
        (errorMap[(e as { code: string }).code] as string) ||
        'Something went wrong'
      setAuth({
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        errorMessage,
      })
      return null
    }
  }

  const signUpFromInvite = async ({
    displayName,
    email,
    password,
    inviteCode,
  }: SignUpProps): Promise<boolean> => {
    setIsCreatingInvite(true)
    try {
      const userRef = await firebase.createAccount(email, password)

      const newUserIdToken = (await userRef?.user?.getIdToken()) || ''
      const rawResult = await fetch(
        `${ENV_GAE_REST_ENDPOINT}/create-user-from-store-invite?userIdToken=${encodeURIComponent(
          newUserIdToken,
        )}&invitationCode=${encodeURIComponent(
          inviteCode,
        )}&displayName=${encodeURIComponent(displayName)}`,
        { method: 'POST' },
      )
      const result = await rawResult.text()
      // eslint-disable-next-line no-console
      console.log(`create-user-from-store-invite: ${result}`)
      setIsCreatingInvite(false)
      return true
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Error creating user from invite: ${e}`)
      const errorMessage =
        (errorMap[(e as { code: string }).code] as string) ||
        'Something went wrong'
      setAuth({
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        errorMessage,
      })
      setIsCreatingInvite(false)
      return false
    }
  }

  const passwordResetErrorMap: ErrorMap = {
    'auth/user-not-found': 'Could not find user with this email',
  }
  const sendPasswordReset = async (email: string): Promise<void> => {
    try {
      return await firebase.passwordReset(email)
    } catch (e: unknown) {
      const errorMessage =
        (passwordResetErrorMap[(e as { code: string }).code] as string) ||
        'Something went wrong'
      throw errorMessage
    }
  }

  return {
    // When creating a user from invite,
    // pretend to continue authentication after the firebase auth user has been created,
    // but while we're still waiting on the userConfig to be created.
    // This prevents top-level GraphQL queries from loading before they are able to be resolved (since a userConfig must exist for GraphQL to work).
    auth: {
      ...auth,
      isCreatingInvite,
      isAuthenticated: isCreatingInvite ? true : auth.isAuthenticated,
    },
    signOut,
    signIn,
    signUp,
    signUpFromInvite,
    sendPasswordReset,
  }
}
