import React from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '16em',
  },
}))

const VendorNotFound = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={classes.container}>
            <Typography variant="h6">No Active Vendor found</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={history.goBack}
            >
              Go back
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default VendorNotFound
