import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LockIcon from '@material-ui/icons/Lock'
import MuiAlert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { gqlVendorProductToAlgolia, pluralize } from '../../helpers/utils'
import { CustomTheme } from '../../theme'
import OrderHistory from '../order-guides/OrderHistory'
import ReorderButton from '../ui/ReorderButton'
import SubtleCard from '../ui/SubtleCard'
import ActiveDealTag from './ActiveDealTag'
import AddToCartButtonInput from './AddToCartButtonInput'
import { ProductCardProps, ProductOrderHistory } from './helpers/types'
import Price from './Price'
import ProductDetailModal from './ProductDetailModal'
import ProductTags from './ProductTags'
import SizeDetails from './SizeDetails'
import { Star01Icon } from '@vori/gourmet-icons'

export const styles = makeStyles((theme: CustomTheme) => ({
  card: {
    boxShadow: '0px 10px 30px rgba(134, 134, 134, 0.1)',
    height: '100%',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      transform: 'translateY(-3px) scale(1.005) translateZ(0)',
    },
    padding: theme.spacing(2),
  },

  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },

  cardWithOrderHistory: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.only('xs')]: {
      justifyContent: 'space-between',
    },
  },

  cardContentOutOfStock: {
    opacity: 0.75,
    filter: 'grayscale(0.55)',
  },

  mainContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  mainContentWithOrderHistory: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'row',
      marginRight: theme.spacing(2),
    },
  },

  cardRow: {
    display: 'flex',
    marginTop: theme.spacing(),

    '& mark': {
      // Highlighted text matching search string
      backgroundColor: theme.colors.VORI_YELLOW,
      borderRadius: 2,
    },
  },

  cardRowBlock: {
    marginTop: theme.spacing(),
    width: '100%',

    '& mark': {
      // Highlighted text matching search string
      backgroundColor: theme.colors.VORI_YELLOW,
      borderRadius: 2,
    },
  },

  topRow: {
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [theme.breakpoints.only('xs')]: {
      marginBottom: 0,
    },
  },

  inStockBadge: {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    borderRadius: 6,

    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, 1, 0, 0),
    },
  },

  inStockBadgeGreen: {
    color: theme.colors.BADGE_GREEN_TEXT,
  },

  inStockBadgeYellow: {
    color: theme.colors.BADGE_YELLOW_TEXT,
  },

  inStockBadgeRed: {
    color: theme.colors.BADGE_RED_TEXT,
  },

  bestsellerRow: {
    height: 24,
    marginTop: theme.spacing(2),

    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },

  bestsellerBadge: {
    backgroundColor: theme.colors.BADGE_YELLOW_BACKGROUND,
    color: theme.colors.BADGE_YELLOW_TEXT,
    padding: theme.spacing(0, 1),
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
  },

  bestsellerBadgeIcon: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(),
  },

  distributorName: {
    color: theme.colors.GRAY_3,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    flexShrink: 0,
    height: 150,
    minWidth: 150,
    marginBottom: theme.spacing(),

    [theme.breakpoints.only('xs')]: {
      height: 90,
    },
  },

  noImage: {
    backgroundColor: theme.colors.VORI_PURPLE_HSLA(0.02),
  },

  smallImage: {
    height: 90,
  },

  imageToLeft: {
    backgroundPosition: 'left center',
  },

  imageToLeftWithOrderHistory: {
    [theme.breakpoints.only('xs')]: {
      width: '25%',
      marginRight: theme.spacing(),
    },
  },

  contentAfterImage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
  },

  descriptionRow: {
    flexDirection: 'column',
  },

  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },

  actionRow: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },

  priceHidden: {
    alignItems: 'center',
    color: theme.colors.GRAY_5,
    display: 'flex',
  },

  priceHiddenIcon: {
    marginRight: theme.spacing(1),
  },

  orderHistory: {
    padding: theme.spacing(0, 5),
    width: '65%',
  },

  addToCartBtnWithOrderHistory: {
    padding: 0,
  },

  sectionContainer: {
    width: '100%',
    '& h3': {
      fontSize: 24,
      marginBottom: 16,
    },
    [theme.breakpoints.down('xs')]: {
      '& h3': {
        fontSize: 18,
      },
    },
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.GRAY_0,
    marginBottom: 16,
  },
}))

function ProductCard(props: ProductCardProps): JSX.Element {
  const classes = styles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const [mobileOrderHistoryIsOpen, setMobileOrderHistoryVisibility] =
    useState(false)

  const {
    activeDeal,
    basketProps,
    className,
    fromGQL,
    isLoadingOrderHistory,
    orderHistory,
    showDistributor,
    showPrice,
    showPriceDifference,
    stockIndicator,
  } = props

  const productData = fromGQL
    ? gqlVendorProductToAlgolia(props.productData)
    : props.productData

  const {
    brandName,
    caseSize,
    description,
    vendorName,
    imgURL,
    itemCode,
    project19Featured: isBestseller,
    unitVolume,
  } = productData

  const hasActiveDeal = activeDeal != null
  // Disable since this value is not working correctly
  const showInStock = false //props.showInStock && basketProps.canShowInStock
  const hasOrderHistory = orderHistory != null

  const onReorderClick = useCallback(
    ({ quantity }) => {
      if (orderHistory != null && basketProps.onQuantityChange != null) {
        basketProps.onQuantityChange(basketProps.quantity + quantity)
      }
    },
    [orderHistory, basketProps],
  )

  const [alert, setAlert] = useState({ open: false, message: '' })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleAlert = (
    _: React.SyntheticEvent<Element, Event> | null,
    message?: string,
  ) => setAlert({ open: !alert.open, message: message || alert.message })

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={toggleAlert}
      >
        <MuiAlert onClose={toggleAlert} severity="success">
          {alert.message}
        </MuiAlert>
      </Snackbar>
      <SubtleCard
        role="button"
        onClick={toggleModal}
        className={clsx(classes.card, className, {
          [classes.cardContentOutOfStock]: showInStock && basketProps.disabled,
        })}
      >
        <div
          className={clsx(classes.cardInner, {
            [classes.cardWithOrderHistory]: hasOrderHistory,
          })}
        >
          {!hasOrderHistory && (showInStock || showDistributor) && (
            <div className={clsx(classes.cardRow, classes.topRow)}>
              <div
                className={clsx(
                  classes.inStockBadge,
                  stockIndicator === 'red'
                    ? classes.inStockBadgeRed
                    : stockIndicator === 'yellow'
                      ? classes.inStockBadgeYellow
                      : classes.inStockBadgeGreen,
                )}
              >
                {showInStock
                  ? basketProps.outOfStock
                    ? 'Out of Stock'
                    : `${basketProps.inStockCount} ${pluralize(
                        'case',
                        basketProps.inStockCount || 0,
                      )} in stock`
                  : '\u200B'}
              </div>

              {showDistributor && (
                <div className={classes.distributorName}>{vendorName}</div>
              )}
            </div>
          )}

          <div
            className={clsx(classes.mainContent, {
              [classes.mainContentWithOrderHistory]: hasOrderHistory,
            })}
          >
            <div
              className={clsx(classes.cardRow, classes.image, {
                [classes.noImage]: !imgURL,
                [classes.smallImage]: hasOrderHistory,
                [classes.imageToLeft]: hasOrderHistory,
                [classes.imageToLeftWithOrderHistory]: hasOrderHistory,
              })}
              style={imgURL ? { backgroundImage: `url(${imgURL})` } : undefined}
            ></div>

            {isModalOpen && (
              <ProductDetailModal
                isModalOpen
                productData={productData}
                basketProps={basketProps}
                toggleAlert={toggleAlert}
                toggleModal={toggleModal}
              />
            )}

            {hasOrderHistory && (showInStock || showDistributor) && (
              <div className={clsx(classes.cardRow, classes.topRow)}>
                <div
                  className={clsx(
                    classes.inStockBadge,
                    stockIndicator === 'red'
                      ? classes.inStockBadgeRed
                      : stockIndicator === 'yellow'
                        ? classes.inStockBadgeYellow
                        : classes.inStockBadgeGreen,
                  )}
                >
                  {basketProps.disabled && showInStock
                    ? basketProps.outOfStock
                      ? 'Out of Stock'
                      : `${basketProps.inStockCount} ${pluralize(
                          'case',
                          basketProps.inStockCount || 0,
                        )} in stock`
                    : '\u200B'}
                </div>

                {showDistributor && (
                  <div className={classes.distributorName}>{vendorName}</div>
                )}
              </div>
            )}

            <div className={classes.contentAfterImage}>
              {hasActiveDeal && <ActiveDealTag activeDeal={activeDeal} />}
              {isBestseller && (
                <div
                  className={clsx({
                    [classes.cardRow]: !hasOrderHistory,
                    [classes.bestsellerRow]: !hasOrderHistory,
                  })}
                >
                  <div className={classes.bestsellerBadge}>
                    <Star01Icon variant="progress" /> Bestseller
                  </div>
                </div>
              )}
              <ProductTags
                itemCode={itemCode as string}
                productData={productData}
                brand={brandName}
              />
              <div className={clsx(classes.cardRow, classes.descriptionRow)}>
                <div className={classes.description} title={description}>
                  {description}
                </div>

                <SizeDetails
                  caseSize={caseSize as string}
                  unitVolume={unitVolume as string}
                />
              </div>
              <div className={clsx(classes.cardRow, classes.actionRow)}>
                {showPrice ? (
                  <>
                    <Price
                      activeDeal={activeDeal}
                      compact={hasOrderHistory}
                      productData={productData}
                      showPriceDifference={showPriceDifference}
                    />
                    {!hasOrderHistory && (
                      <AddToCartButtonInput {...basketProps} />
                    )}
                  </>
                ) : (
                  <div className={classes.priceHidden}>
                    <LockIcon className={classes.priceHiddenIcon} />
                    {`Current ${vendorName} customers can see price`}
                  </div>
                )}
              </div>
            </div>
          </div>

          {!isMobile && (isLoadingOrderHistory || hasOrderHistory) && (
            <div className={classes.orderHistory}>
              <OrderHistory
                isLoading={isLoadingOrderHistory as boolean}
                onReorderClick={onReorderClick}
                orders={orderHistory as ProductOrderHistory[]}
              />
            </div>
          )}

          {hasOrderHistory && <AddToCartButtonInput {...basketProps} />}
        </div>

        {isMobile && hasOrderHistory && (
          <div className={classes.cardRowBlock}>
            <Button
              fullWidth
              endIcon={
                mobileOrderHistoryIsOpen ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )
              }
              onClick={(e) => {
                e.stopPropagation()
                setMobileOrderHistoryVisibility((prevState) => !prevState)
              }}
            >
              <Typography variant="caption">
                {mobileOrderHistoryIsOpen ? 'HIDE' : 'VIEW'} HISTORY
              </Typography>
            </Button>

            {mobileOrderHistoryIsOpen && (
              <Grid className={classes.cardRowBlock} container spacing={1}>
                {orderHistory.map((orderLineItem) => (
                  <Grid
                    key={`order-line-item-${orderLineItem.orderID}`}
                    item
                    xs={6}
                    sm={3}
                  >
                    <ReorderButton
                      {...orderLineItem}
                      onReorderClick={onReorderClick}
                    />
                  </Grid>
                ))}

                {!orderHistory.length && (
                  <Grid item xs={12}>
                    <Typography align="center" color="textSecondary">
                      No order history available
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        )}
      </SubtleCard>
    </>
  )
}

ProductCard.defaultProps = {
  basketProps: {
    quantity: 0,
  },
  isLoadingOrderHistory: false,
  productData: {},
  showPrice: true,
}

export default ProductCard
