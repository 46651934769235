import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { compareAsc, format } from 'date-fns'

import {
  MarketGetCartsQuery,
  useMarketGetCurrentUserQuery,
} from '@vori/gql-market'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import MuiPhoneInput from 'material-ui-phone-number'
import isMobilePhone from 'validator/es/lib/isMobilePhone'

import {
  ClockIcon,
  UserCheck01Icon,
  MarkerPin04Icon,
} from '@vori/gourmet-icons'

import { displayCurrency, isNonEmptyString } from '../../helpers/utils'

import ShippingDetailCollapsiblePane from './ShippingDetailCollapsiblePane'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  contentPadding: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(
      3,
    )}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  placeOrderButton: {
    marginBottom: theme.spacing(1),
  },
  totalRow: {
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)}px 0`,
  },
  totalSaving: {
    color: theme.colors.GREEN,
  },
  totalHighlighted: {
    color: theme.colors.ERROR,
  },
  cartMinimumNotMetNotice: {
    borderRadius: 4,
    backgroundColor: theme.colors.WARNING,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    padding: '4px',
  },
  orderDetailPanels: {
    paddingBottom: theme.spacing(1),
  },
  detailContentTextField: {
    '&+&': {
      marginTop: theme.spacing(2),
    },
  },
  fieldSummaryError: {
    color: theme.colors.ERROR,
  },
}))

type ShippingInfoContainerProps = {
  cart: NonNullable<MarketGetCartsQuery['me']>['user']['carts'][number]
  disablePlaceOrder: boolean
  hasOutOfStock?: boolean
  onPlaceOrder: (shippingInfo: {
    storeID: string
    noteToVendor: string
    deliveryTimePreference: string
  }) => void
}

const ShippingInfoContainer = (
  props: ShippingInfoContainerProps,
): JSX.Element => {
  const { data, loading } = useMarketGetCurrentUserQuery()

  if (loading) {
    return <CircularProgress />
  }

  return (
    <ShippingInfo
      profile={{
        addressLine1:
          data?.me?.user.retailUser?.retailCompany.store?.address || '',
        email: data?.me?.user.email || '',
        fullname: data?.me?.user.displayName || '',
        mobile: data?.me?.user.phoneNumber || '',
        storeID: data?.me?.user.retailUser?.retailCompany.store?.id || '',
        storeName: data?.me?.user.retailUser?.retailCompany.store?.name || '',
      }}
      {...props}
    />
  )
}

interface ShippingInfoProps extends ShippingInfoContainerProps {
  profile: {
    mobile: string
    email: string
    storeID: string
    storeName: string
    addressLine1: string
    fullname: string
  }
}

const ShippingInfo = (props: ShippingInfoProps) => {
  const { cart, disablePlaceOrder, hasOutOfStock, onPlaceOrder, profile } =
    props
  const classes = useStyles()
  const [expandedPanel, setExpandedPanel] = useState<string | boolean>(false)
  const { mobile, email, storeName, storeID, addressLine1, fullname } = profile
  const DEFAULT_DELIVERY = 'As soon as possible'
  const currentDate = format(new Date(), 'yyyy-MM-dd')
  const [shippingInfo, setShippingInfo] = useState({
    mobile,
    email,
    storeID,
    storeName,
    addressLine1,
    fullname,
    noteToVendor: '',
    deliveryTimePreference: DEFAULT_DELIVERY,
  })

  useEffect(() => {
    if (cart?.id) {
      setExpandedPanel(false)
    }
  }, [cart])

  if (!cart) {
    return null
  }

  const {
    lineItemCount,
    subTotal,
    totalDiscount = 0,
    vendor: { orderMin, orderMinimums },
    store,
  } = cart

  const storeOrderMin =
    orderMinimums && orderMinimums.find(({ storeID }) => storeID === store.id)

  let orderMinCents = orderMin || 0

  if (storeOrderMin) {
    orderMinCents = storeOrderMin.orderMin
  }

  const orderMinimumMet = orderMinCents == null || subTotal >= orderMinCents

  const handleExpandedPanelChange =
    (panel: string) =>
    (_: React.ChangeEvent<Record<string, unknown>>, newExpanded: boolean) => {
      setExpandedPanel(newExpanded ? panel : false)
    }
  const { deliveryTimePreference, noteToVendor } = shippingInfo

  const addressLine1Valid = isNonEmptyString(shippingInfo.addressLine1)
  const mobileValid =
    isNonEmptyString(shippingInfo.mobile) &&
    isMobilePhone(shippingInfo.mobile, 'en-US')
  const isValid = addressLine1Valid
  const handlePlaceOrder = () => {
    if (!isValid) {
      return
    }
    onPlaceOrder(shippingInfo)
  }
  return (
    <div className={classes.container}>
      <div className={classes.contentPadding}>
        {hasOutOfStock && (
          <>
            <div className={classes.fieldSummaryError}>
              This vendor has disabled the ability to place orders with products
              that are currently out of stock. Please remove these products
              before placing this order.
            </div>
            <br />
          </>
        )}
        <Button
          className={classes.placeOrderButton}
          data-test-id="place-order-button"
          color="primary"
          disabled={!isValid || disablePlaceOrder || lineItemCount === 0}
          size="large"
          onClick={handlePlaceOrder}
          variant="contained"
        >
          Place Order
        </Button>
        {totalDiscount > 0 && (
          <>
            <div className={classes.totalRow}>
              <div>Subtotal</div>
              <div>{displayCurrency(subTotal)}</div>
            </div>
            <div className={classes.totalRow}>
              <div>Promotional Savings</div>
              <div className={classes.totalSaving}>
                {displayCurrency(totalDiscount)}
              </div>
            </div>
          </>
        )}
        <div className={classes.totalRow}>
          <div>Total</div>
          <div
            className={clsx({
              [classes.totalHighlighted]: !orderMinimumMet,
            })}
          >
            {displayCurrency(subTotal - totalDiscount)}
          </div>
        </div>
        {!orderMinimumMet && (
          <div className={classes.cartMinimumNotMetNotice}>
            Add{' '}
            {displayCurrency(
              Math.max(0, orderMinCents - subTotal - totalDiscount),
            )}{' '}
            to reach order minimum
          </div>
        )}
      </div>
      <Divider />
      <div className={classes.orderDetailPanels}>
        <ShippingDetailCollapsiblePane
          isExpanded={expandedPanel === 'panel1'}
          onExpandedChange={handleExpandedPanelChange('panel1')}
          IconComponent={() => <MarkerPin04Icon />}
          summaryTitle={
            addressLine1Valid ? (
              shippingInfo.addressLine1
            ) : (
              <div className={classes.fieldSummaryError}>
                Enter Delivery Address
              </div>
            )
          }
          summaryContent={
            <>
              <div>
                {noteToVendor.length > 0
                  ? noteToVendor
                  : 'No delivery instructions'}
              </div>
            </>
          }
          content={
            <>
              <TextField
                className={classes.detailContentTextField}
                autoFocus
                multiline
                rows={3}
                variant="outlined"
                onChange={(e) => {
                  setShippingInfo({
                    ...shippingInfo,
                    addressLine1: e.target.value,
                  })
                }}
                value={shippingInfo.addressLine1}
                placeholder="Shipping address"
                label="Shipping address"
              />
              <TextField
                className={classes.detailContentTextField}
                multiline
                rows={3}
                variant="outlined"
                onChange={(e) => {
                  setShippingInfo({
                    ...shippingInfo,
                    noteToVendor: e.target.value,
                  })
                }}
                value={shippingInfo.noteToVendor}
                placeholder="Delivery Instructions"
                label="Additional Notes and Delivery Instructions (Optional)"
              />
            </>
          }
        />
        <ShippingDetailCollapsiblePane
          isExpanded={expandedPanel === 'panel2'}
          onExpandedChange={handleExpandedPanelChange('panel2')}
          IconComponent={() => <ClockIcon />}
          summaryTitle="Delivery Date"
          summaryContent={
            <>
              <div>{deliveryTimePreference}</div>
            </>
          }
          content={
            <TextField
              className={classes.detailContentTextField}
              type="date"
              InputProps={{
                inputProps: {
                  min: currentDate,
                },
              }}
              autoFocus
              variant="outlined"
              onChange={(e) => {
                const dateCompare = compareAsc(
                  new Date(e.target.value),
                  new Date(currentDate),
                )

                // Prevent past dates
                if (
                  new Date(e.target.value).getFullYear() >=
                    new Date(currentDate).getFullYear() &&
                  dateCompare < 0
                ) {
                  return
                }

                setShippingInfo({
                  ...shippingInfo,
                  deliveryTimePreference: e.target.value
                    ? e.target.value
                    : DEFAULT_DELIVERY,
                })
              }}
              value={shippingInfo.deliveryTimePreference}
            />
          }
        />
        <ShippingDetailCollapsiblePane
          isExpanded={expandedPanel === 'panel3'}
          onExpandedChange={handleExpandedPanelChange('panel3')}
          IconComponent={() => <UserCheck01Icon />}
          summaryTitle={
            mobileValid ? (
              'Contact Info'
            ) : (
              <div className={classes.fieldSummaryError}>
                Enter a phone number
              </div>
            )
          }
          summaryContent={
            <>
              <div>{shippingInfo.fullname}</div>
              <div>{shippingInfo.mobile}</div>
              <div>{shippingInfo.email}</div>
            </>
          }
          content={
            <>
              <TextField
                className={classes.detailContentTextField}
                autoFocus
                variant="outlined"
                onChange={(e) => {
                  setShippingInfo({
                    ...shippingInfo,
                    fullname: e.target.value,
                  })
                }}
                value={shippingInfo.fullname}
                placeholder="Contact's Name"
                label="Contact's Name"
              />
              <MuiPhoneInput
                className={classes.detailContentTextField}
                variant="outlined"
                onChange={(newMobile) => {
                  setShippingInfo({
                    ...shippingInfo,
                    mobile: newMobile,
                  })
                }}
                defaultCountry="us"
                disableDropdown
                onlyCountries={['us']}
                error={!mobileValid}
                helperText={
                  !mobileValid && 'Please provide a valid phone number'
                }
                value={shippingInfo.mobile}
                placeholder="Contact's phone number"
                label="Contact's phone number"
              />
            </>
          }
        />
      </div>
    </div>
  )
}

export default ShippingInfoContainer
