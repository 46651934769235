import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { UserType } from '@vori/gql-market'

import { pluralize, isVendorUser } from '../../helpers/utils'
import { useOnClickOutside } from '../../helpers/hooks'
import { useCurrentUser } from '../../graphql/graphqlHooks'

import WithAddToCart from './WithAddToCart'
import { CustomTheme } from '../../theme'
import { WithAddToCartInputProps } from './helpers/types'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 40,
    minWidth: 60,

    [theme.breakpoints.only('xs')]: {
      minHeight: 30,
      minWidth: 'auto',
      paddingLeft: 0,
    },
  },

  addToCartButton: {
    borderRadius: 6,
    padding: 6,
    minWidth: 40,

    [theme.breakpoints.only('xs')]: {
      padding: 0,
      minWidth: 30,
    },
  },

  editForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',

    '& .MuiInputBase-formControl': {
      alignItems: 'stretch',
      height: '100%',
    },
  },

  quantityButtons: {
    borderLeft: `1px solid ${theme.colors.GRAY_1}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& .MuiButton-root': {
      margin: 0,

      '&:first-child': {
        borderBottom: `1px solid ${theme.colors.GRAY_1}`,
      },
    },
  },

  quantityButton: {
    color: theme.colors.GRAY_4,
    borderRadius: 0,
    minWidth: 0,
    padding: 0,
    width: 20,
    height: '100%',

    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },

  quantityInputWrapper: {
    border: `1px solid ${theme.colors.GRAY_1}`,
    borderRadius: 4,
    flexShrink: 0,
  },

  quantityInput: {
    textAlign: 'center',
    borderRadius: 4,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.WHITE,
    color: theme.colors.BLACK,
    fontSize: theme.typography.subtitle2.fontSize,
    height: '100%',
    minWidth: 30,
    maxWidth: 36,
    padding: theme.spacing(1, 0),

    // Disable native up/down buttons on number input
    '-moz-appearance': 'textfield',

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },

    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
  },

  quantityInputMobile: {
    backgroundColor: theme.colors.WHITE,
    border: '0 none',
    color: theme.colors.BLACK,
    fontSize: theme.typography.h4.fontSize,
    margin: theme.spacing(3, 'auto'),
    outline: '0 none',
    padding: theme.spacing(1, 0),
    textAlign: 'center',
    width: '100%',

    // Disable native up/down buttons on number input
    '-moz-appearance': 'textfield',

    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },

    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
  },

  modal: {
    padding: theme.spacing(2),
  },

  modalContainer: {
    backgroundColor: theme.colors.WHITE,
    borderRadius: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(2),
  },
}))

const AddToCartButtonInput = (
  props: WithAddToCartInputProps,
): JSX.Element | null => {
  const { data: user, loading: isLoadingUser } = useCurrentUser()
  const classes = useStyles(props)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const {
    className,
    disabled,
    isEditingQuantity: isEditing,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onEditingQuantityChange: setIsEditing = (value: boolean) => null,
    localQuantity = 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setLocalQuantity = (value: number) => null,
    maxQuantity = Number.POSITIVE_INFINITY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    incrementQuantity = (value: boolean) => null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    decrementQuantity = (value: boolean) => null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => null,
    quantity = 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onQuantityChange = (value: number) => null,
  } = props

  const [quantityInputContainer, setQuantityInputContainer] =
    useState<HTMLDivElement | null>(null)

  const showQuantity = Number.isInteger(localQuantity) && localQuantity > 0

  const cancel = () => {
    setLocalQuantity(quantity)
    setIsEditing(false)
  }

  const confirm = () => {
    if (onQuantityChange) {
      onQuantityChange(Math.min(maxQuantity, localQuantity))
    }

    setIsEditing(false)
  }

  const confirmWithQuantity = (newLocalQuantity: number) => {
    if (onQuantityChange) {
      onQuantityChange(Math.min(maxQuantity, newLocalQuantity))
    }

    setLocalQuantity(newLocalQuantity)
    setIsEditing(false)
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      cancel()
    } else if (event.key === 'Enter') {
      confirm()
    } else if (event.key === 'ArrowUp') {
      incrementQuantity(event.shiftKey)
      event.preventDefault()
    } else if (event.key === 'ArrowDown') {
      decrementQuantity(event.shiftKey)
      event.preventDefault()
    }
  }

  useOnClickOutside(quantityInputContainer, confirm)

  useEffect(() => {
    setLocalQuantity(quantity)
  }, [quantity, setLocalQuantity])

  const addButton = (
    <Button
      data-test-id="add-to-cart-button"
      className={clsx(classes.addToCartButton)}
      color="primary"
      disabled={disabled || isEditing}
      onClick={(e) => {
        e.stopPropagation()
        if (localQuantity === 0) {
          confirmWithQuantity(1)
        } else {
          setIsEditing(true)
        }
      }}
      size="large"
      variant={showQuantity ? 'contained' : 'outlined'}
    >
      {showQuantity ? localQuantity : <AddIcon />}
    </Button>
  )

  if (
    isLoadingUser ||
    !user ||
    isVendorUser(
      (user as { me: { user: { userType: UserType } } })?.me?.user?.userType,
    )
  ) {
    // Prevent Vendor users from adding items to cart / ordering from Market in general
    // in order to limit product complexity (sales reps can just use iOS)
    return null
  }

  return (
    <div className={clsx(className, classes.container)}>
      {(!isEditing || (isEditing && isMobile)) &&
        (disabled ? (
          addButton
        ) : (
          <Tooltip
            title={
              showQuantity
                ? `${localQuantity} ${pluralize('case', localQuantity)} in Cart`
                : 'Add to Cart'
            }
            placement="top"
          >
            {addButton}
          </Tooltip>
        ))}

      {isEditing && !isMobile && (
        <div className={classes.editForm}>
          <TextField
            ref={setQuantityInputContainer}
            className={classes.quantityInputWrapper}
            // props for native `input`
            inputProps={{
              className: classes.quantityInput,
              pattern: '[0-9]*', // force numerical keyboard on iOS
            }}
            // props for Underlying Material `Input`
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <div className={classes.quantityButtons}>
                  <Button
                    className={classes.quantityButton}
                    onClick={(event) => {
                      event.stopPropagation()
                      incrementQuantity(event.shiftKey)
                    }}
                    disabled={maxQuantity <= localQuantity}
                    variant="text"
                  >
                    <ArrowDropUpIcon />
                  </Button>
                  <Button
                    className={classes.quantityButton}
                    disabled={localQuantity <= 0}
                    onClick={(event) => {
                      event.stopPropagation()
                      decrementQuantity(event.shiftKey)
                    }}
                    variant="text"
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </div>
              ),
            }}
            autoFocus
            value={localQuantity}
            onKeyDown={onInputKeyDown}
            onChange={onInputChange}
            type="number"
          />
        </div>
      )}

      {isEditing && isMobile && (
        <Modal
          className={classes.modal}
          onBackdropClick={() => {
            setIsEditing(false)
          }}
          open={true}
        >
          <Container className={classes.modalContainer} maxWidth="xs">
            <Typography align="center" display="block" variant="subtitle1">
              Update Quantity
            </Typography>

            <input
              autoFocus
              className={classes.quantityInputMobile}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              pattern="[0-9]*"
              type="number"
              value={localQuantity}
            />

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={confirm}
                  variant="contained"
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={() => {
                    setIsEditing(false)
                  }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Modal>
      )}
    </div>
  )
}

export default WithAddToCart(AddToCartButtonInput)
