import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { ENV_PLAID_ENDPOINT } from '@vori/market-env'

import { PlaidConfig } from '../../config/AppConfig'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  plaidAccountContainer: {
    display: 'flex',
    flex: 1,
  },
  logo: {
    width: 48,
    height: 48,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-evenly',
    marginLeft: theme.spacing(2),
  },
  accountName: {
    color: theme.colors.GRAY_5,
  },
  accountMask: {
    fontWeight: 'bold',
  },
}))

async function getInstitution(institutionId: string) {
  try {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        public_key: PlaidConfig.publicKey,
        institution_id: institutionId,
        options: { include_optional_metadata: true },
      }),
      redirect: 'follow',
    }
    const response = await fetch(
      `${ENV_PLAID_ENDPOINT}/institutions/get_by_id`,
      requestOptions,
    )
    return response.json()
  } catch {
    return null
  }
}

const cache = new Map()
const requestsInFlight = new Map()
function getCachedInstitution(institutionId: string) {
  if (cache.has(institutionId)) {
    return Promise.resolve(cache.get(institutionId))
  }
  if (requestsInFlight.has(institutionId)) {
    return requestsInFlight.get(institutionId)
  }
  const resultPromise = getInstitution(institutionId).then((value) => {
    requestsInFlight.delete(institutionId)
    cache.set(institutionId, value)
    return value
  })
  requestsInFlight.set(institutionId, resultPromise)
  return resultPromise
}

type Props = {
  account?: {
    name: string
    mask: string
  }
  institution?: {
    institution_id: string
  }
}
const PlaidAccount = (props: Props): JSX.Element | null => {
  const classes = useStyles(props)
  const { account, institution } = props
  const [institutionDetails, setInstitutionDetails] = useState<{
    institution: { logo: string }
  } | null>(null)
  useEffect(() => {
    async function fetchInstitutionDetails() {
      const details = await getCachedInstitution(
        institution?.institution_id as string,
      )
      setInstitutionDetails(details)
    }
    fetchInstitutionDetails()
  }, [institution])
  if (account == null || institution == null) {
    // Should never happen
    return null
  }
  const logoUrl = institutionDetails?.institution?.logo
  const { name, mask } = account
  return (
    <div className={classes.plaidAccountContainer}>
      <div
        className={classes.logo}
        style={{
          backgroundImage:
            logoUrl == null ? '' : `url(data:image/png;base64,${logoUrl})`,
        }}
      />

      <div className={classes.textContent}>
        <div className={classes.accountName}>{name}</div>
        <div className={classes.accountMask}>····· {mask}</div>
      </div>
    </div>
  )
}

export default PlaidAccount
