/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import BodyBackground from '../../components/ui/BodyBackground'
import SubtleCard from '../../components/ui/SubtleCard'
import PlaidAccountControl from '../../components/ui/PlaidAccountControl'
import PlaidAccount from '../../components/ui/PlaidAccount'
import { Colors, CustomTheme } from '../../theme'
import { BankIcon } from '@vori/gourmet-icons'
import Tag from '../../components/ui/Tag'
import OnboardingStepper from './OnboardingStepper'

const useStyles = makeStyles((theme: CustomTheme) => ({
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  nextButton: {
    alignSelf: 'flex-end',
    minWidth: '10em',
    marginTop: theme.spacing(2),
  },
  accountInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bankIcon: { marginRight: theme.spacing(4) },
  circleIcon: {
    color: theme.colors.SUCCESS,
    marginLeft: theme.spacing(4),
  },
  successTag: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2),
  },
}))

export type PlaidAccountType = {
  metadata: {
    account: {
      name: string
      mask: string
    }
    institution: {
      institution_id: string
    }
  }
  token: string
}

const OnboardingPaymentInfo = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const [plaidResponse, setPlaidResponse] = useState<PlaidAccountType | null>(
    null,
  )
  const [savingAccount, setSavingAccount] = useState(false)

  throw new Error('not implemented: Get Profile')
  // TODO get profile via GQL
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const profile: { plaidAccount: PlaidAccountType | null } = {
    plaidAccount: null,
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (plaidResponse == null && profile.plaidAccount != null) {
      setPlaidResponse(profile.plaidAccount)
    }
  }, [profile, plaidResponse])
  const onPlaidResponseSuccess = async (newPlaidResponse: PlaidAccountType) => {
    setPlaidResponse(newPlaidResponse)
    setSavingAccount(true)
    // const updates = {
    //   plaidAccount: newPlaidResponse,
    // };
    // TODO call update Profile mutation
    throw new Error('not implemented: profile update')
    setSavingAccount(false)
  }
  const accountValid = plaidResponse != null
  return (
    <BodyBackground backgroundColor={Colors.OFFWHITE}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <OnboardingStepper activeStep={1} />
          </Grid>
          <Grid item xs={6}>
            <SubtleCard withPadding>
              <Typography variant="h5" className={classes.headline}>
                {accountValid
                  ? 'You’re all set. Let’s go!'
                  : 'Great! Let’s set up your payment method.'}
              </Typography>
              <div className={classes.field}>
                <SubtleCard withPadding>
                  {!accountValid && (
                    <PlaidAccountControl
                      onPlaidResponseSuccess={onPlaidResponseSuccess}
                    />
                  )}
                  {accountValid && (
                    <div className={classes.accountInfo}>
                      <BankIcon variant="secondary" />{' '}
                      <PlaidAccount
                        account={plaidResponse?.metadata?.account}
                        institution={plaidResponse?.metadata?.institution}
                      />
                      <CheckCircleOutlineIcon className={classes.circleIcon} />
                    </div>
                  )}
                </SubtleCard>
                {accountValid && (
                  <Tag
                    className={classes.successTag}
                    type={savingAccount ? 'default' : 'success'}
                    label={
                      savingAccount
                        ? 'Saving progress...'
                        : 'Bank account successfully added!'
                    }
                  />
                )}
              </div>
              <div className={classes.field}>
                <Button
                  className={classes.nextButton}
                  disabled={!accountValid || savingAccount}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    history.push('/')
                  }}
                >
                  {savingAccount ? 'Saving progress...' : 'Finish Setup'}
                </Button>
              </div>
            </SubtleCard>
          </Grid>
        </Grid>
      </Container>
    </BodyBackground>
  )
}

export default OnboardingPaymentInfo
