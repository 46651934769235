import React from 'react'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '@material-ui/core/Modal'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
}))

type Props = {
  isOpen: boolean
  handleOnClose?: () => void
}

const LoadingModal = (props: Props): JSX.Element => {
  const classes = useStyles(props)

  const { isOpen, handleOnClose } = props
  return (
    <Modal open={isOpen} onClick={handleOnClose}>
      <Container className={classes.container}>
        <CircularProgress color="inherit" />
      </Container>
    </Modal>
  )
}

export default LoadingModal
