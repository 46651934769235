import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { CustomTheme } from '../../theme'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.colors.VORI_PURPLE,
    borderRadius: 4,
    color: theme.colors.WHITE,
    display: 'inline-flex',
    maxWidth: '100%',
    padding: '2px 10px',
    textAlign: 'center',
  },

  innerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

type Props = {
  className?: string
  distributor?: string
  itemCode: string
}

function ItemCodeTag(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { className, distributor, itemCode } = props

  return (
    <div
      title={`${
        distributor == null ? '' : `${distributor} `
      }Item Code ${itemCode}`}
      className={clsx(classes.container, className)}
    >
      <span className={classes.innerText}>{itemCode}</span>
    </div>
  )
}

export default ItemCodeTag
