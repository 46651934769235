import React, { Component } from 'react'

class Boundary extends Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: unknown): void {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div>
          <h3>:( Something went wrong.</h3>
        </div>
      )
    }

    return this.props.children as JSX.Element
  }
}

export default Boundary
