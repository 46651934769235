import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'

import Cart from './Cart'

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

type Props = {
  shown: boolean
  vendorID: string
  onShownChange: (toggle: boolean) => void
}

const CartOverlay = (props: Props): JSX.Element => {
  const { shown, onShownChange, vendorID } = props
  const classes = useStyles(props)
  const handleClose = () => onShownChange(false)
  return (
    <Drawer
      PaperProps={{ className: classes.drawer }}
      anchor="right"
      open={shown}
      onClose={handleClose}
    >
      <Cart onClose={handleClose} vendorID={vendorID} />
    </Drawer>
  )
}

export default CartOverlay
