import React, { useCallback } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { CustomTheme } from '../../theme'
import { ProductOrderHistory } from '../product/helpers/types'

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.colors.GRAY_0,
    borderRadius: theme.spacing(),
    padding: theme.spacing(2),
  },

  flexItem: {
    alignItems: 'center',
    display: 'flex',
  },

  flexItemEnd: {
    justifyContent: 'flex-end',
  },

  headerItem: {
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(),
  },

  cellText: {
    fontWeight: 700,
    lineHeight: 1,
  },

  order: {
    backgroundColor: theme.colors.WHITE,
    borderRadius: theme.spacing(),
    marginBottom: theme.spacing(),
    padding: theme.spacing(),

    '&:last-child': {
      marginBottom: 0,
    },
  },

  historyLoader: {
    marginLeft: theme.spacing(),
  },
}))

type Props = {
  onReorderClick: (orderHistory: ProductOrderHistory) => void
  isLoading: boolean
  orders: ProductOrderHistory[]
}

function OrderHistory(props: Props): JSX.Element {
  const classes = useStyles(props)
  const { isLoading, onReorderClick, orders } = props

  const onReorderClickLocal = useCallback(
    (orderHistory) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (onReorderClick) {
        onReorderClick(orderHistory)
      }
    },
    [onReorderClick],
  )

  if (isLoading) {
    return (
      <Grid className={classes.container} container>
        <Grid item sm={12}>
          <Typography align="center" component="div" color="textSecondary">
            <CircularProgress
              className={classes.historyLoader}
              size="0.75rem"
            />{' '}
            Loading order history...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (!orders?.length) {
    return (
      <Grid className={classes.container} container>
        <Grid item sm={12}>
          <Typography align="center" color="textSecondary">
            No order history available
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid className={classes.container} container>
      <Grid container>
        <Grid
          className={clsx(classes.flexItem, classes.headerItem)}
          item
          sm={4}
        >
          <Typography color="textSecondary" variant="caption">
            Order Date
          </Typography>
        </Grid>
        <Grid
          className={clsx(classes.flexItem, classes.headerItem)}
          item
          sm={2}
        >
          <Typography color="textSecondary" variant="caption">
            QTY
          </Typography>
        </Grid>
        <Grid
          className={clsx(classes.flexItem, classes.headerItem)}
          item
          sm={2}
        >
          <Typography color="textSecondary" variant="caption">
            UOM
          </Typography>
        </Grid>
      </Grid>

      {orders.map(
        ({
          orderDateTime,
          orderID,
          quantity,
          unitOfMeasure,
          ...orderHistoryItem
        }) => (
          <Grid className={classes.order} container key={`order-${orderID}`}>
            <Grid className={clsx(classes.flexItem)} item sm={4}>
              <Typography className={classes.cellText} variant="subtitle2">
                {orderDateTime}
              </Typography>
            </Grid>
            <Grid className={clsx(classes.flexItem)} item sm={2}>
              <Typography className={classes.cellText} variant="subtitle2">
                {quantity}
              </Typography>
            </Grid>
            <Grid className={clsx(classes.flexItem)} item sm={2}>
              <Typography className={classes.cellText} variant="subtitle2">
                {unitOfMeasure}
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.flexItem, classes.flexItemEnd)}
              item
              sm={4}
            >
              <Button
                size="small"
                startIcon={<FlashOnIcon />}
                onClick={onReorderClickLocal({
                  orderDateTime,
                  orderID,
                  quantity,
                  unitOfMeasure,
                  ...orderHistoryItem,
                })}
                variant="outlined"
              >
                GET IT AGAIN
              </Button>
            </Grid>
          </Grid>
        ),
      )}
    </Grid>
  )
}

export default OrderHistory
