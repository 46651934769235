import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useMarketGetCurrentUserQuery } from '@vori/gql-market'

import { ChevronDownIcon } from '@vori/gourmet-icons'
import { CustomTheme } from '../../theme'
import { AuthContext } from '../../auth/AuthContext'

import { SIGN_IN, ACCOUNT } from '../../constants/routes'
import { startIntercomConversation } from '../../config/Intercom'

import { useIsMounted } from '../../helpers/hooks'

const useStyles = makeStyles((theme: CustomTheme) => ({
  userBlock: {
    marginRight: theme.spacing(4),
    display: 'flex',
  },
  signInBlock: {
    marginRight: theme.spacing(4),
    marginTop: 6, // align vertically with top row content
  },
  nameColumn: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  userName: {
    color: theme.colors.VORI_PURPLE,
  },
  chevron: {
    marginLeft: theme.spacing(1),
  },
}))

const UserBlock = (): JSX.Element | null => {
  const classes = useStyles()
  const history = useHistory()
  const {
    signOut,
    auth: { isAuthenticated },
  } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null)
  const isMounted = useIsMounted()
  const { data, loading } = useMarketGetCurrentUserQuery({ skip: !isMounted })

  if (data == null) {
    return loading ? null : (
      <MenuItem data-test-id="logout-button" onClick={signOut} id="sign-out">
        Sign Out
      </MenuItem>
    )
  }
  if (!isAuthenticated) {
    return (
      <div className={classes.signInBlock}>
        <Button onClick={() => history.push(SIGN_IN)}>Sign In</Button>
      </div>
    )
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  let displayName
  let storeName
  if (data != null) {
    displayName = data.me?.user.displayName || data.me?.user.email
    storeName = data.me?.user.retailUser?.retailCompany.name
  }
  return (
    <div className={classes.userBlock}>
      {data && (
        <div className={classes.nameColumn}>
          <div className={classes.userName}>
            <strong>{displayName}</strong>
          </div>
          {storeName && <div>{storeName}</div>}
        </div>
      )}
      <div>
        <IconButton
          data-test-id="user-menu-button"
          aria-controls="profile-menu"
          aria-haspopup="true"
          className={classes.chevron}
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
          }}
        >
          <ChevronDownIcon variant="default" />
        </IconButton>
        <Menu
          data-test-id="user-menu"
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          // `getContentAnchorEl` must be null in order to set `anchorOrigin`
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose()
              history.push(ACCOUNT)
            }}
          >
            Your Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose()
              startIntercomConversation('Please describe your issue here')
            }}
          >
            Get Support
          </MenuItem>
          <MenuItem onClick={signOut} id="sign-out">
            Sign Out
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default UserBlock
