import React from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '16em',
  },
}))

const PageNotFound = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Container maxWidth="lg">
      <div className={classes.container}>
        <Typography variant="h4">
          {"The page you are looking for doesn't exist."}
        </Typography>
        <Button variant="contained" color="primary" onClick={history.goBack}>
          Go back
        </Button>
      </div>
    </Container>
  )
}

export default PageNotFound
