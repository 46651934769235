import { useEffect, ReactNode } from 'react'

type Props = {
  backgroundColor: string
  children: ReactNode
}
const BodyBackground = ({ backgroundColor, children }: Props): JSX.Element => {
  useEffect(() => {
    const bodyStyle = document.body.style
    const previousBodyColor = bodyStyle.backgroundColor
    bodyStyle.backgroundColor = backgroundColor
    return () => {
      bodyStyle.backgroundColor = previousBodyColor
    }
  }, [backgroundColor])
  return children as JSX.Element
}

export default BodyBackground
