import React from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../theme'

import Tag from './Tag'
import VoriLogo from './VoriLogo'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  example: {
    padding: theme.spacing(4),
    boxShadow: `0 0 15px hsla(0, 0%, 0%, 0.1)`,
    borderRadius: 20,
    display: 'flex',
  },
}))

const Sections = [
  {
    title: 'Typography',
    content: () => (
      <div>
        <Typography variant="h1" component="h2">
          h1. Heading
        </Typography>
        <Typography variant="h2">h2. Heading</Typography>
        <Typography variant="h3">h3. Heading</Typography>
        <Typography variant="h4">h4. Heading</Typography>
        <Typography variant="h5">h5. Heading</Typography>
        <Typography variant="h6">h6. Heading</Typography>
        <Typography variant="subtitle1">
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2">
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1">
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2">
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block">
          button text
        </Typography>
        <Typography variant="caption" display="block">
          caption text
        </Typography>
        <Typography variant="overline" display="block">
          overline text
        </Typography>
      </div>
    ),
  },
  {
    title: 'Buttons',
    content: () => (
      <div>
        <h3>Overview</h3>
        <div>
          <Button variant="contained" color="primary">
            Contained, primary
          </Button>
          <Button variant="contained">Contained</Button>
          <Button variant="outlined" color="primary">
            Outlined, primary
          </Button>
          <Button variant="outlined">Outlined</Button>
          <Button>Regular</Button>
        </div>
        <h3>Sizes vs types</h3>
        <div>
          <Button size="small">small</Button>
          <Button>regular</Button>
          <Button size="large">large</Button>
        </div>
        <div>
          <Button variant="contained" size="small">
            small
          </Button>
          <Button variant="contained">regular</Button>
          <Button variant="contained" size="large">
            large
          </Button>
        </div>
      </div>
    ),
  },
  {
    title: 'Colors',
    content: () => (
      <div>
        <h3>Overview</h3>
        <div>Consume theme colors via theme.colors:</div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {Object.entries(Colors).map(([name, value], i) => (
            <div
              key={i}
              style={{
                backgroundColor: value as string,
                width: 100,
                height: 100,
                margin: 60,
              }}
            >
              {name}
            </div>
          ))}
        </div>
      </div>
    ),
  },
  {
    title: 'Checkbox',
    content: () => (
      <div>
        <div>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={true} onChange={() => null} />
            }
            label={'Checked checkbox, primary color'}
          />
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={false} onChange={() => null} />
            }
            label={'Unchecked checkbox, primary color'}
          />
        </div>
        <div>
          <FormControlLabel
            control={<Checkbox checked={true} onChange={() => null} />}
            label={'Checked checkbox, default color'}
          />
          <FormControlLabel
            control={<Checkbox checked={false} onChange={() => null} />}
            label={'Unchecked checkbox, default color'}
          />
        </div>
      </div>
    ),
  },
  {
    title: 'Chips',
    content: () => (
      <div>
        <div>
          <Chip
            label="Primary color"
            onDelete={() => {
              // specify this callback to show an (x)
            }}
            color="primary"
          />
          <Chip
            label="Default Color"
            onDelete={() => {
              // specify this callback to show an (x)
            }}
          />
        </div>
      </div>
    ),
  },
  {
    title: 'Tags',
    content: () => {
      return (
        <div>
          <Tag label="default" />
          <Tag label="success" type="success" />
        </div>
      )
    },
  },
  {
    title: 'VoriLogo',
    content: () => {
      return (
        <div>
          <VoriLogo />
        </div>
      )
    },
  },
]

const Styleguide = (): JSX.Element => {
  const classes = useStyles()
  return (
    <div>
      <Container maxWidth="lg">
        <h1>Vori Styleguide</h1>
        {Sections.map(({ title, content: Content }, i) => (
          <div className={classes.section} key={i}>
            <h2>{title}</h2>
            <div className={classes.example}>
              <Content />
            </div>
          </div>
        ))}
      </Container>
    </div>
  )
}

export default Styleguide
