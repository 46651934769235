import React from 'react'

import {
  DealType,
  FixedDeal,
  PercentageDeal,
  UnitOfMeasure,
} from '@vori/gql-market'

import {
  ensureNumericPrice,
  gqlVendorProductToAlgolia,
} from '../../helpers/utils'

import { PriceProps, ProductOrderHistory } from './helpers/types'

type Props = {
  productData: PriceProps['productData']
  activeDeal: PriceProps['activeDeal']
  fromGQL?: boolean
  large?: boolean
  showPriceDifference?: boolean
  compact?: boolean
  className?: string
  hidePriceByLine?: boolean
  showInStock?: boolean
  orderHistory?: ProductOrderHistory[]
}

const WithActiveDeal =
  (Component: (props: PriceProps) => JSX.Element) =>
  (props: Props): JSX.Element => {
    const { activeDeal, fromGQL } = props
    const productData = fromGQL
      ? gqlVendorProductToAlgolia(props.productData || {})
      : props.productData || {}

    const casePrice = ensureNumericPrice(productData.wholesaleCasePrice)
    let showPriceDifference = false
    let casePriceWithActiveDeal = casePrice || 0

    if (props?.productData.inventory && props?.productData.inventory >= 0) {
      productData.inventory = props.productData.inventory
    }

    if (props?.productData?.status) {
      productData.status = props.productData.status
    }

    if (
      activeDeal != null &&
      (activeDeal.type === DealType.Discount ||
        activeDeal.type === DealType.Fixed) &&
      (activeDeal as FixedDeal).unitOfMeasure !== UnitOfMeasure.Each
    ) {
      showPriceDifference = true

      casePriceWithActiveDeal =
        activeDeal.type === DealType.Fixed
          ? casePrice - (activeDeal as FixedDeal).dealValue
          : casePrice * (1 - (activeDeal as PercentageDeal).basisPoints / 10000)
    }

    const enhancedProps = {
      ...props,

      productData: {
        ...productData,
        casePriceWithActiveDeal,
      },

      fromGQL: false,
      activeDeal,
      showPriceDifference,
    }

    return <Component {...enhancedProps} />
  }

export default WithActiveDeal
