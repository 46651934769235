import React, { useMemo } from 'react'
import { capitalize } from 'lodash'

import { UnitOfMeasure } from '@vori/gql-market'

import { gqlVendorProductToAlgolia, toShortDate } from '../../helpers/utils'
import { useProductOrderHistory } from '../../graphql/graphqlHooks'
import { ProductCardProps } from './helpers/types'

const WithOrderHistory = (
  Component: (props: ProductCardProps) => JSX.Element,
) =>
  function WithOrderHistoryEnhancedComponent(
    props: ProductCardProps,
  ): JSX.Element {
    const { fromGQL } = props

    const productData = fromGQL
      ? gqlVendorProductToAlgolia(props.productData || {})
      : props.productData || {}

    const { data, loading } = useProductOrderHistory(
      productData.objectID as string,
    )

    const orderHistory = useMemo<ProductCardProps['orderHistory']>(() => {
      if (loading) {
        return []
      }

      const orderHistoryItems =
        data?.me?.user.retailUser?.retailCompany.store?.productOrderHistory
          .nodes || []

      return orderHistoryItems.map((orderHistoryItem) => ({
        ...orderHistoryItem,
        orderDateTime: toShortDate(orderHistoryItem.orderDateTime as string),
        unitOfMeasure: capitalize(
          orderHistoryItem.unitOfMeasure,
        ) as UnitOfMeasure,
      }))
    }, [data, loading])

    const enhancedProps: ProductCardProps = {
      ...props,
      fromGQL: false,
      isLoadingOrderHistory: loading,
      orderHistory,
      productData,
    }

    return <Component {...enhancedProps} />
  }

export default WithOrderHistory
