import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { matchPath, useLocation } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { CustomTheme } from '../../theme'

import * as ROUTE from '../../constants/routes'

const useStyles = makeStyles((theme: CustomTheme) => ({
  footer: {
    padding: theme.spacing(2),
  },
  footerColumns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerText: {
    fontSize: '0.8rem',
    color: theme.colors.GRAY_3,
    flex: 1,
  },
  footerLink: {
    color: theme.colors.GRAY_3,
  },
  centralFooterText: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  rightFooterText: {
    textAlign: 'right',
  },
}))

const hiddenPaths = [
  ROUTE.SIGN_IN,
  ROUTE.SIGN_UP,
  ROUTE.FORGOT_PASSWORD,
  ROUTE.ACCOUNT,
  ROUTE.ACCOUNT_EDIT,
  ROUTE.CHECKOUT,
]

const Footer = (): JSX.Element | null => {
  const classes = useStyles()
  const { pathname } = useLocation()

  if (matchPath(pathname, hiddenPaths)) {
    return null
  }

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <div className={classes.footerColumns}>
          <Typography className={classes.footerText}>
            Vori Market <span>{new Date().getFullYear()}</span>
          </Typography>
          <Typography
            className={clsx(classes.footerText, classes.centralFooterText)}
          >
            Developed with{' '}
            <span role="img" aria-label="love">
              💜
            </span>{' '}
            by Team Vori
          </Typography>
          <Typography
            className={clsx(classes.footerText, classes.rightFooterText)}
          >
            <Link className={classes.footerLink} href="https://vori.com">
              vori.com
            </Link>
          </Typography>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
